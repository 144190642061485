import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { c_billingdoc_items } from 'src/app/app.class/c_billingdoc_items';
import { s_billingdoc_items } from 'src/app/app.service/s_billingdoc_items.service';
import { GC } from 'src/app/common/global-constants';
import { s_partitems_sub } from 'src/app/app.service/s_partItems_sub.service';
import { c_partitems_sub } from 'src/app/app.class/c_partitems_sub';
import { Columns } from 'pdfmake-wrapper';

var pdfMake = require('node_modules/pdfmake/build/pdfmake.js');
var pdfFonts = require('node_modules/pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-frm-information-asset',
  templateUrl: './frm-information-asset.component.html',
  styleUrls: ['./frm-information-asset.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})
export class FrmInformationAssetComponent implements OnInit {

  s_data: c_billingdoc_items[]=new Array();
  s_data2: c_billingdoc_items[]=new Array();
  m_datas : c_billingdoc_items = new c_billingdoc_items();
  m_data_biling_items : c_billingdoc_items = new c_billingdoc_items();
  s_partsub : c_partitems_sub[]=new Array();
  m_partsub : c_partitems_sub = new c_partitems_sub();

  doctype : number=0;

  lblid : string="Id";
  lblDocEvents : string ="เหตุการณ์";
  lbldocno : string="เลขที่";
  lbldocdate : string="วันที่เอกสาร";
  lblempname : string="ผู้รับผิดชอบ";
  lblpartdept : string ="แผนก";
  lblpartlocation : string ="ที่ตั้ง";

  lblempreceivename : string="ผู้รับโอน";
  lbltopartdept : string ="ไปแผนก";
  lbltopartlocation : string ="ไปที่ตั้ง";

  lblcustomername : string="ผู้ซื้อ/ผู้ขาย";
  lbldocdesc : string="หมายเหตุ";
  lblpartname : string ="ชื่อทรัพย์สิน";
  lblpartsub : string ="ทะเบียนทรัพย์สิน";

  lbltxprice : string ="ราคา";
  lbltxamount : string ="จำนวน";
  lblstatus : string ="สถานะ";

  lblinput : string="";
  lbloutput : string ="";


  lblqrcode : string ="รหัส QR Code";
  lbluseflag : string ="สถานะการใช้";

  searchvalue : string="";

  constructor(private dataServiceSub: s_billingdoc_items,private dataServicePartSub: s_partitems_sub,private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.queryData(this.route.snapshot.params['qrcode']);
  }
  public search()
  {
    this.queryData(this.searchvalue);
  }
  public queryData(strSearch: string){
      if (strSearch !="")
      {
        //get master detail
        let m_partsub=new c_partitems_sub();
        m_partsub.qrcode=strSearch;
        this.s_partsub=new Array();
        this.dataServicePartSub.getDataListById(1,m_partsub).subscribe(data => {
          this.s_partsub = data;
          }, error => console.log(error));

        //get detail document
        this.m_datas.id=-1;
        this.m_datas.qrcode=strSearch;
        this.s_data2=new Array();
        this.dataServiceSub.getDataListByQrcode(4,this.m_datas).subscribe(data => {
           this.s_data2 = data;

           this.s_data.splice(0,this.s_data.length);//clear all
           this.s_data2.forEach( (element : any) =>  {
         
            if (element.documenttype==GC.doc_receive){element.docevents="รับเข้า";}
            else if(element.documenttype==GC.doc_transfer && element.txtype==="E"){element.docevents="โอนจาก";}
            else if(element.documenttype==GC.doc_transfer && element.txtype==="I"){element.docevents="โอนเข้า";}
            else if(element.documenttype==GC.doc_repair ){element.docevents="ส่งซ่อม";}
            else if(element.documenttype==GC.doc_receive_repair){element.docevents="รับจากซ่อม";}
            else if(element.documenttype==GC.doc_return && element.txtype==="E"){element.docevents="รับคืนจาก";}
            else if(element.documenttype==GC.doc_return && element.txtype==="I"){element.docevents="ผู้รับคืน";}
            else if(element.documenttype==GC.doc_buy ){element.docevents="ขายออก";}

            this.s_data.push(element);

          });

          }, error => console.log(error));
      }

  }

  async generateReportPDF() { 
    let docDefinition : any;
  
  
    let rows = [];
    //Header column
    rows.push([ { text: '#',bold: true, alignment: 'center' }, 
                { text: 'เหตุการณ์',bold: true, alignment: 'center' }, 
                { text: 'วันที่เอกสาร', bold: true,alignment: 'center' }, 
                { text: 'เลขที่เอกสาร',bold: true, alignment: 'center' }, 
                { text: 'ผู้ซื้อ/ผู้ขาย',bold: true, alignment: 'center' }, 
                { text: 'ผู้รับผิดชอบ',bold: true, alignment: 'center' },
                { text: 'แผนก',bold: true, alignment: 'center' },
                { text: 'ที่ตั้ง',bold: true, alignment: 'center' },
                { text: 'ราคา',bold: true, alignment: 'center' },
                { text: 'จำนวน',bold: true, alignment: 'center' },
              ]);
  
  //Detail column
    this.m_partsub  = new c_partitems_sub();
    this.s_partsub.forEach( (element : c_partitems_sub) =>  {
      this.m_partsub=element;
    });

     //Detail column
     let i: number=0;
     this.s_data.forEach( (element : c_billingdoc_items) =>  {
       i++;
       rows.push([
       { text: i, alignment: 'right'}, 
       { text: element.docevents, alignment: 'left'}, 
       { text: GC.convertYearEnToYearThai(element.documentdatelast,"DD/MM/YYYY"), alignment: 'center'}, 
       { text: element.documentno, alignment: 'left'}, 
       { text: element.customername, alignment: 'left'}, 
       { text: element.personname, alignment: 'left'}, 
       { text: element.deptname, alignment: 'left'}, 
       { text: element.locationname, alignment: 'left'}, 
       { text: element.txprice, alignment: 'right'}, 
       { text: element.txamount, alignment: 'right'}, 
       ]);
     });
  
    let groupName : string="";
    let pageMaginTop : number=50;
  
    // if (this.m_data.partgroupname != "")
    // {
    //   groupName='หมวด : ' + this.m_data.partgroupname;
    //   pageMaginTop=70;
    // }
  
    pdfMake.fonts = {
      THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNew-Bold.ttf',
        italics: 'THSarabunNew-Italic.ttf',
        bolditalics: 'THSarabunNew-BoldItalic.ttf'
        },
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
      }
      };
  
       docDefinition = {
        pageSize: 'A4',
        pageMargins: [ 20,pageMaginTop,20,30 ],
        pageOrientation: 'landscape' ,
        header: {
          margin: 20,
          
          columns: [
              {
                stack: [
                  {
                    columns: [
                      {
                        text: 'รายงาน การเคลื่อนไหวข้อมูลทรัพย์สิน',
                        bold: true,
                        width: '*',
                        fontSize: 18,
                        alignment: 'center',
                      }
                    ],
                  },
                  {
                    columns: [
                      {
                        text: groupName,
                        bold: true,
                        width: '*',
                        fontSize: 15,
                        alignment: 'center',
                      }
                    ],
                  },
                ],
              }
  
              
          ],
        },
        footer: function (currentPage : any, pageCount : any) {
          return [{ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'center' }];
        },
  
        content: [
          {
            stack: [
              {columns: [
                  {text: 'รหัสทะเบียนทรัพย์สิน',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                  {text: ': '+ this.m_partsub.partcode, bold: false,width: 300,fontSize: 14,alignment: 'left'},
                  {text: 'ซื้อจาก',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                  {text: ': '+ this.m_partsub.personname, bold: false,width: 300,fontSize: 14,alignment: 'left'},
                ],
              },
              {columns: [
                {text: 'ชื่อภาษาอังกฤษ',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                {text: ': '+ this.m_partsub.partnameeng, bold: false,width: 300,fontSize: 14,alignment: 'left'},
                {text: 'มูลค่าการซื้อ',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                {text: ': '+ GC.formatNumber(this.m_partsub.purchasevalue,2), bold: false,width: 300,fontSize: 14,alignment: 'left'},
                ],
              },
              {columns: [
                {text: 'ชื่อทะเบียนทรัพย์สิน',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                {text: ': '+ this.m_partsub.partname, bold: false,width: 300,fontSize: 14,alignment: 'left'},
                {text: 'อัตราค่าเสื่อมราคา',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                {text: ': '+ this.m_partsub.depreciationrate + ' (%)', bold: false,width: 300,fontSize: 14,alignment: 'left'},
                ],
              },
              {columns: [
                {text: 'รายละเอียด',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                {text: ': '+ this.m_partsub.partdesc, bold: false,width: 300,fontSize: 14,alignment: 'left'},
                {text: 'อายุการใช้งาน',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                {text: ': '+ this.m_partsub.lifetime + ' (ปี)', bold: false,width: 300,fontSize: 14,alignment: 'left'},
                ],
              },
              {columns: [
                {text: 'รหัส QR Code',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                {text: ': '+ this.m_partsub.qrcode, bold: false,width: 300,fontSize: 14,alignment: 'left'},
                {text: 'ราคาซาก',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                {text: ': '+ this.m_partsub.carcassprice + ' (บาท)', bold: false,width: 300,fontSize: 14,alignment: 'left'},
                ],
              },
              {columns: [
                {text: 'หน่วย',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                {text: ': '+ this.m_partsub.unitname, bold: false,width: 300,fontSize: 14,alignment: 'left'},
                {text: 'วันที่ซื้อ',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                {text: ': '+ GC.convertYearEnToYearThai(this.m_partsub.purchasedate,'DD/MM/YYYY'), bold: false,width: 300,fontSize: 14,alignment: 'left'},
                ],
              },
              {columns: [
                {text: 'หมวด',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                {text: ': '+ this.m_partsub.partgroupname, bold: false,width: 300,fontSize: 14,alignment: 'left'},
                {text: 'วันที่เริ่มคิดค่าเสื่อม',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                {text: ': '+ GC.convertYearEnToYearThai(this.m_partsub.begindepreciationdate,'DD/MM/YYYY'), bold: false,width: 300,fontSize: 14,alignment: 'left'},
                ],
              },
              {columns: [
                {text: 'ประเภท',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                {text: ': '+ this.m_partsub.parttypename, bold: false,width: 300,fontSize: 14,alignment: 'left'},
                {text: 'วันที่หยุดคิดค่าเสื่อม',bold: true,width: 100,fontSize: 14,alignment: 'left',},
                {text: ': '+ GC.convertYearEnToYearThai(this.m_partsub.enddepreciationdate,'DD/MM/YYYY'), bold: false,width: 300,fontSize: 14,alignment: 'left'},
                ]
                
              }
            ],
          },
          { text: '__________________________________________', fontSize: 18, alignment: 'center' },
          { text: 'รายการเคลื่อนไหว',
            bold: true,
            width: '*',
            fontSize: 18,
            alignment: 'center', 
          },
          { 
            table: {
              headerRows: 1,
                  // widths: [20, 50, 225, 290, 80, 80],//745 แนวนอน
                  widths: [20, 50, 80, 80, 80, 80, 80, 80, 80, 80],//745 แนวตั้ง
                  body: rows,
            },
          },
          
    
        ],
        styles: {
          MasterDesc: {
            fontSize: 14,
            bold: true,
            width: 50,
            alignment: 'left'
          },
          MasterDesc2: {
            fontSize: 14,
            bold: false,
            width: '*',
            alignment: 'left'
          },
          notesText: {
            fontSize: 10,
          },
        },
        defaultStyle: {
          columnGap: 14,
          font: 'THSarabunNew'
        },
      };
       pdfMake.createPdf(docDefinition).open();
     } 

}
