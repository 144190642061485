
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { c_partitems } from '../app.class/c_partitems';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

 
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export class GC {
    public static lanq: string = "th";
    public static role1: boolean =false;
    public static role2: boolean =false;
    //public static httpPath : string ="http://localhost:8446/api/v1/";
    public static httpPath : string ="https://mtpapiservice.net:8445/api/v1/";
    // public static httpPath = window.location.origin.includes('localhost') ? 'http://localhost:8446/api/v1/' : 'https://mtpapiservice.net:8445/api/v1/';
    public static partDirName:string="";



    public static mr_unit : number=1;
    public static mr_itemType : number=2;
    public static mr_dept : number=3;
    public static mr_country :number=4;
    public static mr_supType :number=5;
    public static mr_cusType :number=6;
    public static mr_empLevel :number=7;
    public static mr_location :number=8;
    public static mr_itemGroup : number =9;

    public static ps_company :number=1;
    public static ps_cus :number=2;
    public static ps_sup :number=3;
    public static ps_emp :number=4;

    public static reg_asset :number=1;
    public static reg_partitems :number=2;

    public static doc_receive :number=1;//ใบรับเข้า
    public static doc_transfer :number=2;//ใบโอนย้าย
    public static doc_repair :number =3 ;//ส่งซ่อม
    public static doc_receive_repair =4 ;//รับจากซ่อม
    public static doc_return :number=5;//คืน
    public static doc_buy :number=6;//ใบขายออก
    public static doc_pr :number=7;//ใบขอซื้อ
    public static doc_po :number=8;//ใบสั่งซื้อ

    public static doc_asset_text :string="ทะเบียนทรัพย์สิน";//ทะเบียนทรัพย์สิน
    public static doc_items_text :string="สินค้า";//ทะเบียนทรัพย์สิน
    public static doc_receive_text :string="รับเข้า";//ใบรับเข้า
    public static doc_transfer_text :string="โอนย้าย";//ใบโอนย้าย
    public static doc_repair_text :string="ส่งซ่อม" ;//ส่งซ่อม
    public static doc_receive_repair_text :string="รับจากซ่อม";//รับจากซ่อม
    public static doc_return_text :string="คืน";//คืน
    public static doc_buy_text :string="ขายออก";//ใบขายออก
    public static doc_pr_text :string="ใบขอซื้อ";//ใบขอซื้อ
    public static doc_po_text :string="ใบสั่งซื้อ";//ใบสั่งซื้อ


    public static cf_partno :number=99;//รหัสทะเบียนทรัพย์สิน
    public static cf_itemsno :number=100;//รหัสสินค้า
    public static cf_receive :number=1;//ใบรับเข้า
    public static cf_transfer :number=2;//ใบโอนย้าย
    public static cf_repair :number =3 ;//ส่งซ่อม
    public static cf_receive_repair =4 ;//รับจากซ่อม
    public static cf_return :number=5;//คืน
    public static cf_buy :number=6;//ใบขายออก
    public static cf_pr :number=7;//ใบขอซื้อ
    public static cf_po :number=8;//ใบสั่งซื้อ
    

    public static companyid : number =-1;
    public static companyname :string="";
    public static username :string="";

    public static isSystem : string ="";

    public static datacenter : any;
    

    public static formatNumber(data : number,digit? : number) : number
    {
        let rData;
        if (typeof data == 'undefined'){digit=2;}
    
        rData=Number((data).toFixed(digit));

        // rData=data.toLocaleString('en-US', {maximumFractionDigits:2});

        // let nf = new Intl.NumberFormat('en-US', {maximumFractionDigits:2});
       

        return rData;
    }

    public static isNumber(data : number) : number
    {
        let rData;
        if (typeof data == 'undefined'){data=0}
    
        
        rData=Number((data).toFixed(0));

        // rData=data.toLocaleString('en-US', {maximumFractionDigits:2});

        // let nf = new Intl.NumberFormat('en-US', {maximumFractionDigits:2});
       

        return rData;
    }


    public static formatNumberTostring(data : number,digit? : number) : string
    {
        let rData;
        if (typeof data == 'undefined'){digit=2;}
    
        rData=Number((data).toFixed(digit));

        // rData=data.toLocaleString('en-US', {maximumFractionDigits:2});

        // let nf = new Intl.NumberFormat('en-US', {maximumFractionDigits:2});
       

        return rData.toLocaleString();
    }
    public static getDateEnNow(formatDate : string) : string
    {
      const date = new Date();
  
      let isDate :string;
      let mkDay ;
      let mkMonth ;
      let mkYear ;
        mkMonth=date.getMonth()+1;
        mkMonth=new String(mkMonth);
        if(mkMonth.length==1){
          mkMonth="0"+mkMonth;
        }
        mkDay=date.getDate();
        mkDay=new String(mkDay);
        if(mkDay.length==1){
          mkDay="0"+mkDay;
        }	
        mkYear=date.getFullYear();

      if (formatDate==="DD/MM/YYYY")
      {
        isDate= mkDay + "/" + mkMonth + "/" + mkYear;
      }else if (formatDate==="YYYY/MM/DD")
      {
        isDate= mkYear + "/" + mkMonth + "/" + mkDay;
      }else if (formatDate==="YYYY-MM-DD")
      {
        isDate= mkYear + "-" + mkMonth + "-" + mkDay;
      }

      return isDate;
    }

    public static getDateFormatEnToEn(data : string,formatDate : string) : string
    {
      const date = new Date(data);
  
      let isDate :string;
      let mkDay ;
      let mkMonth ;
      let mkYear ;
        mkMonth=date.getMonth()+1;
        mkMonth=new String(mkMonth);
        if(mkMonth.length==1){
          mkMonth="0"+mkMonth;
        }
        mkDay=date.getDate();
        mkDay=new String(mkDay);
        if(mkDay.length==1){
          mkDay="0"+mkDay;
        }	
        mkYear=date.getFullYear();

      if (formatDate==="DD/MM/YYYY")
      {
        isDate= mkDay + "/" + mkMonth + "/" + mkYear;
      }else if (formatDate==="YYYY/MM/DD")
      {
        isDate= mkYear + "/" + mkMonth + "/" + mkDay;
      }else if (formatDate==="YYYY-MM-DD")
      {
        isDate= mkYear + "-" + mkMonth + "-" + mkDay;
      }

      return isDate;
    }

    public static convertYearEnToYearThai(data : string,formatDate : string) : string
    {
      const date = new Date(data);
  
      let isDate :string;
      let mkDay ;
      let mkMonth ;
      let mkYear ;
        mkMonth=date.getMonth()+1;
        mkMonth=new String(mkMonth);
        if(mkMonth.length==1){
          mkMonth="0"+mkMonth;
        }
        mkDay=date.getDate();
        mkDay=new String(mkDay);
        if(mkDay.length==1){
          mkDay="0"+mkDay;
        }	
        mkYear=date.getFullYear() +543;
  
  
      

      if (formatDate==="DD/MM/YYYY")
      {
        isDate= mkDay + "/" + mkMonth + "/" + mkYear;
      }else if (formatDate==="YYYY/MM/DD")
      {
        isDate= mkYear + "/" + mkMonth + "/" + mkDay;
      }

      return isDate;
    }

    public static convertYearThaiToYearEn(data : string,formatDate : string) : string
    {
      const date = new Date(data);

      //alert(date.getTimezoneOffset());
  
      let isDate :string;
      let mkDay ;
      let mkMonth ;
      let mkYear ;
        mkMonth=date.getMonth()+1;
        mkMonth=new String(mkMonth);
        if(mkMonth.length==1){
          mkMonth="0"+mkMonth;
        }
        mkDay=date.getDate();
        mkDay=new String(mkDay);
        if(mkDay.length==1){
          mkDay="0"+mkDay;
        }	
        mkYear=date.getFullYear() -543;
         
  // alert(data + "," + mkDay + "/" + mkMonth + "/" + mkYear)
      

      if (formatDate==="DD/MM/YYYY")
      {
        isDate= mkDay + "/" + mkMonth + "/" + mkYear;
      }else if (formatDate==="YYYY/MM/DD")
      {
        isDate= mkYear + "/" + mkMonth + "/" + mkDay;
      }else if (formatDate==="YYYY-MM-DD")
      {
        isDate= mkYear + "-" + mkMonth + "-" + mkDay;
      }
      

      return isDate;
    }

    private static getNum(val : any) : any {
        if (isNaN(val)) {
          return 0;
        }
        return val;
     }

     public static fillterText(data : string) :string
     {
       if (!data ) 
       {
        data="";
       }

       return data;
     }

     public static textStatus(data : string) :string
     {
       if (data === 'Y' ) {data="ใช้งาน";}
       else if (data ==='N') {data="ว่าง";}
       else if (data ==='S') {data="ขาย";}
       else if (data ==='R') {data="ซ่อม";}
       else if (data ==='C') {data="ยกเลิก";}

       return data;
     }

     public static calDepreciation(data :c_partitems)  :c_partitems
     {
       let date = new Date( data.begindepreciationdate);
       let date2 = new Date( data.enddepreciationdate);
       let dateNow = new Date( data.dateFix);//new Date();//

        // alert(dateNow);
   
       //หาค่าเสื่อมต่อวัน
       var diffAll = Math.abs(date2.getTime() - date.getTime());
       var diffDaysAll = Math.ceil(diffAll / (1000 * 3600 * 24)); 
      //  alert(dateNow + ':=' + diffDaysAll)
       var valuePerDate=((data.purchasevalue )/(diffDaysAll+1));
       data.depreciationperday=GC.formatNumber(valuePerDate,2);
   
 
        if (dateNow >= date2)//ถ้าวันปัจจุบัน > วันที่หยุดคิดค่าเสื่อม
        {
          data.depreciationaccumlastyeardate="";
          //ค่าเสื่อมสะสมยกมาต้นปี
          data.depreciationaccum= GC.formatNumber((data.purchasevalue - data.carcassprice),2);
          //ทรัพย์สินสุทธิยกมา
          data.assetaccumnet=GC.formatNumber(data.carcassprice,2);
          //ค่าเสื่อมราคาระหว่างปี
          data.depreciationaccumbetweenyear=0;
          //รวมค่าเสื่อมสะสมสุทธิ
          data.sumdepreciationaccumnet=GC.formatNumber((data.depreciationaccum + data.depreciationaccumbetweenyear),2);  
          //คงเหลือทรัพย์สินสุทธิ
          data.depreciationbalancenet=GC.formatNumber((data.purchasevalue-data.sumdepreciationaccumnet),2);
        }else if (dateNow < date)//ถ้าวันปัจจุบัน < วันที่เริ่มคิดค่าเสื่อม
        {
          data.depreciationaccumlastyeardate="";
          //ค่าเสื่อมสะสมยกมาต้นปี
          data.depreciationaccum= 0;
          //ทรัพย์สินสุทธิยกมา
          data.assetaccumnet= 0;
          //ค่าเสื่อมราคาระหว่างปี
          data.depreciationaccumbetweenyear=0;
          //รวมค่าเสื่อมสะสมสุทธิ
          data.sumdepreciationaccumnet= 0; 
          //คงเหลือทรัพย์สินสุทธิ
          data.depreciationbalancenet= 0;
        }else if ((dateNow >= date) && (dateNow <= date2))//ถ้าวันปัจจุบัน อยู่ระหว่าง วันที่เริ่มคิดค่าเสื่อม และ วันที่หยุดคิดค่าเสื่อม
        {
                // alert(data.depreciationaccum)
        


          if (dateNow.getFullYear() > date.getFullYear())//ถ้าอยู่คนละปี
          {
                //หาวันที่สุดท้ายของปีก่อนหน้า
            let lastDateOfPrevYear= (dateNow.getFullYear() -1).toString() +"-12-31" ;
            const date4 = new Date(lastDateOfPrevYear);

           //หาวันที่แรกของปีปัจจุบัน
            let firstDateOfCurrentYear= (dateNow.getFullYear()).toString() +"-01-01" ;
            const dateFirstCY = new Date(firstDateOfCurrentYear);
            data.depreciationaccumlastyeardate =GC.getDateFormatEnToEn(dateFirstCY.toString(),"YYYY-MM-DD");
        
            //ค่าเสื่อมสะสมยกมาต้นปี
            var diff = Math.abs(date4.getTime() - date.getTime())+1;
            var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
            //  alert(diffDays)
            data.depreciationaccum=GC.formatNumber((valuePerDate * diffDays),2);

            //ทรัพย์สินสุทธิยกมา
            data.assetaccumnet=GC.formatNumber((data.purchasevalue -data.depreciationaccum),2);

            //หาวันที่แรกของปีปัจจุบัน
            let startDateOfCurentYear= (dateNow.getFullYear()).toString() +"-01-01" ;
            const date5 = new Date(startDateOfCurentYear);
        
            //ค่าเสื่อมราคาระหว่างปี
            var diff2 = Math.abs(dateNow.getTime() - date5.getTime());
            var diffDays2 = Math.ceil(diff2 / (1000 * 3600 * 24)); 
            data.depreciationaccumbetweenyear=GC.formatNumber((valuePerDate * (diffDays2+1)),2); 
          }else//ถ้าอยู่ในปีเดียวกัน
          {
            data.depreciationaccumlastyeardate="";
            //หาวันที่สุดท้ายของปีก่อนหน้า
            let lastDateOfPrevYear= (dateNow.getFullYear() -1).toString() +"-12-31" ;
            const date4 = new Date(lastDateOfPrevYear);
        
            //ค่าเสื่อมสะสมยกมาต้นปี
            data.depreciationaccum=0;

            //ทรัพย์สินสุทธิยกมา
            data.assetaccumnet=GC.formatNumber((data.purchasevalue -data.depreciationaccum),2);

            //ค่าเสื่อมราคาระหว่างปี
            var diff2 = Math.abs(dateNow.getTime() - date.getTime());
            var diffDays2 = Math.ceil(diff2 / (1000 * 3600 * 24)); 
            data.depreciationaccumbetweenyear=GC.formatNumber((valuePerDate * (diffDays2+1)),2); 

          }
      
          //รวมค่าเสื่อมสะสมสุทธิ
          data.sumdepreciationaccumnet=GC.formatNumber((data.depreciationaccum + data.depreciationaccumbetweenyear),2);  
        
          //คงเหลือทรัพย์สินสุทธิ
          data.depreciationbalancenet=GC.formatNumber((data.purchasevalue-data.sumdepreciationaccumnet),2);
        }

       if (data.depreciationflag == true)
       {
        data.depreciationperday=0;
        data.depreciationaccum=0;
        data.assetaccumnet=0;
        data.depreciationaccumbetweenyear=0;
        data.sumdepreciationaccumnet=0;
        data.depreciationbalancenet=0;
       }
   
       return data;
     }

    //  public static calDepreciation(data :c_partitems)  :c_partitems
    //  {
    //    let date = new Date( data.begindepreciationdate);
    //    let date2 = new Date( data.enddepreciationdate);
    //    let dateNow = new Date();//new Date(data.dateFix);//
    //    if (dateNow > date2)
    //    {
    //      dateNow=date2;
    //    }

    //     // alert( data.depreciationaccumdate)
   
    //    //หาค่าเสื่อมต่อวัน
    //    var diffAll = Math.abs(date2.getTime() - date.getTime());
    //    var diffDaysAll = Math.ceil(diffAll / (1000 * 3600 * 24)); 
    //    var valuePerDate=((data.purchasevalue )/(diffDaysAll+1));
    //    data.depreciationperday=GC.formatNumber(valuePerDate,2);
   
    //   //  alert(dateNow.getFullYear() +"   "+ date.getFullYear())
    //   if (data.depreciationaccum <=0)
    //   {
    //     if (dateNow.getFullYear() > date.getFullYear())
    //     {
    //       // alert(data.depreciationaccum)
    //        //หาวันที่สุดท้ายของปีก่อนหน้า
    //      let lastDateOfPrevYear= (dateNow.getFullYear() -1).toString() +"-12-31" ;
    //      const date4 = new Date(lastDateOfPrevYear);
     
    //      //ค่าเสื่อมสะสมยกมาต้นปี
    //      var diff = Math.abs(date4.getTime() - date.getTime())+1;
    //      var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
         
    //      data.depreciationaccum=GC.formatNumber((valuePerDate * diffDays),2);
    //     }else
    //     {
    //      data.depreciationaccum=0;//ให้ใส่ค่าเป็น 0 หากอยู่ใน ปีเดียวกัน
    //     }
    //   }
   
    //    //ทรัพย์สินสุทธิยกมา
    //    data.assetaccumnet=GC.formatNumber((data.purchasevalue -data.depreciationaccum),2);  
   
    //    if (dateNow.getFullYear() > date.getFullYear())//ถ้าอยู่คนละปี
    //    {
    //     //หาวันที่แรกของปีปัจจุบัน
    //     let startDateOfCurentYear= (dateNow.getFullYear()).toString() +"-01-01" ;
    //     const date5 = new Date(startDateOfCurentYear);
    
    //     //ค่าเสื่อมราคาระหว่างปี
    //     var diff2 = Math.abs(dateNow.getTime() - date5.getTime());
    //     var diffDays2 = Math.ceil(diff2 / (1000 * 3600 * 24)); 
    //     data.depreciationaccumbetweenyear=GC.formatNumber((valuePerDate * (diffDays2+1)),2); 
    //    }else//ถ้าอยู่ในปีเดียวกัน
    //    {
    //      //ค่าเสื่อมราคาระหว่างปี
    //      var diff2 = Math.abs(dateNow.getTime() - date.getTime());
    //      var diffDays2 = Math.ceil(diff2 / (1000 * 3600 * 24)); 
    //      data.depreciationaccumbetweenyear=GC.formatNumber((valuePerDate * (diffDays2)),2); 

    //    }
   
    //    //รวมค่าเสื่อมสะสมสุทธิ
    //    data.sumdepreciationaccumnet=GC.formatNumber((data.depreciationaccum + data.depreciationaccumbetweenyear),2);  
    
    //   //คงเหลือทรัพย์สินสุทธิ
    //   data.depreciationbalancenet=GC.formatNumber((data.purchasevalue-data.sumdepreciationaccumnet),2);


    //    if (data.depreciationbalancenet == 0 )//ถ้าหมดอายุแล้ว
    //    {
    //     //รวมค่าเสื่อมสะสมสุทธิ
    //     data.sumdepreciationaccumnet=GC.formatNumber((data.depreciationaccum + data.depreciationaccumbetweenyear-data.carcassprice),2);  

    //      //คงเหลือทรัพย์สินสุทธิ
    //      data.depreciationbalancenet=GC.formatNumber((data.purchasevalue-data.sumdepreciationaccumnet),2);
    //    }




   

    //    if (data.lifetime==0)
    //    {
    //     data.depreciationperday=0;
    //     data.depreciationaccum=0;
    //     data.assetaccumnet=0;
    //     data.depreciationaccumbetweenyear=0;
    //     data.sumdepreciationaccumnet=0;
    //     data.depreciationbalancenet=0;
    //    }
   
    //    return data;
    //  }

    //  public static calDepreciation(data :c_partitems)  :c_partitems
    //  {
    //    let date = new Date( data.begindepreciationdate);
    //    let date2 = new Date( data.enddepreciationdate);
    //    let dateNow = new Date();
    //    if (dateNow > date2)
    //    {
    //      dateNow=date2;
    //    }
   
    //    //หาค่าเสื่อมต่อวัน
    //    var diffAll = Math.abs(date2.getTime() - date.getTime());
    //    var diffDaysAll = Math.ceil(diffAll / (1000 * 3600 * 24)); 
    //    var valuePerDate=((data.purchasevalue )/(diffDaysAll+1));
    //    data.depreciationperday=GC.formatNumber(valuePerDate,2);
   
    //    //หาวันที่สุดท้ายของปีก่อนหน้า
    //    let lastDateOfPrevYear= (dateNow.getFullYear() -1).toString() +"-12-31" ;
    //    const date4 = new Date(lastDateOfPrevYear);
   
    //    //หาค่าเสื่อมสะสมยกมาของปีก่อนหน้า
    //    var diff = Math.abs(date4.getTime() - date.getTime())+1;
    //    var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
    //    data.depreciationaccum=GC.formatNumber((valuePerDate * diffDays),2);
   
    //    //หาทรัพย์สินสุทธิยกมา
    //    data.assetaccumnet=GC.formatNumber((data.purchasevalue -data.depreciationaccum),2);  
   
    //    //หาวันที่แรกของปีปัจจุบัน
    //    let startDateOfCurentYear= (dateNow.getFullYear()).toString() +"-01-01" ;
    //    const date5 = new Date(startDateOfCurentYear);
   
    //    //หาค่าเสื่อมสะสมยกมาในปีปัจจุบัน
    //    var diff2 = Math.abs(dateNow.getTime() - date5.getTime());
    //    var diffDays2 = Math.ceil(diff2 / (1000 * 3600 * 24)); 
    //    data.depreciationaccumbetweenyear=GC.formatNumber((valuePerDate * (diffDays2+1)),2); 
   
    //    //หารวมค่าเสื่อมสะสมสุทธิ
    //    data.sumdepreciationaccumnet=GC.formatNumber((data.depreciationaccum + data.depreciationaccumbetweenyear),2);  
   
    //    //  alert(data.sumdepreciationaccumnet)
       
       
    //    data.depreciationbalancenet=GC.formatNumber((data.purchasevalue-data.sumdepreciationaccumnet),2);

    //    if (data.lifetime==0)
    //    {
    //     data.depreciationperday=0;
    //     data.depreciationaccum=0;
    //     data.assetaccumnet=0;
    //     data.depreciationaccumbetweenyear=0;
    //     data.sumdepreciationaccumnet=0;
    //     data.depreciationbalancenet=0;
    //    }
   
    //    return data;
    //  }

     public static exportAsExcelFile(json: any[],headerText:any[],excelFileName: string): void {

      var worksheet = XLSX.utils.json_to_sheet(headerText, {header: [], skipHeader: true});
  
      XLSX.utils.sheet_add_json(worksheet, json, {skipHeader: true, origin: "A2"});
      
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
      this.saveAsExcelFile(excelBuffer, excelFileName);	
    }
  
    private static saveAsExcelFile(buffer: any, fileName: string): void {
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    public static exportexcel(): void 
    {
      let dateNow = new Date();

      let fileName= 'Export' + dateNow.getTime() + '.xlsx'; 
       /* table id is passed over here */   
       let element = document.getElementById('report-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, fileName);
			
    }
     

}