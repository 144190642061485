import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import{ GC } from './common/global-constants';
import { TokenStorageService } from './_services/token-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = "";
  role1 : boolean= false;
  isSystem : String ="";
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorageService: TokenStorageService,
    private cookieService: CookieService) 
    {
       this.role1 = !!this.tokenStorageService.getToken();
       this.isSystem = (this.cookieService.get('isSystem'));
    }

    

}
