
<br><br><br><br><br><br><br><br><br><br><br><br><br>

<!DOCTYPE html>
<html>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1">
<style>
.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: #3e8e41;
}

#myInput {
  box-sizing: border-box;
  background-image: url('searchicon.png');
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
}

#myInput:focus {outline: 3px solid #ddd;}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {background-color: #ddd;}

.show {display: block;}
</style>
</head>
<body>

<h2>Search/Filter Dropdown</h2>
<p>Click on the button to open the dropdown menu, and use the input field to search for a specific dropdown link.</p>

<div class="dropdown">
  <button (click)="myFunction()" class="dropbtn">Dropdown</button>
  <div id="myDropdown" class="dropdown-content">
    <input type="text" placeholder="Search.." id="myInput" onkeyup="filterFunction()">
    <a href="#about">About</a>
    <a href="#base">Base</a>
    <a href="#blog">Blog</a>
    <a href="#contact">Contact</a>
    <a href="#custom">Custom</a>
    <a href="#support">Support</a>
    <a href="#tools">Tools</a>
  </div>
</div>


</body>
</html>

<!-- <ejs-dropdownlist 
  id="ddlelement" #samples [dataSource]="data" [fields]="fields" [placeholder]="text"
  [(ngModel)]="selectedItem" [itemTemplate]="itemTemplate" [valueTemplate]="valueTemplate" [(value)]="selectedItem"
  (filtering)='filterText($event.text)' [allowFiltering]='true'>
  <ng-template #itemTemplate="" let-data="">
    <span>{{ data.Id }} - {{ data.Name }}</span>
  </ng-template>
  <ng-template #valueTemplate="" let-data="">
    <span>{{ data.Id }} - {{ data.Name }}</span>
  </ng-template>
</ejs-dropdownlist> -->

    <!-- <div class ="container text-center" id="htmltable2">
        <qrcode [qrdata]="data" [width]="170" [errorCorrectionLevel]="'M'"></qrcode>

      </div>

    <button class="btn btn-primary btn-block" (click)="generatePDF()">Download PDF</button>
    <button class="btn btn-primary btn-block" (click)="shows()">Show</button>


    <div id="action-description">
      <p>This sample demonstrates the default functionalities of the DropDownList. Click the DropDownList element and select an item from the <code>options</code> list.
      The selected item's <code>value</code> and <code>text</code> property values will be shown the in property panel.</p>
  </div> -->
   
<!-- <div id="action-description">
    <p>This sample demonstrates the filtering functionalities of the DropDownList. Click the DropDownList element and then type a character in the search box. It will display the
        filtered list items based on the typed characters.</p>
</div>

<div class="control-section">
    <div class='content' style="width: 280px;margin: 0 auto; padding-top:40px">
        <ejs-dropdownlist id='country' [dataSource]='data' [fields]='fields' (filtering)='onFiltering($event)' 
            [filterBarPlaceholder]="filterPlaceholder"
            [popupHeight]='height' [allowFiltering]='true' [placeholder]='watermark'>
        </ejs-dropdownlist>
    </div>
</div> -->
    

    <!-- <input type="text"         
    (ngModelChange)="filterItem($event)" 
    [(ngModel)]="filterItem">
    <br>
<select 
     #selectList
     [(ngModel)]="myDropDown" 
    (ngModelChange)="onChangeofOptions($event)">
    <option value="empty"></option>
    <option *ngFor="let item of items">         
        {{item}}
    </option>    
</select>
<p>items {{ items | json }}</p> -->




    <!-- Whatever you wrap with the '<window>' component will be rendered on a new window  -->
    <!-- <app-frm-persons></app-frm-persons> -->
    <!-- <window *ngIf="showPortal">
      <h2>Hello world from amother window!!</h2>
      <button (click)="this.showPortal = false">Close me!</button>
    </window> -->
  
    <!-- <div class="mt-5" id="htmltable" #htmltable>
      <table class="table table-bordered">
        <thead>
          <tr class="table-dagner">
            <th>#Post Id</th>
            <th>Body</th>
            <th>Title</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let post of Posts">
            <th scope="row">{{post.id}}</th>
            <td>{{post.body}}</td>
            <td>{{post.title}}</td>
          </tr>
        </tbody>
      </table>
    </div> -->

    <!-- <div class="container text-center">
      <h1>{{title}}</h1>
    </div>
    <div class="container">
      <div class="row">
        <div class="search-hero">
          <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="&#61442;  Start searching for a hero by id or name or country">
        </div>
        <table class="table table-striped">
          <thead>
          <tr>
            <th>Id</th>
            <th>Hero Name</th>
            <th>Country</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let hero of heroes | filter:searchText">
            <td>{{hero.id}}</td>
            <td>{{hero.name}}</td>
            <td>{{hero.country}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div> -->
