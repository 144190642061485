<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>


<body class="ht">
  <div #back></div>
  <div id="bottom"  >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
   </div>
 <div  [style.display]="role1 ? 'block' : 'none'" >

 
   <div class="card2"><br><br>
      <span>
          <h1>{{nameForm}}</h1> 
      </span>
      <span class="example-spacer"></span>

    <div class="container">
        <div class="card2" id="mainDiv"><br>
            <div class="row leftRight">
                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbldocno}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="itemCode" name="itemCode" [(ngModel)]="m_datas.documentno" class="form-control">           
                    </div>
                </div>

                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbldocdate}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="date" id="itemName" name="itemName" [(ngModel)]="m_datas.documentdate" class="form-control">           
                    </div>
                </div>
                <div class="col-md-2 mb-2 fontCenter">
                    <button (click) = "queryData()" class = "btn btn-success text-center">ค้นหา</button>&nbsp;
                    <button (click) = "clearForm()" class = "btn btn-success text-center">เคลียร์</button>
                </div>
            </div>
            <div class="row leftRight">
                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblpartsub}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group" id="partid">
                        <select id="partid" name="partid" [(ngModel)]="m_datas.partid" class="form-control">
                            <option  *ngFor="let ms of pi" [value]="ms.id">{{ms.partcode}} : {{ms.partname}} </option>
                        </select> 
                         <button (click)="myFunction(pi,'pi','partid') " type="button" class="btn btn-primary">...</button>
                    </div>
                </div>

                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblgroup}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <select id="partgroupid" name="partgroupid" [(ngModel)]="m_datas.partgroupid" class="form-control">
                            <option *ngFor="let ms of mr_itemGroup" [value]="ms.id">{{ms.keycode}} : {{ms.keyname}} </option>
                        </select>         
                    </div>
                </div>
                <div class="col-md-2 mb-2 fontCenter">
                   
                </div>

            </div>

                <!-- สำหรับค้นหา -->
                <div [style.display]="showModal ? 'block' : 'none'" >
                <div id="myDropdown" class="dropdown-content modal-dialog-scrollable" style="overflow-x:auto;">
                    <div class="container" style="background : rgb(150, 218, 176)">
                        <div class="row" >
                        <div class="search-hero" >
                            <input  id="myInput" class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="ป้อนข้อมูลที่ต้องการค้นหา" >
                        </div>
                            <table id="dtHorizontalExample" class="table table-striped table-hover table-bordered table-sm " cellspacing="0" width="100%" >
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>รหัส</th>
                                    <th>ชื่อ</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr  *ngFor="let data of dataSearch | filter:searchText" (click)="dataSelect(data.id)">
                                    <td >{{data.id}}</td>
                                    <td >{{data.code}}</td>
                                    <td >{{data.name}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>


        
    </div>
    <br>
      &nbsp;<button (click) = "addData()"  class = "btn btn-success text-center">เพิ่มข้อมูล</button>
    <div class="container-fluid ">
      <div class="card2">

      </div>
      <div class="card2" style="overflow-x:auto;">

        <table id="dtHorizontalExample" class="table table-hover table-bordered table-sm" cellspacing="0" width="100%" >
          <thead>
              <tr class="text-center">
                  <th scope="col" style="text-align: center;"> แก้ไข</th>
                  <!-- <th scope="col" style="text-align: center;" > Id</th> -->
                  <th scope="col" style="text-align: center;"> {{lbldocno}}</th>
                  <th scope="col" style="text-align: center;"> {{lbldocdate}}</th>
                  <th scope="col" style="text-align: center;"> {{lbldocdesc}}</th>
                  <th *ngIf="doctype == 4" scope="col" style="text-align: center;"> {{lblempname}}</th>
                  <th scope="col" style="text-align: center;"> {{lblcompany}}</th>
                  <th scope="col" style="text-align: center;"> ลบ </th>
              </tr>
          </thead>
          <tbody>
              <tr  *ngFor = "let item of s_data" >
                  <td scope="row" class="col-1 fontCenter">  <button (click) = "updateData(item.id)" class = "btn btn-info text-center buttonRow"> แก้ไข</button></td>
                  <!-- <td scope="row" class="col-1 fontRight" > {{item.id}}</td> -->
                  <td scope="row" class="col-1 fontLeft"> {{item.documentno}}</td>
                  <td scope="row" class="col-1 fontCenter"> {{item.documentdate}} </td>
                  <td scope="row" class="col-2 fontLeft"> {{item.documentdesc}} </td>
                  <td *ngIf="doctype == 4" scope="row" class="col-2 fontLeft"> {{item.customercode}} : {{item.customercode}} </td>
                  <td scope="row" class="col-1 fontCenter"> {{item.companycode}} </td>
                  <td scope="row" class="col-1 fontCenter"><button (click) = "deleteData(item.id)" class = "btn btn-danger text-center buttonRow"> ลบ</button></td>
              </tr>
             
          </tbody>
      </table>
      
    </div>
    
    <br>
    </div>
   </div>
</div>
</body>