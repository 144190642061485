import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { c_configdoc } from '../app.class/c_configdoc';
import { map } from 'rxjs/operators';
import { GC } from '../common/global-constants';
import { TokenStorageService } from '../_services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class s_configdoc {

  private baseURL = "";
  private person : any;
  tP : String ="configdoc";

  constructor(private httpClient: HttpClient, private tokenStorage: TokenStorageService) { 
    this.baseURL = GC.httpPath + this.tP;
    GC.username = this.tokenStorage.getUser().username;
  }
  
  getDataList(ind? :number ,mr? : c_configdoc): Observable<c_configdoc[]>{
    
    this.person= this.httpClient.get(this.baseURL).pipe(map((response: any) => response.json()));
    this.baseURL = GC.httpPath + this.tP +"/allkey?ind=" + ind +"&id="+ mr.id + "&configdoctype=" + mr.configdoctype + "&lastno=" + mr.lastno + "&prefixcode=" + mr.prefixcode
    + "&runningno=" + mr.runningno + "&digitamount=" + mr.digitamount  + "&companyid=" + mr.companyid;
    // alert(`${this.baseURL}`); 
    return this.httpClient.get<c_configdoc[]>(`${this.baseURL}`);
  }
 
  createData(data: c_configdoc): Observable<Object>{
    data.createby=GC.username;
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
    //alert(`${this.baseURL}`);
    return this.httpClient.post(`${this.baseURL}`, data);
  }

  getDataById(id: number): Observable<c_configdoc>{
    this.baseURL = GC.httpPath + this.tP
    // alert(`${this.baseURL}`);
    return this.httpClient.get<c_configdoc>(`${this.baseURL}/${id}`);
  }

  updateData(id: number, data: c_configdoc): Observable<Object>{
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
    // alert(`${this.baseURL}/${id}`);
    return this.httpClient.put(`${this.baseURL}/${id}`, data);
  }

  deleteData(id: number): Observable<Object>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }
}

