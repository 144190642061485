// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-frm-items-sup',
//   templateUrl: './frm-items-sup.component.html',
//   styleUrls: ['./frm-items-sup.component.css']
// })
// export class FrmItemsSupComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
import { Component, OnInit } from '@angular/core';
import { c_partitems } from '../../app.class/c_partitems';
import { s_partitems } from '../../app.service/s_partItems.service';
import { s_partitems_sub } from '../../app.service/s_partItems_sub.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GC } from '../../common/global-constants';
import { UploadFilesService } from 'src/app/app.service/upload-files.service';
import { Observable, timer } from 'rxjs';
import { filedata } from 'src/app/app.class/filedata';
import { c_masterRef } from 'src/app/app.class/c_masterref';
import { s_masterRef } from 'src/app/app.service/s_masterRef.service';
import { s_persons } from 'src/app/app.service/s_persons.service';
import { c_persons } from 'src/app/app.class/c_persons';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { c_partitems_sub } from 'src/app/app.class/c_partitems_sub';

import * as XLSX from 'xlsx';
import { CookieService } from 'ngx-cookie-service';

type AOA = any[][];

var pdfMake = require('node_modules/pdfmake/build/pdfmake.js');
var pdfFonts = require('node_modules/pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-frm-items-sup',
  templateUrl: './frm-items-sup.component.html',
  styleUrls: ['./frm-items-sup.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})

export class FrmItemsSupComponent implements OnInit {
  mum_data: c_partitems[]=new Array();//ไว้สำหรับนำมาแสดง
  m_data: c_partitems = new c_partitems();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  m_datas : c_partitems_sub = new c_partitems_sub();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข

  
  m_mr: c_masterRef = new c_masterRef();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  m_ps: c_persons = new c_persons();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  
  mr_master: c_masterRef[]=new Array();
  mr_unit: c_masterRef[]=new Array();//ไว้สำหรับนำมาแสดง
  mr_itemGroup: c_masterRef[]=new Array();//ไว้สำหรับนำมาแสดง
  mr_itemType: c_masterRef[]=new Array();//ไว้สำหรับนำมาแสดง
  ps_supplier: c_persons[]=new Array();//ไว้สำหรับนำมาแสดง

  id: number=0 ;
  isLoggedIn: boolean=true;
  eventsFlag : string ="";
  eventsText : string="";
  role1 : boolean= false;
  BlogId : number=0;

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  fileInfos: Observable<any> | undefined;
  t_fileInfos : filedata[] =new Array();
  file: filedata = new filedata();

  httpPart : string ="";


  itemCode : string="";
  itemName : string="";
  itemDesc : string="";
  itemStatus : string="";
  persontype : number=0;
  nameFormOrigin : string="";
  nameForm : string="";
  nameForm2 : string="";

  lblid : string="Id";
  lblcode : string="รหัส";
  lblname : string="ชื่อ";
  lblnameeng : string="ชื่อภาษาอังกฤษ";
  lbldesc : string="รายละเอียด";
  lblstatus : string ="สถานะ";
  lblgroup : string="หมวด";
  lbltype : string="ประเภท";
  lblunit : string="หน่วยใหญ่";
  lblsubunit : string="หน่วยย่อย";
  lblsupplier : string ="ซื้อจาก";
  
  lblamount : string ="จำนวนทรัพย์สิน";
  lblInputManual : string ="เพิ่มเอง";

  lbluseflag : string ="สถานะการใช้";
  isReload : boolean =false;
  lblnote : string ="หมายเหตุ";

  isSave : boolean =false;
  isSaveAndExit : boolean =false;

  lbldepreciationaperday : string ="ค่าเสื่อมต่อวัน(บาท)";
  lbldepreciationaccumlastyear : string ="ค่าเสื่อมสะสมยกมาต้นปี(บาท)";
  lblassetaccumnet : string ="ทรัพย์สินสุทธิยกมา(บาท)";
  lbldepreciationaccumbetweenyear : string ="ค่าเสื่อมราคาระหว่างปี(บาท)";
  lblsumdepreciationaccumnet: string ="รวมค่าเสื่อมสะสมสุทธิ(บาท)";
  lbldepreciationbalancenet : string ="คงเหลือทรัพย์สินสุทธิ(บาท)";
  

  lblDetail : string ="";
  now = new Date();


  status = [
    { id: "Y", name: "ใช้งาน" },
    { id: "N", name: "ว่าง" } ,
    { id: "R", name: "ซ่อม" } ,
    { id: "S", name: "ขายออก" },
    { id: "C", name: "ยกเลิก" }
  ];

  deptrate= [
    { id: "0", name: "" },
    { id: "5", name: "5 %" },
    { id: "10", name: "10 %" } ,
    { id: "20", name: "20 %" } 
  ];


  myDate : string;

  data: AOA = [
    // [1, 2],
    // [3, 4],
  ];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';

  constructor(private dataService: s_partitems,private dataServiceSub: s_partitems_sub,private masterrefService: s_masterRef,private personsService: s_persons,
    private router: Router,private route: ActivatedRoute,private tokenStorageService: TokenStorageService,private cookieService: CookieService) {
    }

   ngOnInit(): void {
   
      this.role1 = !!this.tokenStorageService.getToken();
      this.httpPart=GC.httpPath;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.id  = this.route.snapshot.params['id'];
      GC.companyid =Number(this.cookieService.get('companyid'));
      this.m_data.companyid=GC.companyid;
      
      this.nameFormOrigin = this.route.snapshot.params['name'];
  
      if (this.nameFormOrigin==="registration_asset")
      {
        this.nameForm="สินค้า";
      }

      this.eventsFlag= this.route.snapshot.params['addedit'];

      

      this.lblcode=this.lblcode+ this.nameForm;
      this.lblname=this.lblname+ this.nameForm;

      // this.mr_unit= this.methodsService.getMasterRef(GC.mr_unit,this.m_mr);
      // this.mr_itemType= this.methodsService.getMasterRef(GC.mr_itemType,this.m_mr);

      //  alert(GC.companyid);

      
      this.getMaster(-1,this.m_mr); 
      this.getSupplier(GC.ps_sup,this.m_ps);


      if (this.eventsFlag==="A")
      {
        this.nameForm2 ="เพิ่มข้อมูล " + this.nameForm;
      }else if (this.eventsFlag==="E")
      {
        this.nameForm2 ="แก้ไขข้อมูล " + this.nameForm;
      }
      
      this.queryData();
   }

   private getMaster(id? : number,mr? : c_masterRef) {
    mr.companyid=GC.companyid;
    this.masterrefService.getDataList(id,mr).subscribe(data => {
      this.mr_master=new Array();//ไว้สำหรับนำมาแสดง
      this.mr_master=data;
   
      this.getUnit(GC.mr_unit); 
      this.getPartGroup(GC.mr_itemGroup); 
      this.getPartType(GC.mr_itemType); 
      
      
    });
  }

  // clearFlag()
  // {
  //   this.m_data.lifetime=0;
  // }

   private getUnit(id? : number,mr? : c_masterRef) {
    this.mr_unit.splice(0,this.mr_unit.length);//clear all
    this.mr_master.forEach( (element : any) =>  {
      
      if (element.masterarea==id){
          this.mr_unit.push(element);
      }
    });
  }

  private getPartGroup(id? : number,mr? : c_masterRef) {
    this.mr_itemGroup.splice(0,this.mr_itemGroup.length);//clear all
    this.mr_master.forEach( (element : any) =>  {
      
      if (element.masterarea==id){
          this.mr_itemGroup.push(element);
      }
    });
  }

  private getPartType(id? : number,mr? : c_masterRef) {

    this.mr_itemType.splice(0,this.mr_itemType.length);//clear all
    this.mr_master.forEach( (element : any) =>  {
      
      if (element.masterarea==id){
          this.mr_itemType.push(element);
      }
    });
  }

  private getSupplier(id? : number,ps? : c_persons) {
    ps.companyid=GC.companyid;
    this.personsService.getDataList(id,ps).subscribe(data => {
      this.ps_supplier=data;
    });
  }

  public searchPartType(id: number){
    this.mr_itemType.splice(0,this.mr_itemType.length);//clear all
    this.mr_master.forEach( (element : any) =>  {
      
      if (element.masterrefid==id){
          this.mr_itemType.push(element);
      }
    });
  }


   private queryData(){
      let id: number=this.id;
      
      //เรัยกแม่
       if (this.eventsFlag=='E')
       {
          //เรียกแม่
          this.m_data=new c_partitems();
          this.m_data.companyid=GC.companyid;
          this.m_data.id=id;
          this.dataService.getDataList(1,this.m_data).subscribe(data => {
            this.mum_data=data;

            this.m_data=new c_partitems();
            this.mum_data.forEach( (element : any) =>  {
              this.m_data=element;
            
            });

          });

      //เรียกลูก
      this.m_datas.id=-1;
      this.m_datas.partid=id;
      this.dataServiceSub.getDataList(1,this.m_datas).subscribe(data => {
         
      });

    }else{
      this.addManual(true);
    }
  }

  addManual(isFlag : any)
  {
    if (isFlag)
    {
      this.m_data.partcode="***AUTO***";
    }else{
      this.m_data.partcode="";
    }
    
  }

  addData(){
    this.m_datas = new c_partitems_sub();
    this.showModal = true; // Show-Hide Modal Check
    this.eventsFlag="A"; 
    this.eventsText ="เพิ่มรายการ " + this.nameForm;

  }

   updateData(id: number){
    let ind : number=0;
    this.id=id;//เก็บ id ของตัวที่กำลังกระทำ เพื่อนำไปบันทึก
    this.m_datas=new c_partitems_sub();
    this.m_datas.id=id;
    this.m_datas.partid=-1;
   

    ind=1;

    this.m_datas=new c_partitems_sub();
    this.m_datas.id=id;
    // this.dataServiceSub.getDataList(1,this.m_datas).subscribe(data => {
    //   this.mum_data2=data;

    //   this.m_datas=new c_partitems_sub();
    //   this.mum_data2.forEach( (element : any) =>  {
    //     this.m_datas=element;

    //     this.lblDetail=this.m_datas.partcode +"#"+ this.m_datas.qrcode +"#"+ this.m_datas.partname;

    //     this.m_datas.genqrcode="https://asset.mittraphap.co.th/#/frm-information-asset.component/" + this.m_datas.qrcode;
      
    //   });

    // });
    
    
     this.showModal = true; // Show-Hide Modal Check
     this.eventsFlag="E";
     this.eventsText ="แก้ไขรายการ " + this.nameForm;

   }
 
   deleteData(id: number,blogId?: number,fileId1? : string,fileId2? : string ){
    let isConfirm : Boolean | any ;
    isConfirm=confirm("Are you sure to delete "+ id)
    if(isConfirm===false) {
      return;
    }
      this.dataServiceSub.deleteData(id).subscribe( data => {
        console.log(data);
        this.queryData();
    
      })
      this.eventsFlag="D";
    
  }


 //for Submit form when action
   onSubmitMaster(AddEditMode : string){
    if (this.m_data.partname ===""){alert("กรุณาระบุ " + this.lblname);return;}
    if (this.m_data.unitid <= 0){alert("กรุณาระบุ " + this.lblunit);return;}
    if (this.m_data.partgroupid <= 0){alert("กรุณาระบุ " + this.lblgroup);return;}
    if (this.m_data.parttypeid <= 0){alert("กรุณาระบุ " + this.lbltype);return;}
    if (this.m_data.supplierid <= 0){alert("กรุณาระบุ " + this.lblsupplier);return;}

     if (AddEditMode=="A") {
      if (GC.companyid> 0)
      {
        this.m_data.companyid=GC.companyid;
        this.m_data.statusflag="Y";//ฝังไว้ก่อนตอนสร้าง
        this.m_data.configdoctype= GC.cf_itemsno;
        this.m_data.parttype= GC.reg_partitems;

        this.dataService.createData(this.m_data).subscribe( data =>{ console.log(data);},
        error => console.log(error));
      }else
      {
        alert("กรุณาระบุ บริษัท")
      }

     }else if (AddEditMode=="E"){
      
      // alert(this.m_data.depreciationflag)
      
       this.dataService.updateData(this.id, this.m_data).subscribe( data =>{
         console.log(data);
       }
       , error => console.log(error));
     }

     
    
     if (this.isSaveAndExit)
     { 
      this.isSaveAndExit=false;
       this.close();
     }else if (this.isSave)
     {
        this.isSave=false;
        if (AddEditMode=="A") {
          this.close();
        }else{
          setTimeout(() => 
          {
          this.queryData();
          },500);
        }
     }
   }


  close ()
  {
    setTimeout(() => 
    {
      this.router.navigate(['/frm-part-items-component',GC.reg_partitems,this.nameFormOrigin]);
    },500);
    
  }
 
 //for popup form
   showModal: boolean | any;
   registerForm: UntypedFormGroup | any;
   submitted = false;
 
  show()
    { 
      this.showModal = true;
    }

   hide()
   {
      this.showModal = false;
   }

   verifyUser(Us : boolean){
     GC.role1=Us;
     this.role1=GC.role1;
   }
   
   refresh(): void {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
    }
    
    eventBotton (id? : number) : void {
      if (id===1)
      {
        this.isSave=true;
      }else if (id===2)
      {
        this.isSaveAndExit=true;
      }
    }


  
    export(): void {
      /* generate worksheet */
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    }


    openPDF(){
      this.dataService.exportToPdf()
      .subscribe(
        (data: Blob) => {
          var file = new Blob([data], { type: 'application/pdf' })
          var fileURL = URL.createObjectURL(file);

// if you want to open PDF in new tab
          window.open(fileURL); 
          var a         = document.createElement('a');
          a.href        = fileURL; 
          a.target      = '_blank';
          // a.download    = 'bill.pdf';
          document.body.appendChild(a);
          a.click();
        },
        (error) => {
          console.log('getPDF error: ',error);
        }
      );
    }
    async generatePDFGroup(data : c_partitems_sub[])
    {
        data.forEach( (element : any) =>  {
        this.m_datas = new c_partitems_sub();
        this.m_datas=element;

        this.generateQRCode(this.m_datas.qrcode ,this.m_datas.partcode ,this.m_datas.partnameeng ,this.m_datas.purchasedate,this.m_datas.note );
      
      });
      // this.s_data.forEach( (element : any) =>  {
      //   this.m_datas = new c_partitems_sub();
      //   this.m_datas=element;

      //   GC.generateQRCode(this.m_datas.qrcode ,this.m_datas.partcode ,this.m_datas.partname ,this.m_datas.purchasedate );
      
      // });
    }

    async generatePDF(qrcode : string,partcode : string ,partname : string,purchasedate : string,note : string) {  
      this.generateQRCode(qrcode ,partcode ,partname ,purchasedate,note);
    }

  public  generateQRCode(qrcode : string,partcode : string ,partname : string,purchasedate : string,note : string) {  
      let docDefinition : any;

     
      let http : string="https://asset.mittraphap.co.th/#/frm-information-asset.component/";

      pdfMake.fonts = {
        THSarabunNew: {
          normal: 'THSarabunNew.ttf',
          bold: 'THSarabunNew-Bold.ttf',
          italics: 'THSarabunNew-Italic.ttf',
          bolditalics: 'THSarabunNew-BoldItalic.ttf'
          },
        Roboto: {
          normal: 'Roboto-Regular.ttf',
          bold: 'Roboto-Medium.ttf',
          italics: 'Roboto-Italic.ttf',
          bolditalics: 'Roboto-MediumItalic.ttf'
        }
        };
        
      let strDesc : string;
      if ((GC.fillterText(note).length) > 0)
      {
        strDesc="#" + GC.fillterText(partname) + " \n\ " + "*" + GC.fillterText(note);
      }else
      {
        strDesc="#" + GC.fillterText(partname) ;
      }

      docDefinition = {
        pageSize: {
                      width: 280,
                      height: 163
                  },
        pageMargins: [ 10,5,10,10 ],
        // header: 'simple text',

        // footer: {
        //   columns: [
        //     'Left part',
        //     { text: 'Right part', alignment: 'right' }
        //   ]
        // },
        content: [
            {
              columns: [
                {
                  width: 100,
                  qr: http + qrcode, fit: '99', style: [ 'qr' ] 
                },
                {
                  width: 150,
                  text: strDesc, style: 'content2'
                }
              ],
              columnGap: 10
            },
            
            {canvas: [ { type: 'line', x1: 0, y1: 0, x2: 260, y2: 0, lineWidth: 1 } ]},
            {text: " " },
            {text: "#" + partcode + "#" + GC.fillterText(qrcode) + "#Date: " + GC.convertYearEnToYearThai(purchasedate,"DD/MM/YYYY"), style: 'content' }
          ],
        styles: {
          header: {
            fontSize: 22,
            bold: true,
            font: 'THSarabunNew'
          },
          qr : {
            bold: true,
            margin : [0,5,0,10],
            alignment: 'left'
          },
          content : {
            fontSize: 14,
            bold: true,
            alignment: 'left',
            font: 'THSarabunNew'
          } ,
          content2 : {
            fontSize: 14,
            bold: true,
            margin : [0,5,0,10],
            alignment: 'left',
            font: 'THSarabunNew'
          },
          defaultStyle: {
            font: 'THSarabunNew'
            }
        }
      };


  pdfMake.createPdf(docDefinition).open();

}

async generateReportPDF() { 
  let docDefinition : any;
  let groupName : string="";
  let pageMaginTop : number=50;
  let width1 : number=95;
  let width2 : number=180;
  let headerSize : number =14;

  let rows = [];
  //Header column
  rows.push([ { text: '#',bold: true, alignment: 'center' }, 
              { text: 'รหัส QR Code',bold: true, alignment: 'center' }, 
              { text: 'สถานะการใช้', bold: true,alignment: 'center' },
            ]);


  //  //Detail column
  //  let i: number=0;
  //  this.s_data.forEach( (element : c_partitems_sub) =>  {
  //    i++;
  //    rows.push([
  //    { text: i, alignment: 'right'}, 
  //    { text: element.qrcode, alignment: 'left'}, 
  //    { text: GC.textStatus(element.useflag), alignment: 'center'}, 
  //    ]);
  //  });



  // if (this.m_data.partgroupname != "")
  // {
  //   groupName='หมวด : ' + this.m_data.partgroupname;
  //   pageMaginTop=70;
  // }

  pdfMake.fonts = {
    THSarabunNew: {
      normal: 'THSarabunNew.ttf',
      bold: 'THSarabunNew-Bold.ttf',
      italics: 'THSarabunNew-Italic.ttf',
      bolditalics: 'THSarabunNew-BoldItalic.ttf'
      },
    Roboto: {
      normal: 'Roboto-Regular.ttf',
      bold: 'Roboto-Medium.ttf',
      italics: 'Roboto-Italic.ttf',
      bolditalics: 'Roboto-MediumItalic.ttf'
    }
    };

     docDefinition = {
      pageSize: 'A4',
      pageMargins: [ 20,pageMaginTop,20,30 ],
      // pageOrientation: 'landscape' ,
      header: {
        margin: 20,
        
        columns: [
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'รายงานข้อมูลทรัพย์สิน',
                      bold: true,
                      width: '*',
                      fontSize: 18,
                      alignment: 'center',
                    }
                  ],
                },
                {
                  columns: [
                    {
                      text: groupName,
                      bold: true,
                      width: '*',
                      fontSize: 15,
                      alignment: 'center',
                    }
                  ],
                },
              ],
            }

            
        ],
      },
      footer: function (currentPage : any, pageCount : any) {
        return [{ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'center' }];
      },

      content: [
        {
          stack: [
            {columns: [
                {text: 'รหัสทะเบียนทรัพย์สิน',bold: true,width: width1,fontSize: headerSize,alignment: 'left',},
                {text: ': '+ this.m_data.partcode, bold: false,width: width2,fontSize: headerSize,alignment: 'left'},
                {text: 'ซื้อจาก',bold: true,width: width1,fontSize: headerSize,alignment: 'left',},
                {text: ': '+ GC.fillterText(this.m_data.suppliername), bold: false,width: width2,fontSize: headerSize,alignment: 'left'},
              ],
            },
            // {columns: [
            //   {text: 'ชื่อภาษาอังกฤษ',bold: true,width: width1,fontSize: headerSize,alignment: 'left',},
            //   {text: ': '+ this.m_data.partnameeng, bold: false,width: width2,fontSize: headerSize,alignment: 'left'},
            //   {text: 'มูลค่าการซื้อ',bold: true,width: width1,fontSize: headerSize,alignment: 'left',},
            //   {text: ': '+ GC.formatNumber(this.m_data.purchasevalue,2), bold: false,width: width2,fontSize: headerSize,alignment: 'left'},
            //   ],
            // },
            {columns: [
              {text: 'ชื่อทะเบียนทรัพย์สิน',bold: true,width: width1,fontSize: headerSize,alignment: 'left',},
              {text: ': '+ this.m_data.partname, bold: false,width: width2,fontSize: headerSize,alignment: 'left'},
              {text: 'อัตราค่าเสื่อมราคา',bold: true,width: width1,fontSize: headerSize,alignment: 'left',},
              ],
            },
            {columns: [
              {text: 'รายละเอียด',bold: true,width: width1,fontSize: headerSize,alignment: 'left',},
              {text: ': '+ this.m_data.partdesc, bold: false,width: width2,fontSize: headerSize,alignment: 'left'},
              {text: 'อายุการใช้งาน',bold: true,width: width1,fontSize: headerSize,alignment: 'left',},
              ],
            },
         
            {columns: [
              {text: 'หน่วย',bold: true,width: width1,fontSize: headerSize,alignment: 'left',},
              {text: ': '+ this.m_data.unitname, bold: false,width: width2,fontSize: headerSize,alignment: 'left'},
            
              ],
            },
            {columns: [
              {text: 'หมวด',bold: true,width: width1,fontSize: headerSize,alignment: 'left',},
              {text: ': '+ this.m_data.partgroupname, bold: false,width: width2,fontSize: headerSize,alignment: 'left'},
             
              ],
            },
            {columns: [
              {text: 'ประเภท',bold: true,width: width1,fontSize: headerSize,alignment: 'left',},
              {text: ': '+ this.m_data.parttypename, bold: false,width: width2,fontSize: headerSize,alignment: 'left'},
             
              ]
              
            },
            {columns: [
              {text: ' ',bold: true,width: width1,fontSize: headerSize,alignment: 'left',}
              ]
            },

          ],
        },
        { text: '__________________________________________', fontSize: 18, alignment: 'center' },
        { text: 'รายการทรัพย์สิน',
          bold: true,
          width: '*',
          fontSize: 18,
          alignment: 'center', 
        },
        { 
          table: {
            headerRows: 1,
                // widths: [20, 50, 225, 290, 80, 80],//745 แนวนอน
                widths: [20, '*', 100],//520 แนวตั้ง
                body: rows,
          },
        },
        
  
      ],
      styles: {
        MasterDesc: {
          fontSize: 14,
          bold: true,
          width: 50,
          alignment: 'left'
        },
        MasterDesc2: {
          fontSize: 14,
          bold: false,
          width: '*',
          alignment: 'left'
        },
        notesText: {
          fontSize: 10,
        },
      },
      defaultStyle: {
        columnGap: 14,
        font: 'THSarabunNew'
      },
    };
     pdfMake.createPdf(docDefinition).open();
   } 

}
