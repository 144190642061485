export class c_billingdoc_items{
    id : number=0;
    billingid : number=-1;
    deptid: number=0;
    personid: number=0;
    locationid: number=0;
    partid: number=0;
    partsubid: number=0;
    txamount: number=0;
    txprice: number=0;
    txtype: string="";
    billingitemsref : number=0;
    billingdocref : number=0;

    createby : string ="";
    createdate :string="";
    modifyby: string="";
    modifydate :string="";

    partcode: string="";
    partname: string="";
    qrcode : string ="";
    unitname : string ="";
    partgroupname : string ="";
    parttypename : string ="";
    deptcode: string ="";
    deptname: string ="";
    locationcode: string ="";
    locationname: string =""; 

    doctype : number=0;

    personcode : string="";
    personname : string ="";
    personreceivecode : string="";
    personreceivename : string ="";

    topersonid : number=0;
    toid: number=0;
    todeptid: number=0;
    tolocationid: number=0;
    topersonname : string ="";
    topersoncode : string ="";
    todeptcode: string ="";
    todeptname: string ="";
    tolocationcode: string ="";
    tolocationname: string =""

    documenttypeset : string ="";
    documenttype : string ="";

    documentno: string="";
    documentdesc: string="";
    documentdate: string="";
    documentdatelast: string="";
    customercode: string="";
    customername: string="";

    docevents : string="";
}