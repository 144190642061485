import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import{ GC } from '../../common/global-constants';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})
export class MenubarComponent implements OnInit {
  companyName: string ="";
  firstPage:  string="";
  aboutUs:  string="";
  ourBusiness:  string="";
  quality : string="";
  productAndService:  string="";
  newsAndEvent:  string="";
  contactUs:  string="";
  language: string="";
  systems: string ="";
  goodsView: string="";
  workwithus:string="";

  test : string="";
  test2 : string="";
  role1 : boolean= false;
  role2 : boolean= false;
  logInOut : boolean=true;

  logoPath : string ="";
  bgPath : string ="";

  isOk : boolean=false;

  private roles: string[] = [];
  isLoggedIn: boolean = false;
  showAdminBoard: boolean = false;
  showModeratorBoard: boolean = false;
  username: string | undefined;

  mr_unit : number=0;
  mr_itemGroup : number=0;
  mr_itemType : number=0;
  mr_dept : number=0;
  mr_country :number=0;
  mr_supType :number=0;
  mr_cusType :number=0;
  mr_empLevel :number=0;
  mr_status :number=0;
  mr_location:number=0;


  ps_company :number=0;
  ps_cus :number=0;
  ps_sup :number=0;
  ps_emp :number=0;

  reg_asset :number=0;
  reg_partitems :number=0;

  doc_receive : number =0;
  doc_transfer : number =0;
  doc_repair : number =0;
  doc_receive_repair : number=0;
  doc_buy : number =0;
  doc_return : number =0;
  doc_pr : number =0;
  doc_po : number =0;

  companyid : number=0;
  companyname : string ="";

  isSystem : string ="";
  

  constructor(private tokenStorageService: TokenStorageService,private route: ActivatedRoute, private router: Router
    ,private cookieService: CookieService, private tokenStorage: TokenStorageService) { 
  }


  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }



  ngOnInit(): void {
    this.role1 = !!this.tokenStorageService.getToken();
    this.role2=GC.role2;
    this.setLanq(GC.lanq);

    this.logoPath=GC.httpPath + "files/logo.png";


    this.isLoggedIn = !!this.tokenStorageService.getToken();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // GC.companyid =Number(this.cookieService.get('companyid'));
    // GC.companyname=(this.cookieService.get('companyname')).toString();
    this.companyid =Number(this.cookieService.get('companyid'));
    this.companyname =(this.cookieService.get('companyname')).toString();
     
    this.username=this.tokenStorage.getUser().username;


    // this.systemid=(this.cookieService.get('isSystem'));

    this.mr_unit = GC.mr_unit;
    this.mr_itemGroup = GC.mr_itemGroup;
    this.mr_itemType = GC.mr_itemType;
    this.mr_dept = GC.mr_dept;
    this.mr_country =GC.mr_country;
    this.mr_supType = GC.mr_supType;
    this.mr_cusType = GC.mr_cusType;
    this.mr_empLevel = GC.mr_empLevel;
    this.mr_location = GC.mr_location;
    

    this.ps_company = GC.ps_company;
    this.ps_cus = GC.ps_cus;
    this.ps_sup = GC.ps_sup;
    this.ps_emp = GC.ps_emp;

    this.reg_asset= GC.reg_asset;
    this.reg_partitems= GC.reg_partitems;

    this.doc_receive = GC.doc_receive;
    this.doc_transfer = GC.doc_transfer;
    this.doc_repair = GC.doc_repair;
    this.doc_receive_repair = GC.doc_receive_repair;
    this.doc_buy = GC.doc_buy;
    this.doc_return = GC.doc_return;
    this.doc_pr = GC.doc_pr;
    this.doc_po = GC.doc_po;


    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;

      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');

      this.username = user.username;
    }


    this.isSystem =(this.cookieService.get('isSystem'));
    // this.cookieService.delete('isSystem', ' '); เก็บไว้ก่อน

  }

setLanq(ty:string){
   if (ty=="th")
   { 
     this.companyName="มิตรภาพโภคภัณฑ์";
    //  this.firstPage="หน้าหลัก";
     this.aboutUs="ผู้ใช้งาน";
     this.ourBusiness="ธุรกิจในเครือ";
     this.quality="ระบบจัดการคุณภาพ";
     this.productAndService="สินค้าและบริการ";
     this.newsAndEvent="ข่าวและกิจกรรม";
     this.contactUs="ติดต่อ";
     this.language="ภาษา";
     this.systems="ตั้งค่า";
     this.goodsView="แสดงข้อมูล";
     this.test ="ทดสอบ";
     this.test2 ="ทดสอบ2";
     if (this.role2===true){
      this.logInOut=false;
     }
     else {
      this.logInOut=true;
     }
     
     this.workwithus="ร่วมงานกับเรา";
   }
   else if (ty=="en"){
     this.companyName="Mittraphap Group";
    // this.firstPage="Main Page";
     this.aboutUs="ABOUT US";
     this.ourBusiness="OUR BUSINESS";
     this.productAndService="PRODUCTS & SERVICES";
     this.newsAndEvent="NEWS & EVENT";
     this.contactUs="CONTACT INFO";
     this.language="L";
     this.systems="Sys";
     this.goodsView="View Data";
     this.test="Test page";
     this.test2="Test2 page";
     this.workwithus="WORKWITHUS"
   }
}

  verifyLang(ty: string){

    GC.lanq=ty;
    this.setLanq(ty);
  }


  hitAnchor1(e:any){
    console.log("Events", e);
    alert("You have clicked the anchor-1 tag");
  }
  

  
  verifyUser(Us : boolean){
    GC.role1=Us;
    this.role1=GC.role1;
    this.role2=GC.role2;
  }

  exitSystem(id?:number){
    GC.role1=false;
    GC.role2=false;
  }

  toMainpage()
  {
    this.cookieService.delete('isSystem', '');
    this.router.navigate(['/frm-main-page-component']);
  }
}

