<!-- <app-menubar></app-menubar> -->

<!-- <app-footerbar></app-footerbar> -->
<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>


<body>
    <router-outlet></router-outlet>
    <div *ngIf="isSystem != ' '">
        <!-- <div (change)="onChange($event)"></div> -->
        <app-menubar></app-menubar>
    </div>
    
</body>