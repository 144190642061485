import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UploadFilesComponent } from './app.components/upload-files/upload-files.component';
import { RegisterComponent } from './app.components/register/register.component';
import { LoginComponent } from './app.components/login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { FrmMasterComponent } from './app.components/frm-master/frm-master.component';
import { FrmPersonsComponent } from './app.components/frm-persons/frm-persons.component';
import { FrmPartItemsComponent } from './app.components/frm-part-items/frm-part-items.component';
import { FrmPartItemsSubComponent } from './app.components/frm-part-items-sub/frm-part-items-sub.component';
import { FrmBillingdocComponent } from './app.components/frm-billingdoc/frm-billingdoc.component';
import { FrmBillingdocItemsComponent } from './app.components/frm-billingdoc-items/frm-billingdoc-items.component';
import { FrmInformationAssetComponent } from './app.components/frm-information-asset/frm-information-asset.component';
import { FrmManageDoccodeComponent } from './app.components/frm-manage-doccode/frm-manage-doccode.component';
import { MenubarComponent } from './app.components/menubar/menubar.component';
import { AppComponent } from './app.component';
import { FrmTestComponent } from './app.components/frm-test/frm-test.component';
import { FrmUserComponent } from './app.components/frm-user/frm-user.component';
import { FrmMainpageComponent } from './app.components/frm-mainpage/frm-mainpage.component';
import { FrmSearchComponent } from './app.components/frm-search/frm-search.component';
// import { FrmBillingsupComponent } from './app.components/frm-billingsup/frm-billingsup.component';
// import { FrmItemsComponent } from './app.components/frm-items/frm-items.component';
import { FrmItemsSupComponent } from './app.components/frm-items-sup/frm-items-sup.component';
import { FrmUserGroupComponent } from './app.components/frm-user-group/frm-user-group.component';


//app-add-edit  questionnaire-component
const routes: Routes = [
  { path: '',  redirectTo: 'login', pathMatch: 'full'},
  { path: 'app-upload-files',component:UploadFilesComponent},
  { path: 'login', component: LoginComponent },
  { path: 'login/:id', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'frm-master-component',component:FrmMasterComponent},
  { path: 'frm-master-component/:id/:name',component:FrmMasterComponent},
  { path: 'frm-persons-component',component:FrmPersonsComponent},
  { path: 'frm-persons-component/:id/:name',component:FrmPersonsComponent},
  { path: 'frm-part-items-component',component:FrmPartItemsComponent},
  { path: 'frm-part-items-component/:id/:name',component:FrmPartItemsComponent},
  { path: 'frm-part-items-component/:id/:name/:addedit',component:FrmPartItemsComponent},
  { path: 'frm-part-items-component/:name',component:FrmPartItemsComponent},
  { path: 'frm-part-items-sub-component',component:FrmPartItemsSubComponent},
  { path: 'frm-part-items-sub-component/:name/:addedit',component:FrmPartItemsSubComponent},
  { path: 'frm-part-items-sub-component/:id/:name/:addedit',component:FrmPartItemsSubComponent},

  { path: 'frm-billingdoc-component',component:FrmBillingdocComponent},
  { path: 'frm-billingdoc-component/:id/:name/:addedit',component:FrmBillingdocComponent},
  { path: 'frm-billingdoc-component/:id/:name',component:FrmBillingdocComponent},
  { path: 'frm-billingdoc-items-component',component:FrmBillingdocItemsComponent},
  { path: 'frm-billingdoc-items-component/:id/:idmaster/:name/:addedit/:doctype',component:FrmBillingdocItemsComponent},
  { path: 'frm-billingdoc-items-component/:id/:name',component:FrmBillingdocItemsComponent},
  { path: 'frm-information-asset.component',component:FrmInformationAssetComponent},
  { path: 'frm-information-asset.component/:qrcode',component:FrmInformationAssetComponent},
  { path: 'frm-manage-doccode.component',component:FrmManageDoccodeComponent},
  { path: 'app-menubar',component:MenubarComponent},
  { path: 'app-menubar/:id',component:MenubarComponent},
  { path: 'app-root',component:AppComponent},
  { path: 'app-frm-test',component:FrmTestComponent},
  { path: 'frm-user-component',component:FrmUserComponent},
  { path: 'frm-main-page-component',component:FrmMainpageComponent},
  { path: 'frm-main-page-component/:id',component:FrmMainpageComponent},
  { path: 'frm-search-component/:value',component:FrmSearchComponent},
  // { path: 'frm-billingsup-component',component:FrmBillingsupComponent},
  // { path: 'frm-billingsup-component/:id/:name/:addedit',component:FrmBillingsupComponent},
  // { path: 'frm-billingsup-component/:id/:name',component:FrmBillingsupComponent},

  { path: 'frm-items-sub-component',component:FrmItemsSupComponent},
  { path: 'frm-items-sub-component/:name/:addedit',component:FrmItemsSupComponent},
  { path: 'frm-items-sub-component/:id/:name/:addedit',component:FrmItemsSupComponent},
  { path: 'frm-user-group-component',component:FrmUserGroupComponent},
];


@NgModule({
  declarations: [
  ],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),RouterModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }