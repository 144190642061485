<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>


<body class="ht" [style.display]="role1 ? 'block' : 'none'">
  <div #back></div>
  <div id="bottom"  >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
   </div>
 
   <div class="card2"><br><br>
      <span>
          <h1>{{nameForm}}</h1> 
      </span>
      <span class="example-spacer"></span>

    <div class="container">
        <div class="card2"><br>
            <div class="row leftRight">
                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblcode}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="itemCode" name="itemCode" [(ngModel)]="m_datas.partcode" class="form-control">           
                    </div>
                </div>

                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblname}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="itemName" name="itemName" [(ngModel)]="m_datas.partname" class="form-control">           
                    </div>
                </div>

                
                <div class="col-md-2 mb-2 fontCenter">
                    <button (click) = "queryData()" class = "btn btn-success text-center">ค้นหา</button>&nbsp;
                    <button (click) = "clearForm()" class = "btn btn-success text-center">เคลียร์</button>
                </div>
            </div>

            <div class="row leftRight">
                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblgroup}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <select id="partgroupid" name="partgroupid" [(ngModel)]="m_datas.partgroupid" class="form-control" (change)="onChange($event,'partgroupid')">
                            <option *ngFor="let ms of mr_itemGroup" [value]="ms.id">{{ms.keycode}} : {{ms.keyname}} </option>
                        </select>         
                    </div>
                </div>

                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbltype}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <select id="parttypeid" name="parttypeid" [(ngModel)]="m_datas.parttypeid" class="form-control">
                        <option *ngFor="let ms of mr_itemType" [value]="ms.id">{{ms.keyname}} </option>
                        <!-- <option *ngFor="let ms of mr_itemType" [value]="ms.id">{{ms.keycode}} : {{ms.keyname}} </option> -->
                    </select>        
                    </div>
                </div>

                
                <div class="col-md-2 mb-2 fontCenter">
                    
                </div>
            </div>

            <div class="row leftRight">
                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblunit}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <select id="unitid" name="unitid" [(ngModel)]="m_datas.unitid" class="form-control">
                        <option *ngFor="let ms of mr_unit" [value]="ms.id">{{ms.keyname}} </option>
                    </select>  
                </div>

                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblstatus}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">   
                        <select id="itemStatus" name="itemStatus" [(ngModel)]="m_datas.statusflag" class="form-control">
                            <option *ngFor="let ms of status" [value]="ms.id">{{ms.name}} </option>
                        </select>    
                    </div>
                </div>
                <div class="col-md-2 mb-2 fontCenter">
                   
                </div>
            </div>

            <div class="row leftRight">
                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblfrompurchasedate}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="date" id="frompurchasedate" name="frompurchasedate"  [(ngModel)]="m_datas.frompurchasedate" class="form-control">
                    </div>
                </div>

                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbltopurchasedate}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="date" id="topurchasedate" name="topurchasedate"  [(ngModel)]="m_datas.topurchasedate" class="form-control">
                    </div>
                </div>
            </div>


            <div class="row leftRight">
                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbldatefix}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="date" id="dateFix" name="dateFix"  [(ngModel)]="m_datas.dateFix" class="form-control">
                    </div>
                </div>

                <!-- <div class="col-md-3 mb-3"><label class="verticalLine" for="">{{lbldepreciationaccumlastyeardate}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="date" id="depreciationaccumlastyeardate" name="depreciationaccumlastyeardate" [(ngModel)]="m_data.depreciationaccumlastyeardate" class="form-control" disabled="true">     
                    </div>
                </div> -->
            </div>
        </div>
        
    </div>

            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">หน้าหลัก</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">รายงาน</button>
                </li>
              </ul>

   
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
    
                <div class="container-fluid ">
                    <br><button (click) = "addData()"  class = "btn btn-success text-center">เพิ่มข้อมูล</button><br>
                <div class="card2" style="overflow-x:auto;">
                    <table id="main-table"  class="table table-hover table-bordered table-sm" cellspacing="0" width="100%" >
                        <thead>
                            <tr class="text-center">
                                <th scope="col" style="text-align: center;"> แก้ไข</th>
                                <th scope="col" style="text-align: center;"> {{lblcode}}</th>
                                <th scope="col" style="text-align: center;"> {{lblname}}</th>
                                <th scope="col" style="text-align: center;"> {{lbldesc}}</th>
                                <th scope="col" style="text-align: center;"> {{lbldocumentref}}</th>
                                <th scope="col" style="text-align: center;"> {{lblsupplier}}</th>
                                <th scope="col" style="text-align: center;"> {{lblpurchasedate}}</th>
                                <th scope="col" style="text-align: center;"> {{lblunit}}</th>
                                <th scope="col" style="text-align: center;"> {{lblgroup}}</th>
                                <th scope="col" style="text-align: center;"> {{lbltype}}</th>
                                <th scope="col" style="text-align: center;"> {{lbllifetime}}</th>
                                <th scope="col" style="text-align: center;"> {{lbldepreciationrate}}</th>
                                <th scope="col" style="text-align: center;"> {{lbldatefix}}</th>
                                <th scope="col" style="text-align: center;"> {{lblbegindepreciationdate}}</th>
                                <th scope="col" style="text-align: center;"> {{lblenddepreciationdate}}</th>
                                <th scope="col" style="text-align: center;"> {{lblpurchasevalue}}</th>
                                <th scope="col" style="text-align: center;"> {{lbldepreciationperday}}</th>
                                <th scope="col" style="text-align: center;"> {{lbldepreciationaccum}}</th>
                                <th scope="col" style="text-align: center;"> {{lblassetaccumnet}}</th>
                                <th scope="col" style="text-align: center;"> {{lbldepreciationaccumbetweenyear}}</th>
                                <th scope="col" style="text-align: center;"> {{lblsumdepreciationaccumnet}}</th>
                                <th scope="col" style="text-align: center;"> {{lbldepreciationbalancenet}}</th>
                                <th scope="col" style="text-align: center;"> {{lblcompany}}</th>
                                <th scope="col" style="text-align: center;"> ลบ </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor = "let item of s_data" >
                                <td scope="row" class="col-1 fontCenter">  <button (click) = "updateData(item.id)" class = "btn btn-info text-center buttonRow"> แก้ไข</button></td>
                                <td scope="row" class="col-1 fontLeft"> {{item.partcode}}</td>
                                <td scope="row" class="col-2 fontLeft"> {{item.partname}} </td>
                                <td scope="row" class="col-2 fontLeft"> {{item.partdesc}} </td>
                                <td scope="row" class="col-2 fontLeft"> {{item.documentref}} </td>
                                <td scope="row" class="col-2 fontLeft"> {{item.suppliername}} </td>
                                <td scope="row" class="col-2 fontLeft"> {{item.purchasedate}} </td>
                                <td scope="row" class="col-2 fontLeft"> {{item.unitname}} </td>
                                <td scope="row" class="col-2 fontLeft"> {{item.partgroupname}} </td>
                                <td scope="row" class="col-2 fontLeft"> {{item.parttypename}} </td>
                                <td scope="row" class="col-2 fontRight"> {{item.lifetime}} </td>
                                <td scope="row" class="col-2 fontRight"> {{item.depreciationrate}} </td>
                                <td scope="row" class="col-2 fontRight"> {{item.dateFix}} </td>
                                <td scope="row" class="col-1 fontCenter"> {{item.begindepreciationdate}} </td>
                                <td scope="row" class="col-1 fontCenter"> {{item.enddepreciationdate}} </td>
                                <td scope="row" class="col-2 fontRight"> {{item.purchasevalue}} </td>
                                <td scope="row" class="col-1 fontRight"> {{item.depreciationperday}} </td>
                                <td scope="row" class="col-1 fontRight"> {{item.depreciationaccum}} </td>
                                <td scope="row" class="col-1 fontRight"> {{item.assetaccumnet}} </td>
                                <td scope="row" class="col-1 fontRight"> {{item.depreciationaccumbetweenyear}} </td>
                                <td scope="row" class="col-1 fontRight"> {{item.sumdepreciationaccumnet}} </td>
                                <td scope="row" class="col-1 fontRight"> {{item.depreciationbalancenet}} </td>
                                <td scope="row" class="col-1 fontLeft"> {{item.companycode}} </td>
                                <td scope="row" class="col-1 fontCenter"><button (click) = "deleteData(item.id)" class = "btn btn-danger text-center buttonRow"> ลบ</button></td>
                            </tr>
                        
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div class="container-fluid ">
                    <div class="container">
                        <div class="card2 text-center"><br>
                            <div class="row leftRight">
                                <div class="col-md-3 mb-3"><label class="verticalLine" for="">{{lblreporttype}}</label> </div>
                                <div class="col-md-8 mb-8"> 
                                    <div class="input-group">   
                                        <select  id="reporttype" name="reporttype" [(ngModel)]="m_datas.reporttype" class="form-control" (change)="onChange($event,'reporttype')">
                                            <option *ngFor="let rt of reporttype" [value]="rt.id">{{rt.name}} </option>
                                        </select>    
                                    </div>
                                </div>
                            </div>

                            <br>
                            <button (click) = "queryReport()" class="btn btn-success text-center">ประมวลผล</button> &nbsp;
                            <button  (click)= "generateReportPDF()" class="btn btn-success text-center">พิมพ์</button> &nbsp;
                            <button  (click)= "downloadExc()" class="btn btn-success text-center">นำออก Excel</button> 
                            <br><br>
                        </div>
                    </div>

                    <div class="card2" style="overflow-x:auto;">
                      <table id="report-table"  class="table table-hover table-bordered table-sm" cellspacing="0" width="100%" >
                          <thead>
                            <tr><td colspan="24" class="fontCenter">{{m_datas.reportname}}</td></tr>
                            <tr><td colspan="24" class="fontCenter">{{m_data.partgroupname}}</td></tr>
                            <tr><td colspan="24" class="fontCenter">จากวันที่ซื้อ : {{m_datas.frompurchasedate}} ถึงวันที่ซื้อ : {{m_datas.topurchasedate}}</td></tr>
                           
                              <tr class="text-center">
                                  <th scope="col" style="text-align: center;"> {{lblcode}}</th>
                                  <th scope="col" style="text-align: center;"> {{lblname}}</th>
                                  <th scope="col" style="text-align: center;"> {{lbldesc}}</th>
                                  <th scope="col" style="text-align: center;"> {{lblqrcode}}</th>
                                  <th scope="col" style="text-align: center;"> {{lblnote}}</th>
                                  <th scope="col" style="text-align: center;"> {{lbldocumentref}}</th>
                                  <th scope="col" style="text-align: center;"> {{lblsupplier}}</th>
                                  <th scope="col" style="text-align: center;"> {{lblpurchasedate}}</th>
                                  <th scope="col" style="text-align: center;"> {{lblunit}}</th>
                                  <th scope="col" style="text-align: center;"> {{lblgroup}}</th>
                                  <th scope="col" style="text-align: center;"> {{lbltype}}</th>
                                  <th scope="col" style="text-align: center;"> {{lbllifetime}}</th>
                                  <th scope="col" style="text-align: center;"> {{lbldepreciationrate}}</th>
                                  <th scope="col" style="text-align: center;"> {{lbldatefix}}</th>
                                  <th scope="col" style="text-align: center;"> {{lblbegindepreciationdate}}</th>
                                  <th scope="col" style="text-align: center;"> {{lblenddepreciationdate}}</th>
                                  <th scope="col" style="text-align: center;"> {{lblpurchasevalue}}</th>
                                  <th scope="col" style="text-align: center;"> {{lbldepreciationperday}}</th>
                                  <th scope="col" style="text-align: center;"> {{lbldepreciationaccum}}</th>
                                  <th scope="col" style="text-align: center;"> {{lblassetaccumnet}}</th>
                                  <th scope="col" style="text-align: center;"> {{lbldepreciationaccumbetweenyear}}</th>
                                  <th scope="col" style="text-align: center;"> {{lblsumdepreciationaccumnet}}</th>
                                  <th scope="col" style="text-align: center;"> {{lbldepreciationbalancenet}}</th>
                                  <th scope="col" style="text-align: center;"> {{lblcompany}}</th>
                              </tr>

                             
                          </thead>
                          <tbody>
                              <tr  *ngFor = "let item of r_data" >
                                  <td scope="row" class="col-1 fontLeft"> {{item.partcode}}</td>
                                  <td scope="row" class="col-2 fontLeft"> {{item.partname}} </td>
                                  <td scope="row" class="col-2 fontLeft"> {{item.partdesc}} </td>
                                  <td scope="row" class="col-2 fontCenter"> {{item.qrcode}} </td>
                                  <td scope="row" class="col-2 fontLeft"> {{item.note}} </td>
                                  <td scope="row" class="col-2 fontLeft"> {{item.documentref}} </td>
                                  <td scope="row" class="col-2 fontLeft"> {{item.suppliername}} </td>
                                  <td scope="row" class="col-2 fontLeft"> {{item.purchasedate}} </td>
                                  <td scope="row" class="col-2 fontLeft"> {{item.unitname}} </td>
                                  <td scope="row" class="col-2 fontLeft"> {{item.partgroupname}} </td>
                                  <td scope="row" class="col-2 fontLeft"> {{item.parttypename}} </td>
                                  <td scope="row" class="col-2 fontRight"> {{item.lifetime}} </td>
                                  <td scope="row" class="col-2 fontRight"> {{item.depreciationrate}} </td>
                                  <td scope="row" class="col-2 fontRight"> {{item.dateFix}} </td>
                                  <td scope="row" class="col-1 fontCenter"> {{item.begindepreciationdate}} </td>
                                  <td scope="row" class="col-1 fontCenter"> {{item.enddepreciationdate}} </td>
                                  <td scope="row" class="col-2 fontRight"> {{item.purchasevalue}} </td>
                                  <td scope="row" class="col-1 fontRight"> {{item.depreciationperday}} </td>
                                  <td scope="row" class="col-1 fontRight"> {{item.depreciationaccum}} </td>
                                  <td scope="row" class="col-1 fontRight"> {{item.assetaccumnet}} </td>
                                  <td scope="row" class="col-1 fontRight"> {{item.depreciationaccumbetweenyear}} </td>
                                  <td scope="row" class="col-1 fontRight"> {{item.sumdepreciationaccumnet}} </td>
                                  <td scope="row" class="col-1 fontRight"> {{item.depreciationbalancenet}} </td>
                                  <td scope="row" class="col-1 fontLeft"> {{item.companycode}} </td>
                              </tr>

                              <tr>
                                <td scope="row" class="col-1 fontCenter">รวม</td>
                                <td scope="row" class="col-1 fontCenter" colspan="15"></td>
                                <td scope="row" class="col-1 fontRight">{{total1}}</td>
                                <td scope="row" class="col-1 fontRight">{{total2}}</td>
                                <td scope="row" class="col-1 fontRight">{{total3}}</td>
                                <td scope="row" class="col-1 fontRight">{{total4}}</td>
                                <td scope="row" class="col-1 fontRight">{{total5}}</td>
                                <td scope="row" class="col-1 fontRight">{{total6}}</td>
                                <td scope="row" class="col-1 fontRight">{{total7}}</td>
                                <td scope="row" class="col-1 fontRight"></td>
                              </tr>
                             
                          </tbody>
                      </table>
                    </div>
                  </div>
            </div>
          </div>




      

   </div>
</body>