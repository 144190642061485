import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';
import { c_user_group } from '../app.class/c_user_group';
import { map } from 'rxjs/operators';
import { GC } from '../common/global-constants';
import { TokenStorageService } from '../_services/token-storage.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})


export class s_user_group {

  private baseURL = "";
  // private baseVisitURL = GC.httpPath + "goods_count";
  private person : any;
  tP : String ="usergroup";

  constructor(private httpClient: HttpClient, private tokenStorage: TokenStorageService) { 
    this.baseURL = GC.httpPath + this.tP;
    GC.username = this.tokenStorage.getUser().username;
  }
  
  getDataList(mr? : c_user_group): Observable<c_user_group[]>{
    
    this.person= this.httpClient.get(this.baseURL).pipe(map((response: any) => response.json()));
    this.baseURL = GC.httpPath + this.tP +"/allkey?id="+ mr.id + "&groupname=" + mr.groupname + "&groupstatus=" + mr.groupstatus + "&companyid=" + mr.companyid;
      // alert(this.baseURL); 
    return this.httpClient.get<c_user_group[]>(`${this.baseURL}`);
  }
  
  getDataByMyQuery(ind? :number ,mt? : c_user_group): Observable<c_user_group>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));
 
    this.baseURL = GC.httpPath + this.tP +"/myquery?ind=" + ind +"&id="+ mt.id  ;
        // alert(this.baseURL); 
      return this.httpClient.get<c_user_group>(`${this.baseURL}`);
  }

  createData(data: c_user_group): Observable<Object>{
    data.createby=GC.username;
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
   
    //alert(`${this.baseURL}`);
    return this.httpClient.post(`${this.baseURL}`, data);
  }

  getDataById(id: number): Observable<c_user_group>{
    this.baseURL = GC.httpPath + this.tP
    // alert(`${this.baseURL}`);
    return this.httpClient.get<c_user_group>(`${this.baseURL}/${id}`);
  }

  updateData(id: number, data: c_user_group): Observable<Object>{
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
    // alert(`${this.baseURL}/${id}`);
    return this.httpClient.put(`${this.baseURL}/${id}`, data);
  }

  deleteData(id: number): Observable<Object>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }
}