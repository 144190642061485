import { OnInit } from "@angular/core";
import { c_masterRef } from "../app.class/c_masterref";
import { s_masterRef } from "../app.service/s_masterRef.service";

export class globalfunction  {

  constructor(private  masterrefService: s_masterRef,) {}

  //  ngOnInit(): void {}


    public static callFunctionS() :string {

        return "eee"
    }

    public static getMaster(id? : number,mr? : c_masterRef) : c_masterRef[] {
      // let masterrefService: s_masterRef;
       let mr_master :c_masterRef[]=new Array();
      //  alert(id)
        //   masterrefService.getDataList(-5,mr).subscribe(data => {
        //   mr_master=new Array();//ไว้สำหรับนำมาแสดง
        //   mr_master=data;

        //   alert(mr_master.length)
    
        // //   this.getDepartment(GC.mr_dept); 
        // //   this.getLocation(GC.mr_location); 

         
          
        // });

        // this.masterrefService.getDataList(id,mr).subscribe(data => {
        //   this.mr_master=new Array();//ไว้สำหรับนำมาแสดง
        //   this.mr_master=data;
    
        //   this.getDepartment(GC.mr_dept); 
        //   this.getLocation(GC.mr_location); 
          
        // });

        return mr_master;
      }
}