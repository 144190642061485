import { Component, OnInit } from '@angular/core';
import { c_user } from '../../app.class/c_user';
import { s_user } from '../../app.service/s_user.service';
import { s_user_group } from '../../app.service/s_user_group.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GC } from '../../common/global-constants';
import { Observable } from 'rxjs';
import { filedata } from 'src/app/app.class/filedata';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/_services/auth.service';
import { c_user_group } from 'src/app/app.class/c_user_group';

var pdfMake = require('node_modules/pdfmake/build/pdfmake.js');
var pdfFonts = require('node_modules/pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-frm-user',
  templateUrl: './frm-user.component.html',
  styleUrls: ['./frm-user.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})
export class FrmUserComponent implements OnInit {
  mum_data: c_user[]=new Array();//ไว้สำหรับนำมาแสดง
  s_data: c_user[]=new Array();//ไว้สำหรับนำมาแสดง
  m_data: c_user = new c_user();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  m_datas: c_user = new c_user();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข

  ug_usergroup :c_user_group[]=new Array();
  m_ug: c_user_group = new c_user_group();
  id: number=0 ;
  isLoggedIn: boolean=true;
  eventsFlag : string ="A";
  eventsText : string="";
  role1 : boolean= false;
  BlogId : number=0;

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  fileInfos: Observable<any> | undefined;
  t_fileInfos : filedata[] =new Array();
  file: filedata = new filedata();

  httpPart : string ="";


  itemCode : string="";
  itemName : string="";
  itemDesc : string="";
  persontype : number=0;
  nameForm : string="";

  lblId : string="Id";
  lblemail : string="อีเมล์";
  lblpassword : string="รหัสผ่าน";
  lblusername : string="ชื่อผู้ใช้งาน";
  lblusergroup : string ="กลุ่ม";
  lblcompany : string="บริษัท";

  form: any = {};
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';


  constructor(private dataService: s_user,
    private router: Router,private formBuilder: UntypedFormBuilder,private route: ActivatedRoute,private tokenStorageService: TokenStorageService
    ,private cookieService: CookieService,private authService: AuthService,private userGroupService: s_user_group) {
      
    }

   ngOnInit(): void {
      this.role1 = !!this.tokenStorageService.getToken();

      if ((this.role1)  )
      {
        if (GC.username==='admin')
        {
          this.role1=true;
        }else{
          this.role1=false;
        }
        
      }

      // this.role1=true;//ชั่วคราว
      // this.cookieService.delete('companyid', '');
      // this.cookieService.set( 'companyid', ); 

      this.httpPart=GC.httpPath;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.persontype = this.route.snapshot.params['id'];

      GC.companyid =Number(this.cookieService.get('companyid'));
      this.getUserGroup(this.m_ug);

      this.queryData();
   }

   private getUserGroup(cl? : c_user_group) {
    
    cl.id=-1;
    cl.companyid=GC.companyid;
    this.userGroupService.getDataList(cl).subscribe(data => {
      this.ug_usergroup=new Array();//ไว้สำหรับนำมาแสดง
      this.ug_usergroup=data;
      
    });
  }

   queryData(){
    this.m_datas.companyid=GC.companyid;
    this.getData(this.persontype,this.m_datas);
   }

   private getData(id? : number,mr? : c_user){
    this.dataService.getDataList(mr).subscribe(data => {
        this.s_data=data;
    });
  }

  addData(){
    this.m_data = new c_user();
    this.showModal = true; // Show-Hide Modal Check
    this.eventsFlag="A"; 
    this.eventsText ="เพิ่มข้อมูล " + this.nameForm;
  }

   updateData(id: number){
    this.id=id;

    this.m_data=new c_user();
    
    this.m_data.companyid=GC.companyid;
    this.m_data.id=id;
    this.dataService.getDataList(this.m_data).subscribe(data => {
      this.mum_data=data;

      this.m_data=new c_user();
      this.mum_data.forEach( (element : any) =>  {
        this.m_data=element;
      
      });

    });


    this.showModal = true; // Show-Hide Modal Check
    this.eventsFlag="E";
    this.eventsText ="แก้ไขข้อมูล " + this.nameForm;

   }
 
   deleteData(id: number){
    let isConfirm : Boolean | any ;
    isConfirm=confirm("คุณแน่ในหรือไม่ที่ต้องการลบข้อมูล "+ id)
    if(isConfirm===false) {
      return;
    }
      this.dataService.deleteData(id).subscribe( data => {
        console.log(data);
        this.queryData();
    
      })
      this.eventsFlag="D";
    
  }

  onSubmit(AddEditMode? : string): void {
   if (AddEditMode=="A") {
      if (GC.companyid> 0)
      {
        this.m_data.companyid=GC.companyid;
        
        this.dataService.createData(this.m_data).subscribe(
        data => {
        console.log(data);
        this.isSuccessful = true;
        this.isSignUpFailed = false;
        // this.showModal = false;
         }
        , err => { 
          this.errorMessage = err.error.message;
          this.isSignUpFailed = true;});
      }else
      {
        alert("กรุณาระบุ บริษัท")
      }
     }else if (AddEditMode=="E"){
      
       this.dataService.updateData(this.id, this.m_data).subscribe( data =>{
         
         console.log(data);
        //  this.queryData();
       }
       , err => { 
           this.errorMessage = err.error.message;
           this.isSignUpFailed = true;});
     }

     setTimeout(() => 
     {
      this.queryData();
     },500);

      this.hide();
  }
 //for Submit form when action
  //  onSubmit(AddEditMode : string){
   
  //    if (AddEditMode=="A") {

  //     if (GC.companyid> 0)
  //     {
  //       if (this.persontype !=1)//ถ้าเป็น บริษัท ให้เลือกทุกบริษัท
  //       {
  //         this.m_data.companyid=GC.companyid;
  //       }
        
  //       // this.m_data.persontype=this.persontype;
        

  //       this.dataService.createData(this.m_data).subscribe( data =>{ console.log(data);},
  //       error => console.log(error));
  //     }else
  //     {
  //       alert("กรุณาระบุ บริษัท")
  //     }
  //    }else if (AddEditMode=="E"){
  //      this.dataService.updateData(this.id, this.m_data).subscribe( data =>{
         
  //        console.log(data);
  //       //  this.queryData();
  //      }
  //      , error => console.log(error));
  //    }

  //    setTimeout(() => 
  //    {
  //     this.queryData();
  //    },500);

  //     this.hide();
  //  }
 
 //for popup form
   showModal: boolean | any;
   registerForm: UntypedFormGroup | any;
   submitted = false;
 
  show()
    { 
      this.showModal = true;
    }

   hide()
   {
     this.showModal = false;
   }

   clearForm() {
    this.m_datas=new c_user();
  }

   verifyUser(Us : boolean){
     GC.role1=Us;
     this.role1=GC.role1;
   }

   async generateReportPDF() { 
  //   let docDefinition : any;
  
  
  //   let rows = [];
  //   //Header column
  //   rows.push([ { text: '#',bold: true, alignment: 'center' }, 
  //               { text: this.lblCode,bold: true, alignment: 'center' }, 
  //               { text: this.lblName, bold: true,alignment: 'center' }, 
  //               { text: 'เลขที่ผู้เสียภาษี',bold: true, alignment: 'center' }, 
  //               { text: 'ที่อยู่1',bold: true, alignment: 'center' }, 
  //               { text: 'ที่อยู่2',bold: true, alignment: 'center' }, 
  //               { text: 'บริษัท',bold: true, alignment: 'center' }]);
  
  // //Detail column
  //   let i: number=0;
  //   this.s_data.forEach( (element : c_user) =>  {
  //     i++;
  //     rows.push([
  //     { text: i, alignment: 'right'}, 
  //     { text: element.personcode, alignment: 'center'}, 
  //     { text: element.personname, alignment: 'left'}, 
  //     { text: element.taxid, alignment: 'left'}, 
  //     { text: element.address1, alignment: 'left'}, 
  //     { text: element.address2, alignment: 'left'}, 
  //     { text: element.companycode, alignment: 'center'}, 
  //     ]);
  //   });
  
  //   let groupName : string="";
  //   let pageMaginTop : number=50;
  
  //   pdfMake.fonts = {
  //     THSarabunNew: {
  //       normal: 'THSarabunNew.ttf',
  //       bold: 'THSarabunNew-Bold.ttf',
  //       italics: 'THSarabunNew-Italic.ttf',
  //       bolditalics: 'THSarabunNew-BoldItalic.ttf'
  //       },
  //     Roboto: {
  //       normal: 'Roboto-Regular.ttf',
  //       bold: 'Roboto-Medium.ttf',
  //       italics: 'Roboto-Italic.ttf',
  //       bolditalics: 'Roboto-MediumItalic.ttf'
  //     }
  //     };
  
  //      docDefinition = {
  //       pageSize: 'A4',
  //       pageMargins: [ 20,pageMaginTop,20,30 ],
  //       pageOrientation: 'landscape' ,
  //       header: {
  //         margin: 20,
          
  //         columns: [
  //             {
  //               stack: [
  //                 {
  //                   columns: [
  //                     {
  //                       text: 'รายงาน ข้อมูล' + this.nameForm,
  //                       bold: true,
  //                       width: '*',
  //                       fontSize: 18,
  //                       alignment: 'center',
  //                     }
  //                   ],
  //                 },
  //                 {
  //                   columns: [
  //                     {
  //                       text: groupName,
  //                       bold: true,
  //                       width: '*',
  //                       fontSize: 15,
  //                       alignment: 'center',
  //                     }
  //                   ],
  //                 },
  //               ],
  //             }
  
              
  //         ],
  //       },
  //       footer: function (currentPage : any, pageCount : any) {
  //         return [{ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'center' }];
  //       },
  
  //       content: [
  //         {
            
  //           table: {
  //             headerRows: 1,
  //                 widths: [20, 45, 190, 60, 193, 193, 30],//745 แนวนอน
  //                 body: rows,
  //           },
  //         },
    
  //       ],
  //       styles: {
  //         notesTitle: {
  //           fontSize: 10,
  //           bold: true,
  //           margin: [0, 50, 0, 3],
  //         },
  //         notesText: {
  //           fontSize: 10,
  //         },
  //       },
  //       defaultStyle: {
  //         columnGap: 14,
  //         font: 'THSarabunNew'
  //       },
  //     };
  //      pdfMake.createPdf(docDefinition).open();
     } 
   
}

