
import { Component, OnInit } from '@angular/core';
import { c_persons } from '../../app.class/c_persons';
import { s_persons } from '../../app.service/s_persons.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GC } from '../../common/global-constants';
import { UploadFilesService } from 'src/app/app.service/upload-files.service';
import { Observable } from 'rxjs';
import { filedata } from 'src/app/app.class/filedata';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { CookieService } from 'ngx-cookie-service';

var pdfMake = require('node_modules/pdfmake/build/pdfmake.js');
var pdfFonts = require('node_modules/pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-frm-persons',
  templateUrl: './frm-persons.component.html',
  styleUrls: ['./frm-persons.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})
export class FrmPersonsComponent implements OnInit {
  mum_data: c_persons[]=new Array();//ไว้สำหรับนำมาแสดง
  s_data: c_persons[]=new Array();//ไว้สำหรับนำมาแสดง
  m_data: c_persons = new c_persons();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  m_datas: c_persons = new c_persons();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  id: number=0 ;
  isLoggedIn: boolean=true;
  eventsFlag : string ="A";
  eventsText : string="";
  role1 : boolean= false;
  BlogId : number=0;

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  fileInfos: Observable<any> | undefined;
  t_fileInfos : filedata[] =new Array();
  file: filedata = new filedata();

  httpPart : string ="";


  itemCode : string="";
  itemName : string="";
  itemDesc : string="";
  persontype : number=0;
  nameForm : string="";

  lblId : string="Id";
  lblCode : string="รหัส";
  lblName : string="ชื่อ";
  lblDesc : string="รายละเอียด";
  lblTax : string="เลขที่ผู้เสียภาษี";
  lblAddress1 : string="ที่อยู่1";
  lblAddress2 : string="ที่อยู่2";
  lblcompany : string="บริษัท";


  constructor(private dataService: s_persons,
    private router: Router,private formBuilder: UntypedFormBuilder,private route: ActivatedRoute,private tokenStorageService: TokenStorageService
    ,private cookieService: CookieService) {
      
    }

   ngOnInit(): void {
      this.role1 = !!this.tokenStorageService.getToken();
      this.httpPart=GC.httpPath;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.persontype = this.route.snapshot.params['id'];

      this.nameForm = this.route.snapshot.params['name'];
      this.lblCode=this.lblCode+ this.nameForm;
      this.lblName=this.lblName+ this.nameForm;

      GC.companyid =Number(this.cookieService.get('companyid'));

  
      this.queryData();
   }

   queryData(){

    if ((this.persontype==1 ) || (this.persontype==2) || (this.persontype==3))//ถ้าเป็น บริษัท ให้เลือกทุกบริษัท
    {
      this.m_datas.iscompany=true;
    }

    this.m_datas.companyid=GC.companyid;
    this.getData(this.persontype,this.m_datas);
   }

   private getData(id? : number,mr? : c_persons){
    this.dataService.getDataList(id,mr).subscribe(data => {
        this.s_data=data;
    });
  }

  addData(){
    this.m_data = new c_persons();
    this.showModal = true; // Show-Hide Modal Check
    this.eventsFlag="A"; 
    this.eventsText ="เพิ่มข้อมูล " + this.nameForm;
  }

   updateData(id: number){
    this.id=id;

    this.m_data=new c_persons();

    if (this.persontype==1)//ถ้าเป็น บริษัท ให้เลือกทุกบริษัท
    {
      this.m_data.iscompany=true;
    }
    
    this.m_data.companyid=GC.companyid;
    this.m_data.id=id;
    this.dataService.getDataList(-1,this.m_data).subscribe(data => {
      this.mum_data=data;

      this.m_data=new c_persons();
      this.mum_data.forEach( (element : any) =>  {
        this.m_data=element;
      
      });

    });


    //  this.id=id;
    //  this.dataService.getDataById(this.id).subscribe(data => {
    //    this.m_data = data;
    //  }, error => console.log(error));
    this.showModal = true; // Show-Hide Modal Check
    this.eventsFlag="E";
    this.eventsText ="แก้ไขข้อมูล " + this.nameForm;
   }
 
   deleteData(id: number,blogId?: number,fileId1? : string,fileId2? : string ){
    let isConfirm : Boolean | any ;
    isConfirm=confirm("Are you sure to delete "+ id)
    if(isConfirm===false) {
      return;
    }
      this.dataService.deleteData(id).subscribe( data => {
        console.log(data);
        this.queryData();
    
      })
      this.eventsFlag="D";
    
  }

 //for Submit form when action
   onSubmit(AddEditMode : string){
   
     if (AddEditMode=="A") {

      if (GC.companyid> 0)
      {
        if (this.persontype !=1)//ถ้าเป็น บริษัท ให้เลือกทุกบริษัท
        {
          this.m_data.companyid=GC.companyid;
        }
        
        this.m_data.persontype=this.persontype;
        

        this.dataService.createData(this.m_data).subscribe( data =>{ console.log(data);},
        error => console.log(error));
      }else
      {
        alert("กรุณาระบุ บริษัท")
      }

      //  this.m_data.persontype=this.persontype;
      //  this.dataService.createData(this.m_data).subscribe( data =>{
      //    console.log(data);

      //    this.queryData();
      //  },
      //  error => console.log(error));
     }else if (AddEditMode=="E"){
       this.dataService.updateData(this.id, this.m_data).subscribe( data =>{
         
         console.log(data);
        //  this.queryData();
       }
       , error => console.log(error));
     }

     setTimeout(() => 
     {
      this.queryData();
     },500);

      this.hide();
   }
 
 //for popup form
   showModal: boolean | any;
   registerForm: UntypedFormGroup | any;
   submitted = false;
 
  show()
    { 
      this.showModal = true;
    }

   hide()
   {
     this.showModal = false;
   }

   clearForm() {
    this.m_datas=new c_persons();
  }

   verifyUser(Us : boolean){
     GC.role1=Us;
     this.role1=GC.role1;
   }

   async generateReportPDF() { 
    let docDefinition : any;
  
  
    let rows = [];
    //Header column
    rows.push([ { text: '#',bold: true, alignment: 'center' }, 
                { text: this.lblCode,bold: true, alignment: 'center' }, 
                { text: this.lblName, bold: true,alignment: 'center' }, 
                { text: 'เลขที่ผู้เสียภาษี',bold: true, alignment: 'center' }, 
                { text: 'ที่อยู่1',bold: true, alignment: 'center' }, 
                { text: 'ที่อยู่2',bold: true, alignment: 'center' }, 
                { text: 'บริษัท',bold: true, alignment: 'center' }]);
  
  //Detail column
    let i: number=0;
    this.s_data.forEach( (element : c_persons) =>  {
      i++;
      rows.push([
      { text: i, alignment: 'right'}, 
      { text: element.personcode, alignment: 'center'}, 
      { text: element.personname, alignment: 'left'}, 
      { text: element.taxid, alignment: 'left'}, 
      { text: element.address1, alignment: 'left'}, 
      { text: element.address2, alignment: 'left'}, 
      { text: element.companycode, alignment: 'center'}, 
      ]);
    });
  
    let groupName : string="";
    let pageMaginTop : number=50;
  
    pdfMake.fonts = {
      THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNew-Bold.ttf',
        italics: 'THSarabunNew-Italic.ttf',
        bolditalics: 'THSarabunNew-BoldItalic.ttf'
        },
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
      }
      };
  
       docDefinition = {
        pageSize: 'A4',
        pageMargins: [ 20,pageMaginTop,20,30 ],
        pageOrientation: 'landscape' ,
        header: {
          margin: 20,
          
          columns: [
              {
                stack: [
                  {
                    columns: [
                      {
                        text: 'รายงาน ข้อมูล' + this.nameForm,
                        bold: true,
                        width: '*',
                        fontSize: 18,
                        alignment: 'center',
                      }
                    ],
                  },
                  {
                    columns: [
                      {
                        text: groupName,
                        bold: true,
                        width: '*',
                        fontSize: 15,
                        alignment: 'center',
                      }
                    ],
                  },
                ],
              }
  
              
          ],
        },
        footer: function (currentPage : any, pageCount : any) {
          return [{ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'center' }];
        },
  
        content: [
          {
            
            table: {
              headerRows: 1,
                  widths: [20, 45, 190, 60, 193, 193, 30],//745 แนวนอน
                  body: rows,
            },
          },
    
        ],
        styles: {
          notesTitle: {
            fontSize: 10,
            bold: true,
            margin: [0, 50, 0, 3],
          },
          notesText: {
            fontSize: 10,
          },
        },
        defaultStyle: {
          columnGap: 14,
          font: 'THSarabunNew'
        },
      };
       pdfMake.createPdf(docDefinition).open();
     } 
   
}

