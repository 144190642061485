export class c_partitems{
    id : number=0;
    partcode: string="";
    documentref: string="";
    partname: string="";
    partdesc: string="";
    statusflag: string="";
    unitid : number =0;
    partgroupid : number =0;
    parttypeid : number =0;
    supplierid : number =0;
    companyid : number =0;
    inputmanual :boolean=false;
    configdoctype: number =0;
    depreciationflag: boolean =false;
    subunitid : number =0;
    convertval : number =0;
    parttype : number =0;


    qrcode :string="";
    note:string="";
    purchasedate :string="";
    inspectiondate :string="";
    begindepreciationdate :string="";
    enddepreciationdate :string="";
    purchasevalue : number =0;
    depreciationrate : number =0;
    lifetime : number =0;
    carcassprice : number =0;

    createby : string ="";
    createdate :string="";
    modifyby: string="";
    modifydate :string="";

    unitname : string ="";
    partgroupname : string ="";
    parttypename : string ="";
    createamount : number =1;
    companycode : string ="";

    depreciationaccum : number =0;
    depreciationaccumdate :string="";
    depreciationaccumbetweenyear : number =0;
    assetaccumnet: number =0;
    depreciationbalancenet : number =0;
    sumdepreciationaccumnet: number =0;
    depreciationperday : number =0;

    ignoreid : number=0;
    useflagset : string ="";
    partsubid: number=0;

    suppliername : string ="";

    dateFix : string ="";
    depreciationaccumlastyeardate : string =""; 

    frompurchasedate :string="";
    topurchasedate :string="";

    reporttype : number=0;
    reportname : string="";
}