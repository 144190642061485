<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>


<body class="ht">
  <div #back></div>

  
 <div  [style.display]="role1 ? 'block' : 'none'" >
    <div id="bottom"  >
        <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
       </div>
 
   <div class="card2"><br><br>
      <span>
          <h1>{{nameForm2}}</h1> 
      </span>
      <span class="example-spacer"></span>


    <div class="container">
        <span class="bg-light">Id : {{m_data.id}}</span>
        <div class="card2"><br>
            <form  (ngSubmit)="f.form.valid && onSubmitMaster(eventsFlag)" #f="ngForm" novalidate>

                <div class="row leftRight">
                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblcode}}</label> </div>
                    <div class="col-md-3 mb-3"> 

                        <ng-container  *ngIf="m_data.inputmanual;else other4" >
                            <div class="input-group">
                                <input  type="text" id="partcode" name="partcode" [(ngModel)]="m_data.partcode" class="form-control"
                                    required minlength="6" #partcode="ngModel" [ngClass]="{ 'is-invalid': f.submitted && partcode.errors }">  
                                    <div *ngIf="partcode.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="partcode.errors['required']">กรุณาระบุ {{lblcode}}</div>
                            </div>          
                            </div>
                        </ng-container>
                        <ng-template #other4>
                            <div class="input-group">
                                <input  type="text" id="partcode" name="partcode" [(ngModel)]="m_data.partcode" class="form-control" disabled="true"> 
                            </div>
                        </ng-template>
                    
                    </div>

                    <ng-container  *ngIf="eventsFlag =='A';else other5" >
                        <div class="col-md-1 mb-1"> <label><input type="checkbox" (click) = "addManual(m_data.inputmanual)" id="autobegingflag" name="autobegingflag" [(ngModel)]="m_data.inputmanual"> {{lblInputManual}}</label>   </div>
                    </ng-container>
                    <ng-template #other5>
                        <div class="col-md-1 mb-1"> <label><input type="checkbox" (click) = "addManual(m_data.inputmanual)" id="autobegingflag" name="autobegingflag" [(ngModel)]="m_data.inputmanual" disabled="true"> {{lblInputManual}}</label>   </div>
                    </ng-template>


       

                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblname}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <input  type="text" id="partname" name="partname" [(ngModel)]="m_data.partname" class="form-control"
                            required minlength="1" #partname="ngModel" [ngClass]="{ 'is-invalid': f.submitted && partname.errors }">  
                            <div *ngIf="partname.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="partname.errors['required']">กรุณาระบุ {{lblname}}</div>
                            </div>            
                        </div>
                    </div>
                </div>

                <div class="row leftRight">
                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbldesc}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <input  type="text" id="partdesc" name="partdesc" [(ngModel)]="m_data.partdesc" class="form-control">           
                        </div>
                    </div>

                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblsupplier}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <select id="supplierid" name="supplierid" [(ngModel)]="m_data.supplierid" class="form-control">
                                <option *ngFor="let ms of ps_supplier" [value]="ms.id">{{ms.personcode}} : {{ms.personname}} </option>
                            </select> 
                        </div>
                    </div>

 
                </div>

                <div class="row leftRight">
                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblgroup}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <select id="partgroupid" (ngModelChange)="searchPartType($event)" name="partgroupid" [(ngModel)]="m_data.partgroupid" class="form-control"
                            required #partgroupid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && partgroupid.errors }">
                                <option *ngFor="let ms of mr_itemGroup" [value]="ms.id">{{ms.keycode}} : {{ms.keyname}} </option>
                            </select> 
                            <div *ngIf="partgroupid.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="partgroupid.errors['required']">กรุณาระบุ {{lblgroup}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbltype}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <select id="parttypeid" name="parttypeid" [(ngModel)]="m_data.parttypeid" class="form-control"
                            required minlength="1" #parttypeid="ngModel" [ngClass]="{ 'is-invalid': f.submitted && parttypeid.errors }">
                                <option *ngFor="let ms of mr_itemType" [value]="ms.id">{{ms.keycode}} : {{ms.keyname}} </option>
                            </select> 

                            <div *ngIf="parttypeid.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="parttypeid.errors['required']">กรุณาระบุ {{lblstatus}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row leftRight">
                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblsubunit}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <input  type="text" id="convertval" name="convertval"  [(ngModel)]="m_data.convertval" class="form-control">&nbsp;&nbsp;&nbsp;
                            <select id="subunitid" name="subunitid" [(ngModel)]="m_data.subunitid" class="form-control" 
                            required #subunitid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && subunitid.errors }">
                                <option *ngFor="let ms of mr_unit" [value]="ms.id">{{ms.keyname}} </option>
                            </select>  
                            <div *ngIf="subunitid.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="subunitid.errors['required']">กรุณาระบุ {{lblsubunit}}</div>
                            </div>
                        
                        </div>
                    </div>

                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblunit}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <select id="unitid" name="unitid" [(ngModel)]="m_data.unitid" class="form-control" 
                            required #unitid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && unitid.errors }">
                                <option *ngFor="let ms of mr_unit" [value]="ms.id">{{ms.keyname}} </option>
                            </select>  
                            <div *ngIf="unitid.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="unitid.errors['required']">กรุณาระบุ {{lblunit}}</div>
                            </div>
                        
                        </div>
                    </div>

                   
                </div>



                <div class="row leftRight">
                    <div class="col-md-3 mb-3 fontCenter"></div>

                    <div class="col-md-6 mb-6 fontCenter">
                        <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit" (click)="generateReportPDF()">พิมพ์</button>&nbsp;
                        <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit" (click)="eventBotton(1)">บันทึก</button> &nbsp;
                        <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit" (click)="eventBotton(2)">บันทึก&ออก</button> &nbsp;
                        <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="close()">ออก</button>
                        <br><br>
                    </div>

                    <div class="col-md-3 mb-3 fontCenter"></div>
                </div>
          </form>
        </div>
        
    </div>

   </div>
</div>


</body>