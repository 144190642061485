import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenubarComponent } from './app.components/menubar/menubar.component';
import { FooterbarComponent } from './app.components/footerbar/footerbar.component';
import { HttpClientModule } from '@angular/common/http'
import { ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { UploadFilesComponent } from './app.components/upload-files/upload-files.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxImgZoomModule  } from 'ngx-img-zoom';
import { LoginComponent } from './app.components/login/login.component';
import { RegisterComponent } from './app.components/register/register.component';

import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { FrmMasterComponent } from './app.components/frm-master/frm-master.component';
import { FrmPersonsComponent } from './app.components/frm-persons/frm-persons.component';
import { FrmPartItemsComponent } from './app.components/frm-part-items/frm-part-items.component';
import { FrmBillingdocComponent } from './app.components/frm-billingdoc/frm-billingdoc.component';
import { FrmPartItemsSubComponent } from './app.components/frm-part-items-sub/frm-part-items-sub.component';
import { FrmBillingdocItemsComponent } from './app.components/frm-billingdoc-items/frm-billingdoc-items.component';
import { FrmInformationAssetComponent } from './app.components/frm-information-asset/frm-information-asset.component';
import { FrmManageDoccodeComponent } from './app.components/frm-manage-doccode/frm-manage-doccode.component';
import { FrmTestComponent } from './app.components/frm-test/frm-test.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FrmUserComponent } from './app.components/frm-user/frm-user.component';
import { FrmMainpageComponent } from './app.components/frm-mainpage/frm-mainpage.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FrmSearchComponent } from './app.components/frm-search/frm-search.component';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { FrmItemsSupComponent } from './app.components/frm-items-sup/frm-items-sup.component';
import { FrmUserGroupComponent } from './app.components/frm-user-group/frm-user-group.component';
// import { IgxTabsModule } from 'igniteui-angular';

@NgModule({
  declarations: [
    AppComponent,
    MenubarComponent,
    FooterbarComponent,
    UploadFilesComponent,
    LoginComponent,
    RegisterComponent,
    FrmMasterComponent,
    FrmPersonsComponent,
    FrmPartItemsComponent,
    FrmBillingdocComponent,
    FrmPartItemsSubComponent,
    FrmBillingdocItemsComponent,
    FrmInformationAssetComponent,
    FrmManageDoccodeComponent,
    FrmTestComponent,
    FrmUserComponent,
    FrmMainpageComponent,
    FrmSearchComponent,
    FrmItemsSupComponent,
    FrmUserGroupComponent,
    // FrmBillingsupItemsComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgxImgZoomModule,
    QRCodeModule,PdfViewerModule,
    Ng2SearchPipeModule,DropDownListModule
    // IgxTabsModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
