import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';
import { c_partitems_sub } from '../app.class/c_partitems_sub';
import { map } from 'rxjs/operators';
import { GC } from '../common/global-constants';
import { TokenStorageService } from '../_services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class s_partitems_sub {

  private baseURL = "";
  private baseVisitURL = GC.httpPath + "goods_count";
  private person : any;
  tP : String ="partitemssub";

  constructor(private httpClient: HttpClient, private tokenStorage: TokenStorageService) { 
    this.baseURL = GC.httpPath + this.tP;
    GC.username = this.tokenStorage.getUser().username;
  }
  
  getDataList(ind? :number ,mt? : c_partitems_sub): Observable<c_partitems_sub[]>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));
 
    this.baseURL = GC.httpPath + this.tP +"/allkey?ind=" + ind +"&id="+ mt.id + "&partid=" + mt.partid + "&useflagset=" + mt.useflagset + "&ignoreid=" + mt.ignoreid + "&qrcode=" + mt.qrcode;
    return this.httpClient.get<c_partitems_sub[]>(`${this.baseURL}`);
  }

  getDataListById(ind? :number ,mt? : c_partitems_sub): Observable<c_partitems_sub[]>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));
 
    this.baseURL = GC.httpPath + this.tP +"/fixbyid?ind=" + ind + "&qrcode=" + mt.qrcode;

    // alert(this.baseURL);
    return this.httpClient.get<c_partitems_sub[]>(`${this.baseURL}`);
  }

  createData(data: c_partitems_sub): Observable<Object>{
    data.createby=GC.username;
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
    //alert(this.baseURL);
    return this.httpClient.post(`${this.baseURL}`, data);
  }

  getDataById(id: number): Observable<c_partitems_sub>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.get<c_partitems_sub>(`${this.baseURL}/${id}`);
  }

  getDataByMyQuery(ind? :number ,mt? : c_partitems_sub): Observable<c_partitems_sub>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));
 
    this.baseURL = GC.httpPath + this.tP +"/myquery?ind=" + ind +"&id="+ mt.id + "&partid=" + mt.partid ;
      //alert(this.baseURL); 
      return this.httpClient.get<c_partitems_sub>(`${this.baseURL}`);
  }

  updateData(id: number, data: c_partitems_sub): Observable<Object>{
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
    // alert(this.baseURL + "/" + id);
    return this.httpClient.put(`${this.baseURL}/${id}`, data);
  }

  updateVisits(id: number, data: c_partitems_sub): Observable<Object>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.put(`${this.baseVisitURL}/${id}`, data);
  }

  deleteData(id: number): Observable<Object>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }

  exportQRCode(mt : c_partitems_sub)
  {
    this.baseURL = GC.httpPath + this.tP +"/qrcode?id="+ mt.id;
    var authorization = 'Bearer '+sessionStorage.getItem("access_token");
    const headers = new HttpHeaders({ 'Content-Type': 'application/json',"Authorization": authorization, responseType : 'blob'});
     
    //  alert(this.baseURL);
    // return this.httpClient.get<any>(`${this.baseURL}`);
    return this.httpClient.get<Blob>(`${this.baseURL}`, { headers : headers,responseType : 'blob' as 'json'});
  }


}

