<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>

<body>
    <nav class="navbar navbar-expand-lg navbar-light fixed-top bg1">
        <div class="container-fluid ">
            <div class="bg2">
                <a class="navbar-brand-"  [routerLink]="['/frm-main-page-component']" routerLinkActive="router-link">
                    <img src={{logoPath}} alt="logo" style="width: 100px;height: 100%;">
                </a>
                
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <div *ngIf="isSystem== '1'">
                    <ul class="navbar-nav justify-content-center">

                        <li class="nav-item dropdown">
                            <a class="dropdown-item dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ข้อมูลผู้ใช้งาน</a>
                                <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" [routerLink]="['/frm-user-group-component']" routerLinkActive="router-link" >กลุ่มผู้ใช้งาน</a>
                                    <a class="dropdown-item" [routerLink]="['/frm-user-component']" routerLinkActive="router-link" >บัญชีผู้ใช้งาน</a>
                                </div>
                        </li>


                        <li class="nav-item dropdown">
                            <a class="dropdown-item dropdown-toggle" id="navbarDropdownLG" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ข้อมูลหลัก</a>
                            <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdownLG">
                                <li class=""  href="">ทั่วไป</li>
                                <!-- <a class="nav-link" [routerLink]="['/frm-master-component',mr_country,'ประเทศ']" routerLinkActive="router-link" href="#">ประเทศ</a> -->
                                <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_supType,'ประเภทผู้ขาย']" routerLinkActive="router-link" >ประเภทผู้ขาย</a>
                                <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_cusType,'ประเภทลูกค้า']" routerLinkActive="router-link" >ประเภทลูกค้า</a>
                                <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_empLevel,'ระดับพนักงาน']" routerLinkActive="router-link" >ระดับพนักงาน</a>
                                <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_dept,'แผนก']" routerLinkActive="router-link" >แผนก</a>
                                <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_location,'ที่อยู่ทรัพย์สิน']" routerLinkActive="router-link" >ที่อยู่ทรัพย์สิน</a>
                            
                                <li class=""  href="">ทรัพย์สิน</li>
                                <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_unit,'หน่วยนับ']" routerLinkActive="router-link" >หน่วยนับ</a>
                                <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_itemGroup,'หมวดสินทรัพย์']" routerLinkActive="router-link" >หมวดสินทรัพย์</a>
                                <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_itemType,'ประเภทสินทรัพย์']" routerLinkActive="router-link" >ประเภทสินทรัพย์</a>
                                
                                <!-- <li class=""  href="">บัญชี</li> -->
                                <!-- <a class="nav-link" [routerLink]="['/user-group-component']" routerLinkActive="router-link" href="#">กลุ่มผู้ใช้งาน</a>
                                <a class="nav-link" [routerLink]="['/user-component']" routerLinkActive="router-link" href="#">บัญชีผู้ใช้งาน</a> -->
                            </div>
                        </li>

                        <li class="nav-item dropdown">
                            <a class="dropdown-item dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ข้อมูลส่วนกลาง</a>
                            <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" [routerLink]="['/frm-persons-component',ps_company,'บริษัท']" routerLinkActive="router-link" >ข้อมูลบริษัท</a>
                                <a class="dropdown-item" [routerLink]="['/frm-persons-component',ps_cus,'ลูกค้า']" routerLinkActive="router-link" >ข้อมูลลูกค้า</a>
                                <a class="dropdown-item" [routerLink]="['/frm-persons-component',ps_sup,'ผู้ขาย']" routerLinkActive="router-link" >ข้อมูลผู้ขาย</a>
                                <a class="dropdown-item" [routerLink]="['/frm-persons-component',ps_emp,'พนักงาน']" routerLinkActive="router-link" >ข้อมูลพนักงาน</a>
                            </div>
                        </li>

                        <li class="nav-item dropdown">
                            <a class="dropdown-item dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ข้อมูลทรัพย์สิน</a>
                            <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" [routerLink]="['/frm-part-items-component',reg_asset,'registration_asset']" routerLinkActive="router-link" >ทะเบียนทรัพย์สิน</a>
                            </div>
                        </li>

                        <li class="nav-item dropdown">
                            <a class="dropdown-item dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ข้อมูลบัญชี</a>
                            <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" [routerLink]="['/frm-billingdoc-component',doc_receive,'doc_receive']" routerLinkActive="router-link" >รับเข้า</a>
                                <a class="dropdown-item" [routerLink]="['/frm-billingdoc-component',doc_transfer,'doc_transfer']" routerLinkActive="router-link" >โอนย้าย</a>
                                <a class="dropdown-item" [routerLink]="['/frm-billingdoc-component',doc_repair,'doc_repair']" routerLinkActive="router-link" >ส่งซ่อม</a>
                                <a class="dropdown-item" [routerLink]="['/frm-billingdoc-component',doc_receive_repair,'doc_receive_repair']" routerLinkActive="router-link" >รับคืน(ซ่อม)</a>
                                <!-- <a class="nav-link" [routerLink]="['/frm-billingdoc-component',doc_return,'doc_return']" routerLinkActive="router-link" ">คืนทรัพย์สิน</a> -->
                                <a class="dropdown-item" [routerLink]="['/frm-billingdoc-component',doc_buy,'doc_buy']" routerLinkActive="router-link" >ขายออก</a>
                                <a class="dropdown-item" [routerLink]="['/frm-information-asset.component','']" routerLinkActive="router-link" >รายงาน</a>
                            </div>
                        </li>

                        
                    

                        <!-- <li class="nav-item dropdown">
                            <a class="dropdown-item" [routerLink]="['/login']" routerLinkActive="router-link" href="#" (click)="exitSystem()">
                                <i *ngIf="isLoggedIn" class="fa fa-sign-out fa-1x" aria-hidden="true" (click)="logout()"  title="Log out"></i>
                                <i *ngIf="!isLoggedIn" class="fa fa-sign-in fa-1x" aria-hidden="true"  title="Log in"></i>
                            </a>
                            
                            
                        </li> -->

                        <!-- <div *ngIf="role1"> -->
                            <li class="nav-item dropdown">
                                <a class="dropdown-item dropdown-toggle" id="servicesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{systems}}</a>
                                <div class="dropdown-menu dropdown-menu-left " aria-labelledby="servicesDropdown">
                                    <div class="d-md-flex ">
                                        <div>
                                            <a class="dropdown-item" [routerLink]="['/login']" routerLinkActive="router-link">เข้าสู่ระบบ</a>
                                            <a class="dropdown-item" [routerLink]="['/frm-manage-doccode.component']" routerLinkActive="router-link">ตั้งค่าเลขที่เอกสาร</a>
                                            <a class="dropdown-item" [routerLink]="['/login','true']" routerLinkActive="router-link">ออกจากระบบ</a>
                                            <!-- <a class="dropdown-item" [routerLink]="['/app-frm-test']" routerLinkActive="router-link">test</a> -->
                                            
                                        </div>
                                    </div>
                                </div>
                            </li>

                        <!-- <li class="nav-item dropdown">
                            <div class="bguser">
                                <label>ผู้ใช้ : {{username}} : {{companyname}}</label>
                                <p>{{companyname}}</p>
                                <ul>
                                    <li class="nav-item dropdown">
                                        <a class="dropdown-item" [routerLink]="['/login']" routerLinkActive="router-link" href="#" (click)="exitSystem()">
                                            <i *ngIf="isLoggedIn" class="fa fa-sign-out fa-1x" aria-hidden="true" (click)="logout()"  title="Log out"></i>
                                            <i *ngIf="!isLoggedIn" class="fa fa-sign-in fa-1x" aria-hidden="true"  title="Log in"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li> -->
                
                    </ul>
               </div>

               <div *ngIf="isSystem== '2'">
                <ul class="navbar-nav justify-content-center">

                    <li class="nav-item dropdown">
                        <a class="dropdown-item dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ข้อมูลผู้ใช้งาน</a>
                            <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" [routerLink]="['/frm-user-group-component']" routerLinkActive="router-link" >กลุ่มผู้ใช้งาน</a>
                                <a class="dropdown-item" [routerLink]="['/frm-user-component']" routerLinkActive="router-link" >บัญชีผู้ใช้งาน</a>
                            </div>
                    </li>


                    <li class="nav-item dropdown">
                        <a class="dropdown-item dropdown-toggle" id="navbarDropdownLG" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ข้อมูลหลัก</a>
                        <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdownLG">
                            <li class=""  href="">ทั่วไป</li>
                            <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_supType,'ประเภทผู้ขาย']" routerLinkActive="router-link" >ประเภทผู้ขาย</a>
                            <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_cusType,'ประเภทลูกค้า']" routerLinkActive="router-link" >ประเภทลูกค้า</a>
                            <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_empLevel,'ระดับพนักงาน']" routerLinkActive="router-link" >ระดับพนักงาน</a>
                            <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_dept,'แผนก']" routerLinkActive="router-link" >แผนก</a>
                            <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_location,'ที่อยู่ทรัพย์สิน']" routerLinkActive="router-link" >ที่อยู่ทรัพย์สิน</a>
                        
                            <li class=""  href="">ทรัพย์สิน</li>
                            <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_unit,'หน่วยนับ']" routerLinkActive="router-link" >หน่วยนับ</a>
                            <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_itemGroup,'หมวดสินทรัพย์']" routerLinkActive="router-link" >หมวดสินทรัพย์</a>
                            <a class="dropdown-item" [routerLink]="['/frm-master-component',mr_itemType,'ประเภทสินทรัพย์']" routerLinkActive="router-link" >ประเภทสินทรัพย์</a>
                            
                        </div>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="dropdown-item dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ข้อมูลส่วนกลาง</a>
                        <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" [routerLink]="['/frm-persons-component',ps_company,'บริษัท']" routerLinkActive="router-link" >ข้อมูลบริษัท</a>
                            <a class="dropdown-item" [routerLink]="['/frm-persons-component',ps_cus,'ลูกค้า']" routerLinkActive="router-link" >ข้อมูลลูกค้า</a>
                            <a class="dropdown-item" [routerLink]="['/frm-persons-component',ps_sup,'ผู้ขาย']" routerLinkActive="router-link" >ข้อมูลผู้ขาย</a>
                            <a class="dropdown-item" [routerLink]="['/frm-persons-component',ps_emp,'พนักงาน']" routerLinkActive="router-link" >ข้อมูลพนักงาน</a>
                        </div>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="dropdown-item dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">สินค้า</a>
                        <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" [routerLink]="['/frm-part-items-component',reg_partitems,'registration_partitems']" routerLinkActive="router-link" >ข้อมูลสินค้า</a>
                        </div>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="dropdown-item dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">เอกสาร</a>
                        <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" [routerLink]="['/frm-billingsup-component',doc_pr,'doc_pr']" routerLinkActive="router-link" >ใบขอซื้อ (PR)</a>
                            <!-- <br> -->
                            <a class="dropdown-item" [routerLink]="['/frm-billingsup-component',doc_po,'doc_po']" routerLinkActive="router-link" >ใบสั่งซื้อ (PO)</a>
                        </div>
                    </li>

                    

                    <!-- <div *ngIf="role1"> -->
                        <li class="nav-item dropdown">
                            <a class="dropdown-item dropdown-toggle" id="servicesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{systems}}</a>
                            <div class="dropdown-menu dropdown-menu-left " aria-labelledby="servicesDropdown">
                                <div class="d-md-flex ">
                                    <div>
                                        <a class="dropdown-item" [routerLink]="['/login']" routerLinkActive="router-link">เข้าสู่ระบบ</a>
                                        <a class="dropdown-item" [routerLink]="['/frm-manage-doccode.component']" routerLinkActive="router-link">ตั้งค่าเลขที่เอกสาร</a>
                                        <a class="dropdown-item" [routerLink]="['/app-frm-test']" routerLinkActive="router-link">test</a>
                                    </div>
                                </div>
                            </div>
                        </li>
            
                </ul>
           </div>

                <div class="collapse navbar-collapse d-flex justify-content-end" id="navbarSupportedContent">
                    <ul class="navbar-nav ">
                        <li class="nav-item dropdown">
                            <div class="bguser">
                                <label>ผู้ใช้ : {{username}} : {{companyname}}</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>


           

       
        </div>
    </nav>
 </body>
</html>