import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { GC } from '../../common/global-constants';

@Component({
  selector: 'app-frm-mainpage',
  templateUrl: './frm-mainpage.component.html',
  styleUrls: ['./frm-mainpage.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})
export class FrmMainpageComponent implements OnInit {
  role1 : boolean= false;
  isSystem : String ="";
  constructor(private tokenStorageService: TokenStorageService,private cookieService: CookieService,private router: Router) { }

  ngOnInit(): void {
    this.role1 = !!this.tokenStorageService.getToken();
  }

  toSystem(ind : number)
  {
    this.cookieService.delete('isSystem', ' ');
    this.cookieService.set( 'isSystem', '' + ind); 
    this.isSystem='' + ind;

    if (this.isSystem != "")
    {
      window.location.reload();
    }
    
  }

}
