export class c_user_group{
    id : number=0;
    groupname: string="";
    groupdesc: string="";
    groupstatus: string="";
    maxuser : number =0;
    companyid : number =0;

    createby : string ="";
    createdate :string="";
    modifyby: string="";
    modifydate :string="";

    companycode : string="";
}