import { Component, OnInit } from '@angular/core';
import { c_billingdoc } from '../../app.class/c_billingdoc';
import { s_billingdoc } from '../../app.service/s_billingdoc.service';
import { c_billingdoc_items } from 'src/app/app.class/c_billingdoc_items';
import { c_datasearch } from 'src/app/app.class/c_datasearch';
import { s_billingdoc_items } from '../../app.service/s_billingdoc_items.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { GC } from '../../common/global-constants';
import { c_masterRef } from 'src/app/app.class/c_masterref';
import { s_masterRef } from 'src/app/app.service/s_masterRef.service';
import { s_persons } from 'src/app/app.service/s_persons.service';
import { s_partitems } from 'src/app/app.service/s_partItems.service';
import { s_partitems_sub } from 'src/app/app.service/s_partItems_sub.service';
import { c_persons } from 'src/app/app.class/c_persons';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { c_partitems } from 'src/app/app.class/c_partitems';
import { c_partitems_sub } from 'src/app/app.class/c_partitems_sub';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { globalfunction } from 'src/app/common/globalfunction';
import { CookieService } from 'ngx-cookie-service';
import * as XLSX from 'xlsx';
type AOA = any[][];

import { FrmSearchComponent } from "src/app/app.components/frm-search/frm-search.component";

// D:\programming\frontend\angular_asset\src\app\app.components\frm-search\frm-search.component.ts
var pdfMake = require('node_modules/pdfmake/build/pdfmake.js');
var pdfFonts = require('node_modules/pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;





@Component({
  selector: 'app-frm-billingdoc-items',
  templateUrl: './frm-billingdoc-items.component.html',
  styleUrls: ['./frm-billingdoc-items.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})

export class FrmBillingdocItemsComponent implements OnInit {
  m_data: any = {};
  // s_data: any = {};
  
  
  mum_data: c_billingdoc[]=new Array();//ไว้สำหรับนำมาแสดง
  s_data: c_billingdoc_items[]=new Array();//ไว้สำหรับนำมาแสดง
  // m_data: c_billingdoc = new c_billingdoc();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  m_datas : c_billingdoc_items = new c_billingdoc_items();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข

  m_data2: c_billingdoc = new c_billingdoc();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข

  s_data_biling_items: c_billingdoc_items[]=new Array();//ไว้้รับค่า
  m_data_biling_items : c_billingdoc_items = new c_billingdoc_items();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข

  mr_itemGroup: c_masterRef[]=new Array();//ไว้สำหรับนำมาแสดง

  m_mr: c_masterRef = new c_masterRef();
  m_ps: c_persons = new c_persons();
  m_pi: c_partitems = new c_partitems();
  m_pi_sub: c_partitems_sub = new c_partitems_sub();
  
  mr_master :c_masterRef[]=new Array();
  mr_dept: c_masterRef[]=new Array();
  mr_location: c_masterRef[]=new Array();
  mr_itemType: c_masterRef[]=new Array();

  mr_persons : c_persons[]=new Array();
  ps_customer: c_persons[]=new Array();
  ps_supplier: c_persons[]=new Array();
  ps_employee: c_persons[]=new Array();

  pi: c_partitems[]=new Array();
  pi_sub: c_partitems_sub[]=new Array();

  dataSearch: c_datasearch[]=new Array();
  indSeatch : String="";
  searchText: any;
  // showSearch: boolean | any;

  id: number=0 ;
  idmaster : number=0;
  eventsFlag : string ="";
  eventsText : string="";
  role1 : boolean= false;

  httpPart : string ="";


  itemCode : string="";
  itemName : string="";
  itemDesc : string="";
  itemStatus : string="";
  persontype : number=0;
  nameFormOrigin : string="";
  nameForm : string="";
  nameForm2 : string="";

  lblid : string="Id";
  lbldocno : string="เลขที่";
  lbldocdate : string="วันที่เอกสาร";
  lblempname : string="ผู้รับผิดชอบ";
  lblpartdept : string ="แผนก";
  lblpartlocation : string ="ที่ตั้ง";
  lblInputManual : string ="เพิ่มเอง";

  lblempreceivename : string="ผู้รับโอน";
  lbltopartdept : string ="ไปแผนก";
  lbltopartlocation : string ="ไปที่ตั้ง";

  lblcustomername : string="";
  lbldocdesc : string="หมายเหตุ";
  lblpartname : string ="ชื่อทรัพย์สิน";
  lblpartsub : string ="ทะเบียนทรัพย์สิน";

  lbltxprice : string ="ราคา";
  lbltxamount : string ="จำนวน";
  lblstatus : string ="สถานะ";

  lblinput : string="";
  lbloutput : string ="";


  lblqrcode : string ="รหัส QR Code";
  lbluseflag : string ="สถานะการใช้";

  isSave : boolean =false;
  isSaveAndExit : boolean =false;
  doctype : number=0;

  now = new Date();

  status = [
    { id: "Y", name: "ใช้งาน" },
    { id: "N", name: "ยกเลิก" }
  ];

  myDate : string;

  data: AOA = [];

  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';



 
  
  // dataSearch : any;
  //  dataSearch = new Set();  
  //  dataSearch: : { [key: string]: Object; }[] 
    

  constructor(private dataService: s_billingdoc,private dataServiceSub: s_billingdoc_items,private masterrefService: s_masterRef,private personsService: s_persons,
    private partitemService: s_partitems,private partitemSubService: s_partitems_sub,
    private router: Router,private route: ActivatedRoute,private tokenStorageService: TokenStorageService
    ,private cookieService: CookieService) {

    
    }

   ngOnInit(): void {
   
      this.role1 = !!this.tokenStorageService.getToken();
      this.httpPart=GC.httpPath;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.id  = this.route.snapshot.params['id'];
      this.idmaster  = this.route.snapshot.params['idmaster'];
      this.nameFormOrigin = this.route.snapshot.params['name'];
      this.eventsFlag= this.route.snapshot.params['addedit'];
      this.doctype = this.route.snapshot.params['doctype'];
      GC.companyid =Number(this.cookieService.get('companyid'));
      GC.companyname =(this.cookieService.get('companyname')).toString();
      this.m_data.companyid=GC.companyid;

      

      if (this.nameFormOrigin==="doc_receive"){
        this.nameForm="รับเข้า";
      }
      else if (this.nameFormOrigin==="doc_transfer"){
        this.nameForm="โอนย้าย"; 
        this.lbloutput="โอนจาก"; 
        this.lblinput="รับโอน";

        this.lblempname ="ผู้รับผิดชอบ";
        this.lblpartdept  ="แผนก";
        this.lblpartlocation  ="ที่ตั้ง";
      
        this.lblempreceivename ="ผู้รับโอน";
        this.lbltopartdept  ="ไปแผนก";
        this.lbltopartlocation  ="ไปที่ตั้ง";
      }
      else if (this.nameFormOrigin==="doc_repair"){
        this.nameForm="ส่งซ่อม"; 
        this.lblcustomername ="ผู้ขาย";
        this.lbloutput="ก่อนส่งซ่อม"; 
        this.lblinput="ขณะซ่อม";

        this.lblempname ="ผู้รับผิดชอบ";
        this.lblpartdept  ="แผนก";
        this.lblpartlocation  ="ที่ตั้ง";
      
        this.lblempreceivename ="ผู้รับผิดชอบ";
        this.lbltopartdept  ="ไปแผนก";
        this.lbltopartlocation  ="ไปที่ตั้ง";
        // alert("e")
        // this.getSupplier(GC.ps_sup);
      }
      else if (this.nameFormOrigin==="doc_receive_repair"){
        this.nameForm="รับคืน(ซ่อม)";
        this.lblcustomername ="ผู้ขาย";
        this.lbloutput="ขณะซ่อม"; 
        this.lblinput="หลังซ่อมเสร็จ";

        this.lblempname ="ผู้รับผิดชอบ";
        this.lblpartdept  ="แผนก";
        this.lblpartlocation  ="ที่ตั้ง";
      
        this.lblempreceivename ="ผู้รับผิดชอบ";
        this.lbltopartdept  ="ไปแผนก";
        this.lbltopartlocation  ="ไปที่ตั้ง";

        this.lbltxprice  ="ค่าซ่อม";

        // this.getSupplier(GC.ps_sup);
      }
      else if (this.nameFormOrigin==="doc_buy"){
        this.nameForm="ขายออก"; 
        this.lblcustomername ="ลูกค้า";
        // this.getCustomer(GC.ps_cus);
      }
      else if (this.nameFormOrigin==="doc_return"){
        this.nameForm="คืนทรัพย์สิน"; 
        this.lbloutput="คืนจาก"; 
        this.lblinput="รับคืน";
      }

      this.lbldocno=this.lbldocno+ this.nameForm;


      this.getMaster(-1,this.m_mr); 
      this.getPersons(-1,this.m_ps);
    
      // this.getEmployee(GC.ps_emp);

     


      if (this.eventsFlag==="A")
      {
        this.nameForm2 ="เพิ่มข้อมูล " + this.nameForm;
      }else if (this.eventsFlag==="E")
      {
        this.nameForm2 ="แก้ไขข้อมูล " + this.nameForm;
      }
      
      this.mr_master=globalfunction.getMaster(-1,this.m_mr);
     
      
      this.queryData();
      

   }

  




   public openPDF(): void {
    let DATA: any = document.getElementById('htmlData');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('angular-demo.pdf');
    });
  }

   private getMaster(id? : number,mr? : c_masterRef) {
    mr.iscompany=true;//เปิดให้เข้าทุกบริษัท
    mr.companyid=GC.companyid;
    this.masterrefService.getDataList(id,mr).subscribe(data => {
      this.mr_master=new Array();//ไว้สำหรับนำมาแสดง
      this.mr_master=data;

      this.getDepartment(GC.mr_dept); 
      this.getLocation(GC.mr_location); 
      
    });
  }

   private getDepartment(id? : number) {
    this.mr_dept.splice(0,this.mr_dept.length);//clear all
    this.mr_master.forEach( (element : any) =>  {
      
      if (element.masterarea==id){
          this.mr_dept.push(element);
      }
    });
  }

  private getLocation(id? : number) {
    this.mr_location.splice(0,this.mr_location.length);//clear all
    this.mr_master.forEach( (element : any) =>  {
      
      if (element.masterarea==id){
          this.mr_location.push(element);
      }
    });
  }

  private getPartItem(id? : number,mr? : c_partitems) {
    mr.companyid=this.m_data.companyid;

    this.partitemService.getDataList(id,mr).subscribe(data => {
      this.pi =new Array();//ไว้สำหรับนำมาแสดง
      this.pi=data;

    });
  }

  


  private getPersons(id? : number,ps? : c_persons) {
    ps.iscompany=true;//เปิดให้เข้าทุกบริษัท
    ps.companyid=GC.companyid;
    this.personsService.getDataList(id,ps).subscribe(data => {
      this.mr_persons=data;
      // alert(this.mr_persons);
       this.getSupplier(GC.ps_sup);
       this.getCustomer(GC.ps_cus);
       this.getEmployee(GC.ps_emp);
    });
  }

  private getEmployee(id? : number) {
    this.ps_employee.splice(0,this.ps_employee.length);//clear all
    this.mr_persons.forEach( (element : any) =>  {
      
      if ((element.persontype==id) && (element.companyid==GC.companyid)){
          this.ps_employee.push(element);
      }
    });
  }

  private getCustomer(id? : number) {
    this.ps_customer.splice(0,this.ps_customer.length);//clear all
    this.mr_persons.forEach( (element : any) =>  {
   
      if (element.persontype==id){
          this.ps_customer.push(element);
      }
    });
  }

  private getSupplier(id? : number) {
    this.ps_supplier.splice(0,this.ps_supplier.length);//clear all
    this.mr_persons.forEach( (element : any) =>  {
      
      if (element.persontype==id){
          this.ps_supplier.push(element);
      }
    });
  }



  public searchPartItemsSub(id: number,ignoreid : number=0){
    
    this.m_pi_sub.partid=id;
    this.m_pi_sub.ignoreid=ignoreid;

    // alert(this.doctype);
    if(this.doctype==2 || this.doctype==5)
    {
      this.m_pi_sub.useflagset="('Y')"; //เอาที่ใช้งาน
    }else if(this.doctype==3  || this.doctype==6)
    {
      this.m_pi_sub.useflagset="('Y','N')"; //เอาที่ใช้งาน และ ว่าง
    }else if(this.doctype==4 )
    {
      this.m_pi_sub.useflagset="('R')";//เอาเฉพาะที่ส่งซ่อม
    }else{
      this.m_pi_sub.useflagset="('N')"; 
    }
    
    this.getPartItemSub(GC.reg_asset,this.m_pi_sub);
  }

  public searchLastBilling(id: number){
    
    if(this.doctype==2 || this.doctype==3 || this.doctype==4 || this.doctype==5 || this.doctype==6)
    {
      this.m_datas.id=-1;
      this.m_datas.partsubid=id;
      this.m_datas.documenttypeset="(" + GC.doc_receive + "," + GC.doc_transfer + "," + GC.doc_repair + ","
      + GC.doc_receive_repair + "," + GC.doc_return + "," + GC.doc_buy +")";
    

      this.dataServiceSub.getDataByMyQuery(3,this.m_datas).subscribe(data => {
        this.m_data_biling_items = data;
       
        // กรณีแก้ไข แสดงว่ามี partid แล้ว ก็ให้ไป load partsub ด้วย
        if( this.m_data_biling_items.personid>0)
        { 
          this.m_datas.personid=this.m_data_biling_items.personid;
          this.m_datas.deptid=this.m_data_biling_items.deptid;
          this.m_datas.locationid=this.m_data_biling_items.locationid;
          this.m_datas.billingdocref=this.m_data_biling_items.billingdocref
          
        }
        }, error => console.log(error));
        }

  }

  private getPartItemSub(id? : number,mr? : c_partitems_sub) {
    this.partitemSubService.getDataList(id,mr).subscribe(data => {
      this.pi_sub =new Array();//ไว้สำหรับนำมาแสดง
      this.pi_sub=data;
      
    });
  }



   private queryData(){
      let id: number=this.idmaster;
      let ind : number=0;

      if (this.eventsFlag=='E' || this.eventsFlag=='D')
       {
          //เรียกแม่
          this.m_data=new c_billingdoc();
          this.m_data.companyid=GC.companyid;
          this.m_data.id=id;
          this.dataService.getDataList(1,this.m_data).subscribe(data => {
            this.mum_data=data;

            this.m_data=new c_billingdoc();
            this.mum_data.forEach( (element : any) =>  {
              this.m_data=element;
            
            });

          });


          //เรียกลูก
          this.m_datas.id=-1;
          this.m_datas.billingid=id;
          if(this.doctype==2 || this.doctype==5)
          {
            ind=2;
          }else{
            ind=1;
          }
          this.dataServiceSub.getDataList(ind,this.m_datas).subscribe(data => {
          this.s_data=data;
          });
     }else
     {
      this.addManual(true);
      this.m_data.documentdate=GC.getDateEnNow("YYYY-MM-DD");
     }
  }

  addManual(isFlag : any)
  {
      if (isFlag)
      {
        this.m_data.documentno="***AUTO***";
      }else{
        this.m_data.documentno="";
      }
  }

  addData(){
    if (this.idmaster>0)
    {
      this.m_pi = new c_partitems();
      if(this.doctype==1){
        this.m_pi.useflagset="('N')";//ไม่เอาทรัพย์สินที่ไม่ว่างแล้ว
      }else if(this.doctype==2 || this.doctype==3 || this.doctype==6){
        this.m_pi.useflagset="('Y')";//เอาทรัพย์สินที่ใช้งานอย่างเดียว
      }else if(this.doctype==4 ){
        this.m_pi.useflagset="('R')";//เอาทรัพย์สินที่ส่งซ่อม
      }
      // else if(this.doctype==6 ){
      //   this.m_pi.useflagset="('N','Y')";//เอาทรัพย์สินที่ว่าง และใช้งาน
      // }
      
      this.getPartItem(2,this.m_pi);

      this.m_datas = new c_billingdoc_items();
      this.showModal = true; // Show-Hide Modal Check
      this.eventsFlag="A"; 
      this.eventsText ="เพิ่มรายการ " + this.nameForm;
  
      this.pi_sub =new Array();

    }else{
      alert("กรุณาเพิ่มข้อมูลหลักก่อน")
    }

  }

   updateData(id: number,partsubid : number){
    let ind : number=0;

    this.m_pi = new c_partitems();
    if(this.doctype==1){
      this.m_pi.useflagset="('N')";//ไม่เอาทรัพย์สินที่ไม่ว่างแล้ว
    }else if(this.doctype==2 || this.doctype==3 || this.doctype==6){
      this.m_pi.useflagset="('Y')";//เอาทรัพย์สินที่ใช้งานอย่างเดียว
    }else if(this.doctype==4 ){
      this.m_pi.useflagset="('R')";//เอาทรัพย์สินที่ส่งซ่อม
    }

    this.m_pi.ignoreid=partsubid;
    this.getPartItem(2,this.m_pi);

     this.id=id;//เก็บ id ของตัวที่กำลังกระทำ เพื่อนำไปบันทึก
     this.m_datas=new c_billingdoc_items();
     this.m_datas.id=id;
     this.m_datas.billingid=-1;
     
     if(this.doctype==2 || this.doctype==5)
     {
        ind=2;
     }else{
        ind=1;
     }

     this.dataServiceSub.getDataByMyQuery(ind,this.m_datas).subscribe(data => {
     this.m_datas = data;

      // กรณีแก้ไข แสดงว่ามี partid แล้ว ก็ให้ไป load partsub ด้วย
      if( this.m_datas.partid>0)
      { 
        this.searchPartItemsSub(this.m_datas.partid,this.m_datas.partsubid);
      }
      }, error => console.log(error));
     
      this.showModal = true; // Show-Hide Modal Check
      this.eventsFlag="E";
      this.eventsText ="แก้ไขรายการ " + this.nameForm;
     
     
   }
 
   deleteData(id: number,id2?: number ){
    let isConfirm : Boolean | any ;
    isConfirm=confirm("คุณแน่ใจหรือไม่ที่จะลบข้อมูล : "+ id + id2)
    if(isConfirm===false) {
      return;
    }

    this.eventsFlag="D";
    this.dataServiceSub.deleteData(id,id2).subscribe( data => {
      console.log(data);
      setTimeout(() => 
      {
        this.queryData();
      },1000);
  
    })
     
    
  }

  
 //for Submit form when action
   onSubmitMaster(AddEditMode : string){
     if (AddEditMode=="A") {
      // GC.companyid=GC.companyid;
      if (GC.companyid> 0)
      {
        if (this.nameFormOrigin==="doc_receive"){this.m_data.configdoctype= GC.cf_receive;}
        else if (this.nameFormOrigin==="doc_transfer"){this.m_data.configdoctype= GC.cf_transfer;}
        else if (this.nameFormOrigin==="doc_repair"){this.m_data.configdoctype= GC.cf_repair;}
        else if (this.nameFormOrigin==="doc_receive_repair"){this.m_data.configdoctype= GC.cf_receive_repair;}
        else if (this.nameFormOrigin==="doc_buy"){this.m_data.configdoctype= GC.cf_buy;}
        else if (this.nameFormOrigin==="doc_return"){this.m_data.configdoctype= GC.cf_return;}

        this.m_data.companyid=GC.companyid;
        this.m_data.documenttype=this.doctype;
        this.m_data.statusflag="N";
        
        this.dataService.createData(this.m_data).subscribe( data =>{ console.log(data);},
        error => console.log(error));
      }else
      {
        alert("กรุณาระบุ บริษัท")
      }

     }else if (AddEditMode=="E"){
       this.dataService.updateData(this.idmaster, this.m_data).subscribe( data =>{
         console.log(data);
       }
       , error => console.log(error));
     }

     
     
     if (this.isSaveAndExit)
     { 
      this.isSaveAndExit=false;
       this.close();
     }else if (this.isSave)
     {
        this.isSave=false;
        if (AddEditMode=="A") {
          this.close();
        }else{
          setTimeout(() => 
          {
          this.queryData();
          },500);
        }
     }
   }

  //  alert(this.isSaveAndExit +","+ this.isSave);

   onSubmitItems(AddEditMode : string){

    if (this.m_datas.partid <= 0){alert("กรุณาระบุ " + this.lblpartname);return;}
    if (this.m_datas.partsubid <= 0){alert("กรุณาระบุ " + this.lblpartsub);return;}
    if (this.m_datas.personid <= 0){alert("กรุณาระบุ " + this.lblempname);return;}
    if (this.m_datas.deptid <= 0){alert("กรุณาระบุ " + this.lblpartdept);return;}
    if (this.m_datas.locationid <= 0){alert("กรุณาระบุ " + this.lblpartlocation);return;}

    if (this.doctype==2)
    {
     if (this.m_datas.topersonid <= 0){alert("กรุณาระบุ " + this.lblempreceivename);return;}
     if (this.m_datas.todeptid <= 0){alert("กรุณาระบุ " + this.lbltopartdept);return;}
     if (this.m_datas.tolocationid <= 0){alert("กรุณาระบุ " + this.lbltopartlocation);return;}
    }
    if (AddEditMode=="A") {
     if (this.idmaster> 0)
     {

       this.m_datas.billingid=this.idmaster;
       this.m_datas.txamount=1;//ให้ใส่จำนวนเป็น 1 ชิ้นเสมอ เพราะ 1 ทรัพย์สิน มีแค่ ตัวเดียวเท่านั้น
       this.m_datas.doctype=this.doctype;

       if (this.doctype==GC.doc_receive || this.doctype==GC.doc_receive_repair  ){this.m_datas.txtype="I";}
       else if (this.doctype==GC.doc_buy || this.doctype==GC.doc_transfer || this.doctype==GC.doc_repair || this.doctype==GC.doc_return ){this.m_datas.txtype="E";}
       else{alert("กรุณาระบุ txtype : " + this.doctype)}
    
       if (this.doctype==GC.doc_receive)//ถ้าเป็นการรับ ให้ นำ id แม่ของตัวเอง มาไว้ที่ ลูกด้วย
       {
         this.m_datas.billingdocref=this.idmaster;
        // alert(this.m_datas.billingdocref);
       }

       this.dataServiceSub.createData(this.m_datas).subscribe( data =>{ console.log(data);},
       error => console.log(error));
     }else
     {
       alert("กรุณาระบุ refresh")
     }

    }else if (AddEditMode=="E"){
      // alert(this.id);
      this.m_datas.doctype=this.doctype;
      // alert(this.m_datas.billingdocref);
      this.dataServiceSub.updateData(this.id, this.m_datas).subscribe( data =>{
        console.log(data);
      }
      , error => console.log(error));
    }

    setTimeout(() => 
    {
     this.queryData();
    },1000);

    if (this.isSaveAndExit)
     {
       this.isSaveAndExit=false;
       
       this.hide();
       this.refresh();
       
     }
  }



  close ()
  {
    setTimeout(() => 
    {
      this.router.navigate(['/frm-billingdoc-component',this.doctype,this.nameFormOrigin,this.eventsFlag]);
    },1000);
  }
 
 //for popup form
   showModal: boolean | any;
   registerForm: UntypedFormGroup | any;
   submitted = false;
 
  show()
    { 
      this.showModal = true;
    }

   hide()
   {
      this.showModal = false;
      document.getElementById("myDropdown").classList.toggle("show");
   }

   verifyUser(Us : boolean){
     GC.role1=Us;
     this.role1=GC.role1;
   }
   
   refresh(): void {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
    }
    
    eventBotton (id? : number) : void {
      if (id===1)
      {
        this.isSave=true;
      }else if (id===2)
      {
        this.isSaveAndExit=true;
      }
    }

    onFileChange(evt: any) {
      /* wire up file reader */
      const target: DataTransfer = <DataTransfer>evt.target;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
  
        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
  
        /* save data */
        this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });

        let i : number=0;
        // let j : number=0;
     
        this.data.forEach( async (element : any) =>  {
          i++;
          
          if (i > 2  )
          {
            // insert แม่
            this.m_data2=new c_billingdoc();

            this.m_data2.companyid=GC.companyid;
            this.m_data2.statusflag="N";
            this.m_data2.documenttype=1;
            this.m_data2.inputmanual=false;
            this.m_data2.configdoctype=1;
            
            var dateSplitted = element[18].split("/"); 
            var strDate = (Number(dateSplitted[2]) ) + "-" + dateSplitted[1] + "-" +dateSplitted[0];

            this.m_data2.documentdate=GC.getDateFormatEnToEn(strDate,"YYYY-MM-DD");


            // insert ลูก
            this.m_data2.inputauto=true;
            this.m_data2.partid = element[32];
            this.m_data2.partsubid = element[24];

            // alert(strDate);
            this.dataService.createData(this.m_data2).subscribe( data =>{ console.log(data);},error => console.log(error));
          }
       
        });
      };
      reader.readAsBinaryString(target.files[0]);
    }


    async generateReportPDF() { 
      let docDefinition : any;
      let pageMaginTop : number=130;
      let width1 : number=60;
      let width2 : number=210;
      let leftRightMagin : number =20;
      let strColumn : any ;

      let note:string=GC.fillterText(this.m_data.documentdesc);
      
      let HeaderRows = [];
      if ((this.doctype==3) || (this.doctype==4) || (this.doctype==6))
      {
        HeaderRows.push([ 
          { text: 'เลขที่เอกสาร'  + '\n\ ผู้ขาย',bold: true, alignment: 'left',fontSize: 16 }, 
          { text: ': ' + GC.fillterText(this.m_data.documentno)  + '\n\ : ' + GC.fillterText(this.m_data.customername),bold: true, alignment: 'left',fontSize: 16 }, 
          { text: 'วันที่เอกสาร',bold: true, alignment: 'left',fontSize: 16 }, 
          { text: ': ' + GC.convertYearEnToYearThai(this.m_data.documentdate,'DD/MM/YYYY') ,bold: true, alignment: 'left',fontSize: 16 }, 
        ]);
     
      }else
      {
        HeaderRows.push([ 
          { text: 'เลขที่เอกสาร',bold: true, alignment: 'left',fontSize: 16 }, 
          { text: ': ' + GC.fillterText(this.m_data.documentno)  ,bold: true, alignment: 'left',fontSize: 16 }, 
          { text: 'วันที่เอกสาร',bold: true, alignment: 'left',fontSize: 16 }, 
          { text: ': ' + GC.convertYearEnToYearThai(this.m_data.documentdate,'DD/MM/YYYY') ,bold: true, alignment: 'left',fontSize: 16 }, 
        ]);
      }

      let rows = [];
      //Header column
      if ((this.doctype==2) )
      {
        rows.push([ { text: '#',bold: true, alignment: 'center' }, 
        { text: 'ชื่อทรัพย์สิน',bold: true, alignment: 'center' }, 
        { text: 'ทะเบียน', bold: true,alignment: 'center' },
        { text: 'ผู้รับผิดชอบ', bold: true,alignment: 'center' },
        { text: 'แผนก', bold: true,alignment: 'center' },
        { text: 'ที่ตั้ง', bold: true,alignment: 'center' },
        { text: 'ผู้รับโอน', bold: true,alignment: 'center' },
        { text: 'ไปแผนก', bold: true,alignment: 'center' },
        { text: 'ที่ตั้ง', bold: true,alignment: 'center' },
        { text: 'จำนวน', bold: true,alignment: 'center' },
      ]);
        // strColumn= [15, 90, 30,50,40,40,50,40,40,40,25];
        strColumn= [15, '*', 30,60,40,40,60,40,40,25];
      }else if ((this.doctype==4) || (this.doctype==6))
      {
        rows.push([ { text: '#',bold: true, alignment: 'center' }, 
        { text: 'ชื่อทรัพย์สิน',bold: true, alignment: 'center' }, 
        { text: 'ทะเบียน', bold: true,alignment: 'center' },
        { text: 'ผู้รับผิดชอบ', bold: true,alignment: 'center' },
        { text: 'แผนก', bold: true,alignment: 'center' },
        { text: 'ที่ตั้ง', bold: true,alignment: 'center' },
        { text: 'ราคา', bold: true,alignment: 'center' },
        { text: 'จำนวน', bold: true,alignment: 'center' },
        ]);
        strColumn= [15, '*', 30,70,50,50,40,30];

      }else
      {
        rows.push([ { text: '#',bold: true, alignment: 'center' }, 
        { text: 'ชื่อทรัพย์สิน',bold: true, alignment: 'center' }, 
        { text: 'ทะเบียน', bold: true,alignment: 'center' },
        { text: 'ผู้รับผิดชอบ', bold: true,alignment: 'center' },
        { text: 'แผนก', bold: true,alignment: 'center' },
        { text: 'ที่ตั้ง', bold: true,alignment: 'center' },
        { text: 'จำนวน', bold: true,alignment: 'center' },
      ]);
        // strColumn= [15, 190, 30,70,50,50,50,30];
        strColumn= [15, '*', 30,70,50,50,30];
      }

   
    
       //Detail column
       let i: number=0;
       this.s_data.forEach( (element : c_billingdoc_items) =>  {
        i++;
      if ((this.doctype==2))
      {
        rows.push([
          { text: i, alignment: 'right'}, 
          { text: element.partname, alignment: 'left'}, 
          { text: element.qrcode, alignment: 'center'}, 
          { text: element.personname, alignment: 'left'}, 
          { text: element.deptname, alignment: 'left'}, 
          { text: element.locationname, alignment: 'left'}, 
          { text: element.personreceivename, alignment: 'left'}, 
          { text: element.todeptname, alignment: 'left'}, 
          { text: element.tolocationname, alignment: 'left'}, 
          { text: element.txamount, alignment: 'right'}, 
          ]);

      }else if ((this.doctype==4) || (this.doctype==6))
      {
        rows.push([
          { text: i, alignment: 'right'}, 
          { text: element.partname, alignment: 'left'}, 
          { text: element.qrcode, alignment: 'center'}, 
          { text: element.personname, alignment: 'left'}, 
          { text: element.deptname, alignment: 'left'}, 
          { text: element.locationname, alignment: 'left'}, 
          { text: element.txprice, alignment: 'right'}, 
          { text: element.txamount, alignment: 'right'}, 
          ]);

      }else{
        rows.push([
          { text: i, alignment: 'right'}, 
          { text: element.partname, alignment: 'left'}, 
          { text: element.qrcode, alignment: 'center'}, 
          { text: element.personname, alignment: 'left'}, 
          { text: element.deptname, alignment: 'left'}, 
          { text: element.locationname, alignment: 'left'}, 
          { text: element.txamount, alignment: 'right'}, 
          ]);
      }
        
       });

       let j: number=0;
       for (j=i;j<24; j++)
       {
        if ((this.doctype==2))
        {
          rows.push([
            { text: ' ', alignment: 'right'}, 
            { text: ' ', alignment: 'left'}, 
            { text: ' ', alignment: 'center'}, 
            { text: ' ', alignment: 'left'}, 
            { text: ' ', alignment: 'left'}, 
            { text: ' ', alignment: 'left'}, 
            { text: ' ', alignment: 'left'}, 
            { text: ' ', alignment: 'left'}, 
            { text: ' ', alignment: 'left'}, 
            { text: ' ', alignment: 'right'}, 
            ]);

        }else if ((this.doctype==4) || (this.doctype==6))
        {
          rows.push([
            { text: ' ', alignment: 'right'}, 
            { text: ' ', alignment: 'left'}, 
            { text: ' ', alignment: 'center'}, 
            { text: ' ', alignment: 'left'}, 
            { text: ' ', alignment: 'left'}, 
            { text: ' ', alignment: 'left'}, 
            { text: ' ', alignment: 'right'}, 
            { text: ' ', alignment: 'right'}, 
            ]);

        }else{
          rows.push([
          { text: ' ', alignment: 'right'}, 
          { text: ' ', alignment: 'left'}, 
          { text: ' ', alignment: 'center'}, 
          { text: ' ', alignment: 'left'}, 
          { text: ' ', alignment: 'left'}, 
          { text: ' ', alignment: 'left'}, 
          { text: ' ', alignment: 'right'}, 
          ]);
        }
      }

    
      pdfMake.fonts = {
        THSarabunNew: {
          normal: 'THSarabunNew.ttf',
          bold: 'THSarabunNew-Bold.ttf',
          italics: 'THSarabunNew-Italic.ttf',
          bolditalics: 'THSarabunNew-BoldItalic.ttf'
          },
        Roboto: {
          normal: 'Roboto-Regular.ttf',
          bold: 'Roboto-Medium.ttf',
          italics: 'Roboto-Italic.ttf',
          bolditalics: 'Roboto-MediumItalic.ttf'
        }
        };
    
         docDefinition = {
          pageSize: 'A4',
          pageMargins: [ leftRightMagin,pageMaginTop,leftRightMagin,180 ],
          // pageOrientation: 'landscape' ,
          header: {
            margin: 20,
            columns: [
                {
                  stack: [
                    { 
                      columns: [
                        {
                          text: GC.companyname,
                          bold: true,
                          width: '*',
                          fontSize: 25,
                          alignment: 'center',
                        }
                      ],
                    },
                    { 
                      columns: [
                        {
                          text: 'ใบรายงาน' + this.nameForm,
                          bold: true,
                          width: '*',
                          fontSize: 20,
                          alignment: 'center',
                        }
                      ],
                    },
                    { 
                      table: {
                          headerRows: 1,
                          widths: [70, '*', 70, '*'],//520 แนวตั้ง
                          margin: [leftRightMagin, 0, leftRightMagin, 0],
                    
                          body: HeaderRows
                      },
                       layout: 'noBorders',
                    },''
                  ],
                }
            ],    
          },
          footer: function (currentPage : any, pageCount : any) {
            return ([ 
              {
                columns: [ 
                    { 
                      text: 'หมายเหตุ : ' + note ,style :['comment'],margin: [leftRightMagin, 0, leftRightMagin, 0]
                    }
                ],
            },' ',
            {
              columns: [ 
                  { 
                    text: '...............................................................',style :['footer'],
                  },
                  { 
                    text: '...............................................................',style :['footer'],
                  }
              ],
          },
          {
              columns: [ 
                  { 
                    text: '(...............................................................)',style :['footer'],
                  },
                  { 
                    text: '(...............................................................)',style :['footer'],
                  }
              ],
          },
          {
            columns: [ 
                { 
                  text: 'วันที่........./........./.............',style :['footer'],
                },
                { 
                  text: 'วันที่........./........./.............' ,style :['footer'],
              }
            ],
        },
          {
            columns: [ 
                { 
                  text: 'ผู้รับผิดชอบ' ,style :['footer'],
                },
                { 
                  text: 'ผู้อนุมัติ' ,style :['footer'],
              }
            ],
        },' ',
          { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'center' }
          ]);
          },

          content: [
            { 
              table: {
                headerRows: 1,
                    // widths: [20, 50, 225, 290, 80, 80],//745 แนวนอน
                    widths: strColumn,//520 แนวตั้ง
                    height : '*',
                    body: rows
              },
            },
            
      
          ],
          styles: {
            MasterDesc: {
              fontSize: 14,
              bold: true,
              width: 50,
              alignment: 'left'
            },
            MasterDesc2: {
              fontSize: 14,
              bold: false,
              width: '*',
              alignment: 'left'
            },
            notesText: {
              fontSize: 10,
            },
            footer: {
              bold: false,
              width: '*',
              fontSize: 16,
              alignment: 'center'
            },
            comment: {
              bold: false,
              width: '100',
              fontSize: 16,
              alignment: 'left',
              left: 20
            },
            comment2: {
              bold: false,
              width: '*',
              fontSize: 16,
              alignment: 'left',
              left: 20
            },
          },
          defaultStyle: {
            columnGap: 14,
            font: 'THSarabunNew'
          },
        };
         pdfMake.createPdf(docDefinition).open();
       } 

      onChange(event : any) {
        this.m_data.suppliername=event.target.options[event.target.options.selectedIndex].text;
       }

      myFunction(datas : any,ind : String,nameId? :string) {
      
        this.getOffset(nameId);

        document.getElementById("myDropdown").classList.toggle("show");
        
        this.indSeatch=ind;
        let data :c_datasearch=new c_datasearch();
        this.dataSearch =new Array();
        
        if (ind==='pi')
        {
          datas.forEach( (element : any) =>  {
            data =new c_datasearch();
            data.id=element.id;
            data.code=element.partcode;
            data.name=element.partname;
            this.dataSearch.push(data);
          });
        }else if((ind==='person') || (ind==='toemp'))
        {
          datas.forEach( (element : any) =>  {
            data =new c_datasearch();
            data.id=element.id;
            data.code=element.personcode;
            data.name=element.personname;
            this.dataSearch.push(data);
          });
        }else if((ind==='dept') || (ind==='location') || (ind==='todept') || (ind==='tolocation'))
        {
          datas.forEach( (element : any) =>  {
            data =new c_datasearch();
            data.id=element.id;
            data.code=element.keycode;
            data.name=element.keyname;
            this.dataSearch.push(data);
          });
        }
  
      }

      getOffset(nameId : string) {
        const element3 = document.getElementById("mainDiv");

        const element = document.getElementById(nameId);
        let pos1 = element.offsetTop + 118;
        let pos2 = element3.offsetLeft;
       

        const element2 = document.getElementById("myDropdown");
        element2.style.position = 'absolute';
        element2.style.top = pos1 + 'px';
        element2.style.left = pos2 + 'px';
      }

      dataSelect(id : any){
        if (this.indSeatch==='pi')
        {
          this.m_datas.partid=id;
          this.searchPartItemsSub(id);
        }else if(this.indSeatch==='person')
        {
          this.m_datas.personid=id;
        }else if(this.indSeatch==='toemp')
        {
          this.m_datas.topersonid=id;
        }else if(this.indSeatch==='dept')
        {
          this.m_datas.deptid=id;
        }else if(this.indSeatch==='todept')
        {
          this.m_datas.todeptid=id;
        }else if(this.indSeatch==='location')
        {
          this.m_datas.locationid=id;
        }else if(this.indSeatch==='tolocation')
        {
          this.m_datas.tolocationid=id;
        }

        document.getElementById("myDropdown").classList.toggle("show");
     }

      
}
