<div class="container">
    <div class="row">
       <div class="search-hero">
            <input  id="myInput" class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="&#61442;  ssssss ">
            </div>
            <table class="table table-striped">
              <thead>
              <tr>
                <th>ชื่อ</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let hero of data | filter:searchText">
                <td (click)="dataSelect(hero.id)">{{hero.name}}</td>
              </tr>
              </tbody>
         </table>
    </div>
  </div>
