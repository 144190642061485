<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>
<body>
<br><br><br><br>
<div class="col-md-12">
  <div class="card card-container">
    <img id="profile-img" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" class="profile-img-card"
    />
    <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate >
      <div class="row leftRight">
        <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblUsername}}</label> </div>
            <div class="col-md-8 mb-8"> 
            <div class="input-group">
              <input type="text" class="form-control" name="username" [(ngModel)]="form.username" required #username="ngModel"[ngClass]="{ 'is-invalid': f.submitted && username.errors }"/>
              <div *ngIf="username.errors && f.submitted" class="invalid-feedback">
                Username is required!
              </div>
            </div>
            </div>
       </div>


      <div class="row leftRight">
        <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblPassword}}</label> </div>
            <div class="col-md-8 mb-8"> 
            <div class="input-group">
              <input  type="password"  class="form-control" name="password" [(ngModel)]="form.password" required minlength="6" #password="ngModel" [ngClass]="{ 'is-invalid': f.submitted && password.errors }"/>
              <div *ngIf="password.errors && f.submitted" class="invalid-feedback">
                <div *ngIf="password.errors['required']">Password is required</div>
                <div *ngIf="password.errors['minlength']">Password must be at least 6 characters</div>
              </div>
            </div>
            </div>
       </div>

      <div class="row leftRight">
        <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblCompany}}</label> </div>
            <div class="col-md-8 mb-8"> 
            <div class="input-group">
                <select id="companyid" name="companyid" [(ngModel)]="form.companyid"   class="form-control" required #companyid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && companyid.errors }" (change)="onChange($event)">
                    <option *ngFor="let ms of ps_company" [value]="ms.id">{{ms.personname}} </option>
                </select> 
                <div *ngIf="companyid.errors && f.submitted" class="invalid-feedback">
                  ต้องระบุบริษัทเท่านั้น!
                </div>
            </div>
            </div>
       </div>
      <div class="form-group text-center">
        <br>
        <button class="btn btn-primary btn-block "> ตกลง</button>
      </div>

      <!-- <a class="dropdown-item" [routerLink]="['/frm-user-component']" routerLinkActive="router-link" >บัญชีผู้ใช้งาน</a> -->

      <div class="form-group">
        <div *ngIf="f.submitted && isLoginFailed" class="alert alert-danger" role="alert"> Login failed: {{ errorMessage }} </div>
      </div>
    </form>
    <div class="alert alert-success" *ngIf="isLoggedIn">
      Logged in as {{ roles }}.
    </div>

    <!-- <div class="text-center">
      <a class="nav-link" [routerLink]="['/register']" routerLinkActive="router-link" href="#">Sign Up</a>
    </div> -->
  </div>
</div>
</body>