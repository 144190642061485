import { Component, OnInit } from '@angular/core';
import { c_billingdoc } from '../../app.class/c_billingdoc';
import { s_billingdoc } from '../../app.service/s_billingdoc.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GC } from '../../common/global-constants';
import { globalfunction } from '../../common/globalfunction';
import { s_masterRef } from 'src/app/app.service/s_masterRef.service';
import { s_persons } from 'src/app/app.service/s_persons.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { c_partitems_sub } from 'src/app/app.class/c_partitems_sub';
import { s_partitems_sub } from 'src/app/app.service/s_partItems_sub.service';
import { c_datasearch } from 'src/app/app.class/c_datasearch';
import { c_partitems } from 'src/app/app.class/c_partitems';
import { s_partitems } from 'src/app/app.service/s_partItems.service';
import { c_masterRef } from 'src/app/app.class/c_masterref';

@Component({
  selector: 'app-frm-billingdoc',
  templateUrl: './frm-billingdoc.component.html',
  styleUrls: ['./frm-billingdoc.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})



export class FrmBillingdocComponent implements OnInit {
  s_data: c_billingdoc[]=new Array();//ไว้สำหรับนำมาแสดง
  m_data: c_billingdoc = new c_billingdoc();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  m_datas: c_billingdoc = new c_billingdoc();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข

  // m_pi_sub: c_partitems_sub = new c_partitems_sub();
  m_pi: c_partitems = new c_partitems();
  pi: c_partitems[]=new Array();
  
  mr_master :c_masterRef[]=new Array();
  m_mr: c_masterRef = new c_masterRef();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  mr_itemGroup: c_masterRef[]=new Array();//ไว้สำหรับนำมาแสดง

  dataSearch: c_datasearch[]=new Array();
  indSeatch : String="";
  searchText: any;
  showModal: boolean | any;

  id: number=0 ;
  isLoggedIn: boolean=true;
  eventsFlag : string ="A";
  eventsText : string="";
  role1 : boolean= false;

  httpPart : string ="";


  itemCode : string="";
  itemName : string="";
  itemDesc : string="";
  itemStatus : string="";
  doctype : number=0;
  nameFormOrigin : string="";
  nameForm : string="";

  lblid : string="Id";
  lbldocno : string="เลขที่";
  lbldocdate : string="วันที่เอกสาร";
  lblempname : string="ผู้รับผิดชอบ";
  lblempreceivename : string="ผู้รับโอน";
  lbldocdesc : string="รายละเอียด";
  lblstatus : string ="สถานะ";
  lblcompany : string ="บริษัท";
  lblpartsub : string ="ทะเบียนทรัพย์สิน";
  lblgroup : string="หมวด";


  now = new Date();


  status = [
    { id: "Y", name: "ใช้งาน" },
    { id: "N", name: "ยกเลิก" }
  ];

  myDate : string;

  constructor(private dataService: s_billingdoc,private router: Router,private route: ActivatedRoute,private tokenStorageService: TokenStorageService
    ,private cookieService: CookieService,private partitemService: s_partitems,private masterrefService: s_masterRef) {}

   ngOnInit(): void {
      this.role1 = !!this.tokenStorageService.getToken();
      this.httpPart=GC.httpPath;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;

      GC.companyid =Number(this.cookieService.get('companyid'));

      this.doctype = this.route.snapshot.params['id'];
      this.nameFormOrigin = this.route.snapshot.params['name'];
      
      this.m_data.companyid=GC.companyid;
      
      if (this.nameFormOrigin==="doc_receive"){this.nameForm="รับเข้า";}
      else if (this.nameFormOrigin==="doc_transfer"){this.nameForm="โอนย้าย";}
      else if (this.nameFormOrigin==="doc_repair"){this.nameForm="ส่งซ่อม";}
      else if (this.nameFormOrigin==="doc_receive_repair"){this.nameForm="รับคืน(ซ่อม)";}
      else if (this.nameFormOrigin==="doc_buy"){this.nameForm="ขายออก";}
      else if (this.nameFormOrigin==="doc_return"){this.nameForm="คืนทรัพย์สิน";}
      
      
      this.lbldocno=this.lbldocno+ this.nameForm;

      // this.getPartItem(GC.reg_asset,this.pi);
      this.getPartItem(2,this.m_pi);
      this.getMaster(-1,this.m_mr); 
  
      this.queryData();

      this.showModal = false;
      // document.getElementById("myDropdown").classList.remove("show");
      
   }

   queryData(){
    if (this.m_datas.statusflag.length <= 0){this.m_datas.statusflag="Y";}
    
    this.m_datas.companyid=GC.companyid;
    this.m_datas.documenttype=this.doctype;
   

     this.getData(this.doctype,this.m_datas);
   }

   private getData(id? : number,mr? : c_billingdoc){
    this.dataService.getDataList(1,mr).subscribe(data => {
        this.s_data=data;
    });
  }

  addData(){
    this.m_data = new c_billingdoc();
    this.eventsFlag="A"; 
    this.eventsText ="เพิ่มข้อมูล " + this.nameForm;
    this.router.navigate(['/frm-billingdoc-items-component',-1,0,this.nameFormOrigin,this.eventsFlag,this.doctype]);
  }

   updateData(id: number){
     this.id=id;
    this.eventsFlag="E";
    this.eventsText ="แก้ไขข้อมูล " + this.nameForm;
    this.router.navigate(['/frm-billingdoc-items-component',-1,id,this.nameFormOrigin,this.eventsFlag,this.doctype]);
   }
 
   deleteData(id: number,blogId?: number,fileId1? : string,fileId2? : string ){
    let isConfirm : Boolean | any ;
    isConfirm=confirm("Are you sure to delete "+ id)
    if(isConfirm===false) {
      return;
    }
      this.dataService.deleteData(id).subscribe( data => {
        console.log(data);
        this.queryData();
    
      })
      this.eventsFlag="D";
    
  }

  clearForm() {
    this.m_datas=new c_billingdoc();
  }

   verifyUser(Us : boolean){
     GC.role1=Us;
     this.role1=GC.role1;
   }

   private getMaster(id? : number,mr? : c_masterRef) {
    
    mr.companyid=GC.companyid;
    this.masterrefService.getDataList(id,mr).subscribe(data => {
      this.mr_master=new Array();//ไว้สำหรับนำมาแสดง
      this.mr_master=data;

      this.getPartGroup(GC.mr_itemGroup); 
      
    });
  }

   private getPartGroup(id? : number,mr? : c_masterRef) {
    this.mr_itemGroup.splice(0,this.mr_itemGroup.length);//clear all
    this.mr_master.forEach( (element : any) =>  {
      
      if (element.masterarea==id){
          this.mr_itemGroup.push(element);
      }
    });
  }

  
  private getPartItem(id? : number,mr? : c_partitems) {
    mr.companyid=this.m_data.companyid;

    this.partitemService.getDataList(id,mr).subscribe(data => {
      this.pi =new Array();//ไว้สำหรับนำมาแสดง
      this.pi=data;

    });
  }


  myFunction(datas : any,ind : String,nameId? :string) {
    
    this.getOffset(nameId);

    if (this.showModal){this.showModal = false;}else{ this.showModal = true;}
    
    this.indSeatch=ind;
    let data :c_datasearch=new c_datasearch();
    this.dataSearch =new Array();
    
    if ((ind==='pi') || (ind==='toemp'))
    {
      datas.forEach( (element : any) =>  {
        data =new c_datasearch();
        data.id=element.id;
        data.code=element.partcode;
        data.name=element.partname;
        this.dataSearch.push(data);
      });
    }else if((ind==='person') || (ind==='toemp'))
    {
      datas.forEach( (element : any) =>  {
        data =new c_datasearch();
        data.id=element.id;
        data.code=element.personcode;
        data.name=element.personname;
        this.dataSearch.push(data);
      });
    }else if((ind==='dept') || (ind==='location') || (ind==='todept') || (ind==='tolocation'))
    {
      datas.forEach( (element : any) =>  {
        data =new c_datasearch();
        data.id=element.id;
        data.code=element.keycode;
        data.name=element.keyname;
        this.dataSearch.push(data);
      });
    }

  }

  getOffset(nameId : string) {
    const element3 = document.getElementById("mainDiv");

    const element = document.getElementById(nameId);
    let pos1 = element.offsetTop + 118;
    let pos2 = element3.offsetLeft;
   

    const element2 = document.getElementById("myDropdown");
    element2.style.position = 'absolute';
    element2.style.top = pos1 + 'px';
    element2.style.left = pos2 + 'px';
  }

  dataSelect(id : any){
    if (this.indSeatch==='pi')
    {
      this.m_datas.partid=id;
    }

    if (this.showModal){this.showModal = false;}else{ this.showModal = true;}
 }
}
