<div class="row">
  <div class="col-8">
      <label class="btn btn-default p-0">
          <input type="file" (change)="selectFile($event)"/>
      </label>
  </div>

  <div class="col-4">
      <button class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload()">
          Upload file
      </button>
  </div>
</div>

<div *ngIf="message" class="alert alert-success" role="alert">{{ message }}</div>
<div *ngIf="errorMsg" class="alert alert-danger" role="alert">{{ errorMsg }}</div>