export class c_user{
    id : number=0;
    email: string="";
    password: string="";
    username : string="";
    crcompany : number =0;
    companyid : number =0;
    usergroupid: number=0;

    createby : string ="";
    createdate :string="";
    modifyby: string="";
    modifydate :string="";

    companycode : string="";
    
}