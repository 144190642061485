import { Component, OnInit } from '@angular/core';
import { c_configdoc } from '../../app.class/c_configdoc';
import { s_configdoc } from '../../app.service/s_configdoc.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { GC } from '../../common/global-constants';
import { UploadFilesService } from 'src/app/app.service/upload-files.service';
import { Observable } from 'rxjs';
import { filedata } from 'src/app/app.class/filedata';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { Alert } from 'selenium-webdriver';

@Component({
  selector: 'app-frm-manage-doccode',
  templateUrl: './frm-manage-doccode.component.html',
  styleUrls: ['./frm-manage-doccode.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})
export class FrmManageDoccodeComponent implements OnInit {
  s_data: c_configdoc[]=new Array();//ไว้สำหรับนำมาแสดง
  c_data: c_configdoc[]=new Array();//ไว้สำหรับนำมาแสดง
  m_data: c_configdoc = new c_configdoc();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  m_datas: c_configdoc = new c_configdoc();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข

  mr_master :c_configdoc[]=new Array();
  mr_partgroup: c_configdoc[]=new Array();
  m_mr: c_configdoc = new c_configdoc();

  id: number=0 ;
  isLoggedIn: boolean=true;
  eventsFlag : string ="A";
  eventsText : string="";
  role1 : boolean= false;
  BlogId : number=0;

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  fileInfos: Observable<any> | undefined;
  t_fileInfos : filedata[] =new Array();
  file: filedata = new filedata();

  httpPart : string ="";


  itemCode : string="";
  itemName : string="";
  itemDesc : string="";
  master_area : number=0;
  nameForm : string="";

  lblId : string="Id";
  lblDoctype : string="ประเภทเอกสาร";
  lblPrefix : string="Prefix";
  lblDigit : string="หลัก";
  lblRunningNo : string="เลขที่สุดท้าย";
  lblLastDoc : string="เอกสารล่าสุด";
  lblCode1 : string="ตัวคั่น 1";
  lblCode2 : string="ตัวคั่น 2";
  lblCode3 : string="ตัวคั่น 3";
  lblYearType : string="ประเภทปี";
  lblMonthType : string="ประเภทเดือน";
  lblAutoBegin : string="เริ่มเป็น 0 เมื่อขึ้นเดือนใหม่";
  lblcompany : string="บริษัท";


  doctype = [
    { id: GC.cf_partno, name: GC.doc_asset_text },
    { id: GC.cf_itemsno, name: GC.doc_items_text },
    { id: GC.cf_receive, name: GC.doc_receive_text },
    { id: GC.cf_transfer, name: GC.doc_transfer_text },
    { id: GC.cf_repair, name: GC.doc_repair_text },
    { id: GC.cf_receive_repair, name: GC.doc_receive_repair_text },
    { id: GC.cf_buy, name: GC.doc_buy_text },
    { id: GC.cf_pr, name: GC.doc_pr_text },
    { id: GC.cf_po, name: GC.doc_po_text },
  ];

  yeartypes = [
    { id: 1, name: "พศ. 2 หลัก" },
    { id: 2, name: "พศ. 4 หลัก" },
    { id: 3, name: "คศ. 2 หลัก" },
    { id: 4, name: "คศ. 4 หลัก" },
  ];

  monthtypes = [
    { id: 1, name: "มี" }
  ];


  constructor(private dataService: s_configdoc,
    private router: Router,private formBuilder: UntypedFormBuilder,private route: ActivatedRoute,private uploadService: UploadFilesService,private tokenStorageService: TokenStorageService
    ,private masterrefService: s_configdoc) 
    {
      
    }

   ngOnInit(): void {
      this.role1 = !!this.tokenStorageService.getToken();
      this.httpPart=GC.httpPath;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.master_area = this.route.snapshot.params['id'];

      this.nameForm = "เลขที่เอกสาร";
  
      this.queryData();
   }

   queryData(){
   
    // this.m_datas=new c_configdoc();
    this.m_datas.companyid=GC.companyid;
    this.getData(1,this.m_datas);
   }

   private getData(id? : number,mr? : c_configdoc){
    this.dataService.getDataList(id,mr).subscribe(data => {
        this.s_data=data;
        
    });
  }

  addData(){
    this.m_data = new c_configdoc();
    this.showModal = true; // Show-Hide Modal Check
    this.eventsFlag="A"; 
    this.eventsText ="เพิ่มข้อมูล " + this.nameForm;
  }

   updateData(id: number){
    this.id=id;//ห้ามลบ เอาไว้ใช้ตอน update

    this.m_data=new c_configdoc();
    this.m_data.companyid=GC.companyid;
    this.m_data.id=id;
    this.dataService.getDataList(1,this.m_data).subscribe(data => {
      this.c_data=data;

      this.m_data=new c_configdoc();
      this.c_data.forEach( (element : any) =>  {
        this.m_data=element;
      
      });

    });



    this.showModal = true; // Show-Hide Modal Check
    this.eventsFlag="E";
    this.eventsText ="แก้ไขข้อมูล " + this.nameForm;
   }
 
   deleteData(id: number,blogId?: number,fileId1? : string,fileId2? : string ){
    let isConfirm : Boolean | any ;
    isConfirm=confirm("Are you sure to delete "+ id)
    if(isConfirm===false) {
      return;
    }
      this.dataService.deleteData(id).subscribe( data => {
        console.log(data);
        this.queryData();
    
      })
      this.eventsFlag="D";
    
  }

 //for Submit form when action
   onSubmit(AddEditMode : string){
    // alert(this.m_data.masterrefid);
     if (AddEditMode=="A") {
      this.m_data.companyid=GC.companyid;
      // alert(this.m_data.companyid);
       this.dataService.createData(this.m_data).subscribe( data =>{
         console.log(data);
       },
       error => console.log(error));
     }else if (AddEditMode=="E"){
       this.dataService.updateData(this.id, this.m_data).subscribe( data =>{
         console.log(data);
       }
       , error => console.log(error));
     }
    
      setTimeout(() => 
      {
        this.queryData();
      },500);
     
     this.hide();
   }
 
 //for popup form
   showModal: boolean | any;
   registerForm: UntypedFormGroup | any;
   submitted = false;
 
  show()
    { 
      this.showModal = true;
    }

   hide()
   {
     this.showModal = false;
   }

   verifyUser(Us : boolean){
     GC.role1=Us;
     this.role1=GC.role1;
   }

  clearForm() {
    this.m_datas=new c_configdoc();
  }

   
}

