import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { c_persons } from '../app.class/c_persons';
import { map } from 'rxjs/operators';
import { GC } from '../common/global-constants';
import { TokenStorageService } from '../_services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class s_persons {

  private baseURL = "";
  private baseVisitURL = GC.httpPath + "goods_count";
  private person : any;
  tP : String ="persons";

  constructor(private httpClient: HttpClient, private tokenStorage: TokenStorageService) { 
    this.baseURL = GC.httpPath + this.tP;
    GC.username = this.tokenStorage.getUser().username;
  }
  
  getDataList(id? :number,mt? : c_persons): Observable<c_persons[]>{
    this.person= this.httpClient.get(this.baseURL).pipe(map((response: any) => response.json()));
    
    // if (id > 0){
      // this.baseURL = GC.httpPath + this.tP +"/masterArea?masterarea="+id
      this.baseURL = GC.httpPath + this.tP +"/allkey?id="+ mt.id + "&personcode=" + mt.personcode + "&personname=" + mt.personname 
      + "&iscompany=" + mt.iscompany + "&persontype=" + id + "&companyid=" + mt.companyid;
    // }
    // alert(this.baseURL); //idkeycodekeyname?id=0&keycode=e&keyname=e
    return this.httpClient.get<c_persons[]>(`${this.baseURL}`);
  }

  createData(data: c_persons): Observable<Object>{
    data.createby=GC.username;
    data.modifyby=GC.username;

    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.post(`${this.baseURL}`, data);
  }

  getDataById(id: number): Observable<c_persons>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.get<c_persons>(`${this.baseURL}/${id}`);
  }

  updateData(id: number, data: c_persons): Observable<Object>{
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.put(`${this.baseURL}/${id}`, data);
  }

  updateVisits(id: number, data: c_persons): Observable<Object>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.put(`${this.baseVisitURL}/${id}`, data);
  }

  deleteData(id: number): Observable<Object>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }
}

