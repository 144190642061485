import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { c_billingdoc } from '../app.class/c_billingdoc';
import { map } from 'rxjs/operators';
import { GC } from '../common/global-constants';
import { TokenStorageService } from '../_services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class s_billingdoc {

  private baseURL = "";
  private baseVisitURL = GC.httpPath + "goods_count";
  private person : any;
  tP : String ="billingdoc";

  constructor(private httpClient: HttpClient, private tokenStorage: TokenStorageService) { 
    this.baseURL = GC.httpPath + this.tP;
    GC.username = this.tokenStorage.getUser().username;
  }
  
  getDataList(ind? :number ,mt? : c_billingdoc): Observable<c_billingdoc[]>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));
 
    this.baseURL = GC.httpPath + this.tP +"/allkey?ind=" + ind +"&id="+ mt.id + "&documentno=" + mt.documentno + "&documentdate=" + mt.documentdate + 
    "&statusflag=" + mt.statusflag + "&cusid=" + mt.cusid + "&companyid=" + mt.companyid + "&doccumenttype=" + mt.documenttype + "&partid=" + mt.partid + "&partgroupid=" + mt.partgroupid;
        // alert(this.baseURL);  
    return this.httpClient.get<c_billingdoc[]>(`${this.baseURL}`);
  }

  createData(data: c_billingdoc): Observable<Object>{
    data.createby=GC.username;
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
    //  alert(this.baseURL);
    return this.httpClient.post(`${this.baseURL}`, data);
  }

  getDataById(id: number): Observable<c_billingdoc>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.get<c_billingdoc>(`${this.baseURL}/${id}`);
  }

  updateData(id: number, data: c_billingdoc): Observable<Object>{
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
  //  alert(this.baseURL + "/" + id);
    return this.httpClient.put(`${this.baseURL}/${id}`, data);
  }

  updateVisits(id: number, data: c_billingdoc): Observable<Object>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.put(`${this.baseVisitURL}/${id}`, data);
  }

  deleteData(id: number): Observable<Object>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }
}

