<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>

<!-- [style.display]="role1 ? 'block' : 'none'" -->
<body class="ht" >
  <div #back></div>
  <div id="bottom"  >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
   </div>
 
   <div class="card2"><br><br>
      <span>
          <h1>{{nameForm}}</h1> 
      </span>
      <span class="example-spacer"></span>

    <div class="container">
        <div class="card2"><br>
            <div class="row leftRight">
                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblusername}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="username" name="username" [(ngModel)]="m_datas.username" class="form-control">           
                    </div>
                </div>

                <!-- <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblpassword}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="password" name="password" [(ngModel)]="m_datas.password" class="form-control">           
                    </div>
                </div> -->
                <div class="col-md-2 mb-2 fontCenter">
                    <button (click) = "queryData()" class = "btn btn-success text-center">ค้นหา</button>&nbsp;
                    <button (click) = "clearForm()" class = "btn btn-success text-center">เครียร์</button>
                </div>
            </div>
        </div>
        
    </div>
    <br>
        <button (click) = "addData()"  class = "btn btn-success text-center">เพิ่มข้อมูล</button>&nbsp;
        <button  (click)="generateReportPDF()" class="btn btn-success text-center">พิมพ์</button> 
    <div class="container-fluid ">
      <div class="card2">

      </div>
      <div class="card2" style="overflow-x:auto;">

        <table id="dtHorizontalExample" class="table table-hover table-bordered table-sm" cellspacing="0" width="100%" >
          <thead>
              <tr class="text-center">
                  <th scope="col" style="text-align: center;"> แก้ไข</th>
                  <th scope="col" style="text-align: center;"> {{lblusername}}</th>
                  <!-- <th scope="col" style="text-align: center;"> {{lblpassword}}</th> -->
                  <th scope="col" style="text-align: center;"> {{lblemail}}</th>
                  <th scope="col" style="text-align: center;"> {{lblcompany}}</th>
                  <th scope="col" style="text-align: center;"> ลบ </th>
              </tr>
          </thead>
          <tbody>
              <tr  *ngFor = "let item of s_data" >
                  <td scope="row" class="col-1 fontCenter">  <button (click) = "updateData(item.id)" class = "btn btn-info text-center buttonRow"> แก้ไข</button></td>
                  <td scope="row" class="col-2 fontLeft"> {{item.username}} </td>
                  <!-- <td scope="row" class="col-2 fontLeft"> {{item.password}} </td> -->
                  <td scope="row" class="col-2 fontLeft"> {{item.email}}</td>
                  <td scope="row" class="col-1 fontCenter"> {{item.companycode}} </td>
                  <td scope="row" class="col-1 fontCenter"><button (click) = "deleteData(item.id)" class = "btn btn-danger text-center buttonRow"> ลบ</button></td>
              </tr>
             
          </tbody>
      </table>
      
    </div>
    
    <br>
    </div>
   </div>

<!-- Creates the bootstrap modal where the image will appear -->
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <form name="form" (ngSubmit)="f.form.valid && onSubmit(eventsFlag)" #f="ngForm" novalidate >
    <div class="modal-dialog " id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable " role="document">
       <div class="modal-content">
          
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
            <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
          </div>

          
            <div class="modal-body">
                <span class="bg-light">Id : {{m_data.id}}</span>
                <div class="card2"><br>
                    <div class="row leftRight">
                        <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblusername}}</label> </div>
                         <div class="col-md-8 mb-8"> 
                            <div class="input-group">
                                <!-- <input  type="text" id="username" name="username" [(ngModel)]="m_data.username" class="form-control">            -->
                                <input type="text" class="form-control" name="username"  [(ngModel)]="m_data.username"  required  minlength="3" maxlength="20" #username="ngModel" />
                                <div class="alert-danger" *ngIf="f.submitted && username.invalid">
                                <div *ngIf="username.errors.required">Username is required</div>
                                <div *ngIf="username.errors.minlength">
                                    Username must be at least 3 characters
                                </div>
                                <div *ngIf="username.errors.maxlength">
                                    Username must be at most 20 characters
                                </div>
                                </div>
                            </div>
                         </div>
                    </div>

                    <div class="row leftRight">
                        <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblpassword}}</label> </div>
                         <div class="col-md-8 mb-8"> 
                            <div class="input-group">
                                <input  type="password" class="form-control"  name="password"  [(ngModel)]="m_data.password"  required  minlength="6"  #password="ngModel"  />
                                <div class="alert-danger" *ngIf="f.submitted && password.invalid">
                                  <div *ngIf="password.errors.required">Password is required</div>
                                  <div *ngIf="password.errors.minlength">
                                    Password must be at least 6 characters
                                  </div>
                                </div>       
                            </div>
                         </div>
                    </div>

                    <div class="row leftRight">
                        <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblemail}}</label> </div>
                         <div class="col-md-8 mb-8"> 
                            <div class="input-group">
                                <input  type="email"  class="form-control"  name="email" [(ngModel)]="m_data.email"  required  email #email="ngModel" />
                                <div class="alert-danger" *ngIf="f.submitted && email.invalid">
                                  <div *ngIf="email.errors.required">Email is required</div>
                                  <div *ngIf="email.errors.email">
                                    Email must be a valid email address
                                  </div>
                                </div>        
                            </div>
                         </div>
                    </div>

                    <div class="row leftRight">
                      <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblusergroup}}</label> </div>
                      <div class="col-md-8 mb-8"> 
                          <div class="input-group" id="usergroupid">
                              
                              <select id="usergroupid" name="usergroupid" [(ngModel)]="m_data.usergroupid" class="form-control" required #usergroupid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && usergroupid.errors }">
                                  <option *ngFor="let ms of ug_usergroup" [value]="ms.id">{{ms.groupname}}  </option>
                              </select> 
                              <!-- <button (click)="myFunction(mr_dept,'todept','todeptid')" type="button" class="btn btn-primary">...</button> -->
                              <div *ngIf="usergroupid.errors && f.submitted" class="invalid-feedback">
                                  <div *ngIf="usergroupid.errors['required']">กรุณาระบุ {{lblusergroup}}</div>
                              </div>
                          </div>
                      </div>
                    </div>
   

                    <br>
                </div>
            </div>

          <div class="modal-footer">
            <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
            <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
          </div>
       
        </div>
      </div>
    </div>

    <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">
        Signup failed!<br />{{ errorMessage }}
      </div>
   </form>

   <!-- <div class="alert alert-success" *ngIf="isSuccessful">
       Your registration is successful!
    </div> -->
  </div>
    <!-- end  Creates the bootstrap modal where the image will appear -->

</body>