import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GC } from '../../common/global-constants';

@Component({
  selector: 'app-frm-search',
  templateUrl: './frm-search.component.html',
  styleUrls: ['./frm-search.component.css']
})
export class FrmSearchComponent implements OnInit {
  searchText: any;
  // pi : any;

  public data: { [key: string]: Object; }[] = [
        { Name: 'Australia', Code: 'AU' },
        { Name: 'Bermuda', Code: 'BM' },
        { Name: 'Canada', Code: 'CA' },
        { Name: 'Cameroon', Code: 'CM' },
        { Name: 'Denmark', Code: 'DK' },
        { Name: 'France', Code: 'FR' },
        { Name: 'Finland', Code: 'FI' },
        { Name: 'Germany', Code: 'DE' },
        { Name: 'Greenland', Code: 'GL' },
        { Name: 'Hong Kong', Code: 'HK' },
        { Name: 'India', Code: 'IN' },
        { Name: 'Italy', Code: 'IT' },
        { Name: 'Japan', Code: 'JP' },
        { Name: 'Mexico', Code: 'MX' },
        { Name: 'Norway', Code: 'NO' },
        { Name: 'Poland', Code: 'PL' },
        { Name: 'Switzerland', Code: 'CH' },
        { Name: 'United Kingdom', Code: 'GB' },
        { Name: 'United States', Code: 'US' }
    ];
  static pi: any;

  constructor(private router: Router,private route: ActivatedRoute) {}

  ngOnInit(): void {
  
  }
  public static test()
  {
    this.pi=GC.datacenter;

  }
  

  dataSelect(id : any){
    // this.m_datas.partid=id;
    // this.searchPartItemsSub(id);
    // this.pi = GC.datacenter;
    // document.getElementById("myDropdown").classList.toggle("show");
 }

}
