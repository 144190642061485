import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { GC } from '../../common/global-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { c_persons } from 'src/app/app.class/c_persons';
import { s_persons } from 'src/app/app.service/s_persons.service';
import { CookieService } from 'ngx-cookie-service';
import { s_user } from 'src/app/app.service/s_user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;

  

  isLogin : string ="false";
  isLogOut : string="false";
  errorMessage = '';
  roles: string[] = [];
  username : string[]=[];

  ps_company: c_persons[]=new Array();//ไว้สำหรับนำมาแสดง
  m_ps: c_persons = new c_persons();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข

  lblUsername : string ="ชื่อผู้ใช้งาน";
  lblPassword : string ="รหัสผ่าน";
  lblCompany : string ="บริษัท";
  companyid : number=-1;
  companyname : string ="";

  mySelect = '1';
  selectedValue: any;

  @Input('psSelectText') selectedText: string;

  constructor(private dataService: s_user, private tokenStorage: TokenStorageService,private router: Router,private route: ActivatedRoute,
    private personsService: s_persons,private cookieService: CookieService) {
    this.getCompany(GC.ps_company,this.m_ps);
   }

  ngOnInit(): void {
    // this.cookieService.delete('isLoggedIn', '');

    this.isLogOut = this.route.snapshot.params['id'];
    if (this.isLogOut ==='true')
    {
      this.cookieService.delete('isLogin', '');
      this.cookieService.delete('companyid', '');
      this.cookieService.delete('companyname', '');
      this.isLoggedIn = false;
      this.tokenStorage.signOut();

       // window.location.reload();
      //  this.router.navigate(['/frm-main-page-component']);

      return;
    }



    this.isLogin =(this.cookieService.get('isLogin')).toString();
    if (this.isLogin==="true")
    {
      this.cookieService.delete('isLogin', '');
      this.cookieService.set( 'isLogin', "false"); 

      // this.router.navigate(['/frm-main-page-component']);
      // this.router.navigate(['/app-menubar']);
    }

    if (this.tokenStorage.getToken()) {
      
      this.isLoggedIn = false;
      this.roles = this.tokenStorage.getUser().roles;
    }
  }

  onSubmit(): void {

    this.dataService.login(this.form).subscribe(
      data => {
        this.tokenStorage.saveToken(data.accessToken);
        this.tokenStorage.saveUser(data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;


        this.roles = this.tokenStorage.getUser().roles;
        
        this.cookieService.delete('companyid', '');
        this.cookieService.set( 'companyid', this.form.companyid ); 


        GC.companyid =Number(this.cookieService.get('companyid'));
        GC.username = this.tokenStorage.getUser().username;

        this.cookieService.delete('isLogin', '');
        this.cookieService.set( 'isLogin', "true"); 

        // window.location.reload();
        this.router.navigate(['/frm-main-page-component']);
       
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }




  private getCompany(id? : number,ps? : c_persons) {
    ps.iscompany=true;
    this.personsService.getDataList(id,ps).subscribe(data => {
      this.ps_company=data;
    });
  }

  reloadPage(): void {
    window.location.reload();
  }

  onChange(event : any) {
    // this.m_data.partgroupname=event.target.options[event.target.options.selectedIndex].text;


    this.companyname=event.target.options[event.target.options.selectedIndex].text;
    this.cookieService.delete('companyname', '');
    this.cookieService.set( 'companyname', this.companyname ); 


   }

}
