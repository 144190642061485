<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>


<body class="ht">
  <div #back></div>
  
 <div  [style.display]="role1 ? 'block' : 'none'" >
    <div id="bottom"  >
        <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
       </div>
 
   <div class="card2"><br><br>
      <span>
          <h1>{{nameForm2}}</h1> 
      </span>
      <span class="example-spacer"></span>

    <div class="container">
        <span class="bg-light">Id : {{m_data.id}}</span>
        <div  class="card2"><br>
    <!-- <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate > -->
         <form   (ngSubmit)="f.form.valid && onSubmitMaster(eventsFlag)" #f="ngForm" novalidate>
            <div class="row leftRight">
                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbldocno}}</label> </div>
                <div class="col-md-3 mb-3"> 

                    <ng-container  *ngIf="m_data.inputmanual;else other4" >
                        <div class="input-group">
                            <input  type="text" id="documentno" name="documentno" [(ngModel)]="m_data.documentno" class="form-control"
                            required minlength="6" #documentno="ngModel" [ngClass]="{ 'is-invalid': f.submitted && documentno.errors }">  
                            <div *ngIf="documentno.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="documentno.errors['required']">กรุณาระบุ {{lbldocno}}</div>
                            </div>       
                        </div>
                    </ng-container>
                    <ng-template #other4>
                        <div class="input-group">
                            <input  type="text" id="documentno" name="documentno" [(ngModel)]="m_data.documentno" class="form-control" disabled="true"> 
                        </div>
                    </ng-template>
                   
                </div>
                
                <ng-container  *ngIf="eventsFlag =='A';else other5" >
                    <div class="col-md-1 mb-1"> <label><input type="checkbox" (click) = "addManual(m_data.inputmanual)" id="autobegingflag" name="autobegingflag" [(ngModel)]="m_data.inputmanual"> {{lblInputManual}}</label>   </div>
                </ng-container>
                <ng-template #other5>
                    <div class="col-md-1 mb-1"> <label><input type="checkbox" (click) = "addManual(m_data.inputmanual)" id="autobegingflag" name="autobegingflag" [(ngModel)]="m_data.inputmanual" disabled="true"> {{lblInputManual}}</label>   </div>
                </ng-template>
               

                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbldocdate}}</label> </div>
                <div class="col-md-4 mb-4"> 
                    <div class="input-group">
                        <input  type="date" id="documentdate" name="documentdate" [(ngModel)]="m_data.documentdate" class="form-control"
                        required minlength="0" #documentdate="ngModel" [ngClass]="{ 'is-invalid': f.submitted && documentdate.errors }">  
                        <div *ngIf="documentdate.errors && f.submitted" class="invalid-feedback">
                            <div *ngIf="documentdate.errors['required']">กรุณาระบุ {{lbldocdate}}</div>
                        </div>            
                    </div>
                </div>
            </div>

            <div class="row leftRight">
                <div class="col-md-2 mb-2"><label class="verticalLine-" for=""><div *ngIf="doctype == 3 || doctype == 4 || doctype == 6">{{lblcustomername}}</div></label> </div>
                <div class="col-md-4 mb-4"> 
                    <div *ngIf="doctype == 3 || doctype == 4" class="input-group">

                        <select id="personid" name="personid"  [(ngModel)]="m_data.cusid" class="form-control" required #personid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && personid.errors }" >
                            <option *ngFor="let ms of ps_supplier" [value]="ms.id">{{ms.personcode}} : {{ms.personname}}  </option>
                        </select> 
                        <div *ngIf="personid.errors && f.submitted" class="invalid-feedback">
                            <div *ngIf="documentdate.errors['required']">กรุณาระบุ {{lblcustomername}}</div>
                        </div>
                    </div>
                    <div *ngIf="doctype == 6" class="input-group">

                        <select id="personid" name="personid"  [(ngModel)]="m_data.cusid" class="form-control" required #personid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && personid.errors }" >
                            <option *ngFor="let ms of ps_customer" [value]="ms.id">{{ms.personcode}} : {{ms.personname}}  </option>
                        </select> 
                        <div *ngIf="personid.errors && f.submitted" class="invalid-feedback">
                            <div *ngIf="documentdate.errors['required']">กรุณาระบุ {{lblcustomername}}</div>
                        </div>

                    </div>
                </div>

                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbldocdesc}}</label> </div>
                <div class="col-md-4 mb-4"> 
                    <div class="input-group">
                        <input  type="text" id="documentdesc" name="documentdesc" [(ngModel)]="m_data.documentdesc" class="form-control">        
                    </div>
                </div>
            </div>



            <div class="row leftRight">
                <div class="col-md-3 mb-3 fontCenter"></div>

                <div class="col-md-6 mb-6 fontCenter">
                    <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit" (click)="generateReportPDF()">พิมพ์</button>&nbsp;
                    <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit" (click)="eventBotton(1)">บันทึก</button> &nbsp;
                    <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit" (click)="eventBotton(2)">บันทึก&ออก</button> &nbsp;
                    <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="close()">ออก</button>
                    <br><br>
                </div>

                <div class="col-md-3 mb-3 fontCenter"></div>
            </div> 
          </form>
        </div>
        
    </div>
    <br>
        &nbsp; <button (click) = "addData()"  class = "btn btn-success text-center">เพิ่มข้อมูล</button> &nbsp;

        <!-- <input type="file" (change)="onFileChange($event)" multiple="false" />
        <table class="sjs-table">
        <tbody>
          <tr *ngFor="let row of data">
                <td *ngFor="let val of row">
                    {{val}}
                </td>
            </tr>
        </tbody>
        </table> -->
    <div class="container-fluid ">
      <div class="card2">

      </div>
      <div class="card2" style="overflow-x:auto;" id="htmlData">

        <table id="dtHorizontalExample" class="table table-hover table-bordered table-sm " cellspacing="0" width="100%" >
          <thead>
           
            <ng-container  *ngIf="doctype==2 || doctype==5 ;else other1" >  <!-- การโอน -->
                <tr class="text-center">
                    <th scope="col" style="text-align: center;"> แก้ไข</th>
                    <!-- <th scope="col" style="text-align: center;"> Id</th> -->
                    <th scope="col" style="text-align: center;"> {{lblpartname}}</th>
                    <th scope="col" style="text-align: center;"> {{lblpartsub}}</th>
                    <th scope="col" style="text-align: center;"> {{lblempname}}</th>
                    <th scope="col" style="text-align: center;"> {{lblpartdept}}</th>
                    <th scope="col" style="text-align: center;"> {{lblpartlocation}}</th>
                    <th scope="col" style="text-align: center;"> {{lblempreceivename}}</th>
                    <th scope="col" style="text-align: center;"> {{lbltopartdept}}</th>
                    <th scope="col" style="text-align: center;"> {{lbltopartlocation}}</th>
                    <th scope="col" style="text-align: center;"> {{lbltxprice}}</th>
                    <th scope="col" style="text-align: center;"> {{lbltxamount}}</th>
                    <th scope="col" style="text-align: center;"> ลบ </th>
                </tr>
            </ng-container>
            <ng-template #other1>
                <tr class="text-center">
                    <th scope="col" style="text-align: center;"> แก้ไข</th>
                    <!-- <th scope="col" style="text-align: center;"> Id</th> -->
                    <th scope="col" style="text-align: center;"> {{lblpartname}}</th>
                    <th scope="col" style="text-align: center;"> {{lblpartsub}}</th>
                    <th scope="col" style="text-align: center;"> {{lblempname}}</th>
                    <th scope="col" style="text-align: center;"> {{lblpartdept}}</th>
                    <th scope="col" style="text-align: center;"> {{lblpartlocation}}</th>
                    <th scope="col" style="text-align: center;"> {{lbltxprice}}</th>
                    <th scope="col" style="text-align: center;"> {{lbltxamount}}</th>
                    <th scope="col" style="text-align: center;"> ลบ </th>
                </tr>
            </ng-template>
          </thead>
          <tbody>
            <ng-container *ngIf="doctype==2 || doctype==5 ;else other2" >  <!-- การโอน -->
                <tr  *ngFor = "let item of s_data" >
                    <td scope="row" class="col-1 fontCenter">  <button (click) = "updateData(item.id,item.partsubid)" class = "btn btn-info text-center buttonRow"> แก้ไข</button></td>
                    <!-- <td scope="row" class="col-1 fontRight"> {{item.id}}:{{item.toid}}</td> -->
                    <td scope="row" class="col-1 fontLeft"> {{item.partname}} </td>
                    <td scope="row" class="col-1 fontLeft"> {{item.qrcode}} </td>
                    <td scope="row" class="col-1 fontLeft"> {{item.personcode}} : {{item.personname}} </td>
                    <td scope="row" class="col-1 fontLeft"> {{item.deptname}} </td>
                    <td scope="row" class="col-1 fontLeft"> {{item.locationname}} </td>
                    <td scope="row" class="col-1 fontLeft"> {{item.personreceivecode}} : {{item.personreceivename}} </td>
                    <td scope="row" class="col-1 fontLeft"> {{item.todeptname}} </td>
                    <td scope="row" class="col-1 fontLeft"> {{item.tolocationname}} </td>
                    <td scope="row" class="col-1 fontRight"> {{item.txprice}} </td>
                    <td scope="row" class="col-1 fontRight"> {{item.txamount}} </td>
                    <td scope="row" class="col-1 fontCenter"><button (click) = "deleteData(item.id,item.toid)" class = "btn btn-danger text-center buttonRow"> ลบ</button></td>
                </tr>
            </ng-container>
            <ng-template #other2>
                <tr  *ngFor = "let item of s_data" >
                    <td scope="row" class="col-1 fontCenter">  <button (click) = "updateData(item.id,item.partsubid)" class = "btn btn-info text-center buttonRow"> แก้ไข</button></td>
                    <!-- <td scope="row" class="col-1 fontRight"> {{item.id}}</td> -->
                    <td scope="row" class="col-1 fontLeft"> {{item.partname}} </td>
                    <td scope="row" class="col-1 fontLeft"> {{item.qrcode}} </td>
                    <td scope="row" class="col-1 fontLeft"> {{item.personcode}} : {{item.personname}} </td>
                    <td scope="row" class="col-1 fontLeft"> {{item.deptname}} </td>
                    <td scope="row" class="col-1 fontLeft"> {{item.locationname}} </td>
                    <td scope="row" class="col-1 fontRight"> {{item.txprice}} </td>
                    <td scope="row" class="col-1 fontRight"> {{item.txamount}} </td>
                    <td scope="row" class="col-1 fontCenter"><button (click) = "deleteData(item.id)" class = "btn btn-danger text-center buttonRow"> ลบ</button></td>
                </tr>
            </ng-template>
          </tbody>
      </table>
      
    </div>
    
    <br>
    </div>
   </div>
</div>

<!-- Creates the bootstrap modal where the image will appear -->
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <form   (ngSubmit)="f.form.valid && onSubmitItems(eventsFlag)" #f="ngForm" novalidate>
    <div class="modal-dialog " id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-content" role="document">
       <!-- <div class="modal-content"> -->
          
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
            <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
          </div>
          
            <div class="modal-body">
                <span class="bg-light">Id : {{m_datas.id}}</span>
                <div class="card2" id="mainDiv"><br>
                   <div class="row leftRight">
                        <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lblpartname}}</label> </div>
                         <div class="col-md-7 mb-7"> 
                            <div class="input-group" id="partid">
                                <select id="partid" name="partid" (ngModelChange)="searchPartItemsSub($event)"  [(ngModel)]="m_datas.partid" class="form-control" required #partid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && partid.errors }" >
                                    <option  *ngFor="let ms of pi" [value]="ms.id">{{ms.partcode}} : {{ms.partname}} </option>
                                </select> 
                                 <button (click)="myFunction(pi,'pi','partid') " type="button" class="btn btn-primary">...</button>
                                <div *ngIf="partid.errors && f.submitted" class="invalid-feedback">
                                    <div *ngIf="partid.errors['required']">กรุณาระบุ {{lblpartname}}</div>
                                </div>
                            </div>
                         </div>

                    </div>

                    <div class="row leftRight">
                        <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lblpartsub}}</label> </div>
                        <div class="col-md-7 mb-7"> 
                            <div class="input-group">
                                
                                <select id="partsubid" name="partsubid" (ngModelChange)="searchLastBilling($event)" [(ngModel)]="m_datas.partsubid" class="form-control" required #partsubid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && partsubid.errors }">
                                    <option *ngFor="let ms of pi_sub" [value]="ms.id">{{ms.qrcode}} : {{ms.partname}}  </option>
                                </select> 
                                <div *ngIf="partsubid.errors && f.submitted" class="invalid-feedback">
                                    <div *ngIf="partsubid.errors['required']">กรุณาระบุ {{lblpartsub}}</div>
                                </div>
                            </div>
                        </div>
                      </div>

                      <ng-container *ngIf="doctype==2  || doctype==5 || doctype == 6;else other3" >  <!-- การโอน -->
                        <hr width="100%">
                        <div class="text-center">{{lbloutput}}</div><br>
                        
                        <div class="row leftRight">
                            <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lblempname}}</label> </div>
                            <div class="col-md-7 mb-7"> 
                                <div class="input-group" id="personid">
                                    
                                    <select id="personid" name="personid" [(ngModel)]="m_datas.personid" class="form-control" required #personid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && personid.errors }" disabled="true">
                                        <option *ngFor="let ms of ps_employee" [value]="ms.id">{{ms.personcode}} : {{ms.personname}}  </option>
                                    </select> 
                                    <button (click)="myFunction(ps_employee,'person','personid')" type="button" class="btn btn-primary">...</button>
                                    <div *ngIf="personid.errors && f.submitted" class="invalid-feedback">
                                        <div *ngIf="personid.errors['required']">กรุณาระบุ {{lblempname}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row leftRight">
                            <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lblpartdept}}</label> </div>
                            <div class="col-md-7 mb-7"> 
                                <div class="input-group" id="deptid">
                                    
                                    <select id="deptid" name="deptid" [(ngModel)]="m_datas.deptid" class="form-control" required #deptid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && deptid.errors }" disabled="true">
                                        <option *ngFor="let ms of mr_dept" [value]="ms.id">{{ms.keycode}} : {{ms.keyname}}  </option>
                                    </select> 
                                    <button (click)="myFunction(mr_dept,'dept','deptid')" type="button" class="btn btn-primary">...</button>
                                    <div *ngIf="deptid.errors && f.submitted" class="invalid-feedback">
                                        <div *ngIf="deptid.errors['required']">กรุณาระบุ {{lblpartdept}}</div>
                                    </div>
                                </div>
                            </div>
                          </div>
    
                          <div class="row leftRight">
                            <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lblpartlocation}}</label> </div>
                            <div class="col-md-7 mb-7"> 
                                <div class="input-group" id="locationid">
                                    
                                    <select id="locationid" name="locationid" [(ngModel)]="m_datas.locationid" class="form-control" required #locationid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && locationid.errors }" disabled="true">
                                        <option *ngFor="let ms of mr_location" [value]="ms.id">{{ms.keycode}} : {{ms.keyname}}  </option>
                                    </select> 
                                    <button (click)="myFunction(mr_location,'location','locationid')" type="button" class="btn btn-primary">...</button>
                                    <div *ngIf="locationid.errors && f.submitted" class="invalid-feedback">
                                        <div *ngIf="locationid.errors['required']">กรุณาระบุ {{lblpartlocation}}</div>
                                    </div>
                                </div>
                            </div>
                          </div>

                      </ng-container>
                      <ng-template #other3>
                        <div class="row leftRight">
                            <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lblempname}}</label> </div>
                            <div class="col-md-7 mb-7"> 
                                <div class="input-group" id="personid2">
                                    <select id="personid" name="personid" [(ngModel)]="m_datas.personid" class="form-control" required #personid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && personid.errors }">
                                        <option *ngFor="let ms of ps_employee" [value]="ms.id">{{ms.personcode}} : {{ms.personname}}  </option>
                                    </select> 
                                    <button (click)="myFunction(ps_employee,'person','personid2')" type="button" class="btn btn-primary">...</button>
                                    <div *ngIf="personid.errors && f.submitted" class="invalid-feedback">
                                        <div *ngIf="personid.errors['required']">กรุณาระบุ {{lblempname}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row leftRight">
                            <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lblpartdept}}</label> </div>
                            <div class="col-md-7 mb-7"> 
                                <div class="input-group" id="deptid">
                                    
                                    <select id="deptid" name="deptid" [(ngModel)]="m_datas.deptid" class="form-control" required #deptid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && deptid.errors }">
                                        <option *ngFor="let ms of mr_dept" [value]="ms.id">{{ms.keycode}} : {{ms.keyname}}  </option>
                                    </select> 
                                    <button (click)="myFunction(mr_dept,'dept','deptid')" type="button" class="btn btn-primary">...</button>
                                    <div *ngIf="deptid.errors && f.submitted" class="invalid-feedback">
                                        <div *ngIf="deptid.errors['required']">กรุณาระบุ {{lblpartdept}}</div>
                                    </div>
                                </div>
                            </div>
                          </div>
    
                          <div class="row leftRight">
                            <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lblpartlocation}}</label> </div>
                            <div class="col-md-7 mb-7"> 
                                <div class="input-group" id="locationid">
                                    
                                    <select id="locationid" name="locationid" [(ngModel)]="m_datas.locationid" class="form-control" required #locationid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && locationid.errors }">
                                        <option *ngFor="let ms of mr_location" [value]="ms.id">{{ms.keycode}} : {{ms.keyname}}  </option>
                                    </select> 
                                    <button (click)="myFunction(mr_location,'location','locationid')" type="button" class="btn btn-primary">...</button>
                                    <div *ngIf="locationid.errors && f.submitted" class="invalid-feedback">
                                        <div *ngIf="locationid.errors['required']">กรุณาระบุ {{lblpartlocation}}</div>
                                    </div>
                                </div>
                            </div>
                          </div>
                      </ng-template>

                      <div *ngIf="doctype == 2 || doctype == 5">
                        <hr width="100%">
                        <div class="text-center">{{lblinput}}</div><br>
                        <div  class="row leftRight">
                            <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lblempreceivename}}</label> </div>
                            <div class="col-md-7 mb-7"> 
                                <div class="input-group" id="topersonid">
                                    
                                    <select id="topersonid" name="topersonid" [(ngModel)]="m_datas.topersonid" class="form-control" required #topersonid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && topersonid.errors }">
                                        <option *ngFor="let ms of ps_employee" [value]="ms.id">{{ms.personcode}} : {{ms.personname}}  </option>
                                    </select> 
                                    <button (click)="myFunction(ps_employee,'toemp','topersonid')" type="button" class="btn btn-primary">...</button>
                                    <div *ngIf="topersonid.errors && f.submitted" class="invalid-feedback">
                                        <div *ngIf="topersonid.errors['required']">กรุณาระบุ {{lblempreceivename}}</div>
                                    </div>
                                </div>
                            </div>
                          </div>

                          <div class="row leftRight">
                            <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lbltopartdept}}</label> </div>
                            <div class="col-md-7 mb-7"> 
                                <div class="input-group" id="todeptid">
                                    
                                    <select id="todeptid" name="todeptid" [(ngModel)]="m_datas.todeptid" class="form-control" required #todeptid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && todeptid.errors }">
                                        <option *ngFor="let ms of mr_dept" [value]="ms.id">{{ms.keycode}} : {{ms.keyname}}  </option>
                                    </select> 
                                    <button (click)="myFunction(mr_dept,'todept','todeptid')" type="button" class="btn btn-primary">...</button>
                                    <div *ngIf="todeptid.errors && f.submitted" class="invalid-feedback">
                                        <div *ngIf="todeptid.errors['required']">กรุณาระบุ {{lbltopartdept}}</div>
                                    </div>
                                </div>
                            </div>
                          </div>
    
                          <div class="row leftRight">
                            <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lbltopartlocation}}</label> </div>
                            <div class="col-md-7 mb-7"> 
                                <div class="input-group" id="tolocationid">
                                    
                                    <select id="tolocationid" name="tolocationid" [(ngModel)]="m_datas.tolocationid" class="form-control" required #tolocationid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && tolocationid.errors }">
                                        <option *ngFor="let ms of mr_location" [value]="ms.id">{{ms.keycode}} : {{ms.keyname}}  </option>
                                    </select> 
                                    <button (click)="myFunction(mr_location,'tolocation','tolocationid')" type="button" class="btn btn-primary">...</button>
                                    <div *ngIf="tolocationid.errors && f.submitted" class="invalid-feedback">
                                        <div *ngIf="tolocationid.errors['required']">กรุณาระบุ {{lbltopartlocation}}</div>
                                    </div>
                                </div>
                            </div>
                          </div>
                      </div>

                      <div *ngIf="!(doctype == 1 || doctype == 2 || doctype == 3 || doctype == 5 )" class="row leftRight">
                        <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lbltxprice}}</label> </div>
                        <div class="col-md-7 mb-7"> 
                           <div class="input-group">
                               <input  type="text" id="txprice" name="txprice" [(ngModel)]="m_datas.txprice" class="form-control"
                               required minlength="1" #txprice="ngModel" [ngClass]="{ 'is-invalid': f.submitted && txprice.errors }">  
                               <div *ngIf="txprice.errors && f.submitted" class="invalid-feedback">
                                   <div *ngIf="txprice.errors['required']">กรุณาระบุ {{lbltxprice}}</div>
                               </div>  

                           </div>
                        </div>
                      </div>
        

                   <br>
                </div>

                
                
            </div>

          <div class="modal-footer">
            <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit" (click)="eventBotton(2)">บันทึก</button> &nbsp;
            <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">ออก</button>
          </div>

        <!-- สำหรับค้นหา -->
        <!-- <div [style.display]="showSearch ? 'block' : 'none'" > -->
            <div id="myDropdown" class="dropdown-content modal-dialog-scrollable" style="overflow-x:auto;">
                <div class="container" style="background : rgb(150, 218, 176)">
                    <div class="row" >
                    <div class="search-hero" >
                        <input  id="myInput" class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="ป้อนข้อมูลที่ต้องการค้นหา" >
                    </div>
                        <table id="dtHorizontalExample" class="table table-striped table-hover table-bordered table-sm " cellspacing="0" width="100%" >
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>รหัส</th>
                                <th>ชื่อ</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr  *ngFor="let data of dataSearch | filter:searchText" (click)="dataSelect(data.id)">
                                <td >{{data.id}}</td>
                                <td >{{data.code}}</td>
                                <td >{{data.name}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        <!-- </div> -->


       
        <!-- </div> -->
      </div>
    </div>
   </form>
  </div>
    <!-- end  Creates the bootstrap modal where the image will appear -->

</body>