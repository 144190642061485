export class c_billingdoc{
    id : number=0;
    documentno: string="";
    documentdesc: string="";
    documentdate: string="";
    documentdatelast: string=""; 
    cusid : number =0;
    personreceiveid : number =0;
    documenttype : number =0;
    totalprice : number =0;
    companyid : number =0;
    statusflag :string="";
    inputmanual :boolean=false;
    configdoctype: number =0;

    taxflag: string="";
    closeflag: string="";
    documentnoref: string="";
    approvedflag1: string="";
    approvedflag2: string="";
    approvedflag3: string="";
    approvedflag4: string="";
    approvedname1: string="";
    approvedname2: string="";
    approvedname3: string="";
    approvedname4: string="";
    approveddate1: string="";
    approveddate2: string="";
    approveddate3: string="";
    approveddate4: string="";
  

    createby : string ="";
    createdate :string="";
    modifyby: string="";
    modifydate :string="";


    customercode : string="";
    customername : string ="";
    companycode : string="";
    
    inputauto : boolean=false;//ใช้สำหรับการ import เท่านั้น
    partid: number=0;
    partsubid: number=0;
    partgroupid: number=0;
}