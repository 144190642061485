export class c_partitems_sub{
    id : number=0;
    partid: number=0;
    qrcode: string="";
    useflag: string="";
    beforeuseflag: string="";
    useflagset: string="";
    note: string="";

    createby : string ="";
    createdate :string="";
    modifyby: string="";
    modifydate :string="";

    partcode : string ="";
    partname : string ="";
    partnameeng : string ="";
    partdesc: string="";
    ignoreid : number=0;

    genqrcode: string="";

    unitname : string ="";
    partgroupname : string ="";
    parttypename : string ="";
    deptcode: string ="";
    deptname: string ="";
    locationcode: string ="";
    locationname: string =""; 
    personcode : string="";
    personname : string ="";
    personreceivecode : string="";
    personreceivename : string ="";

    purchasedate :string="";
    inspectiondate :string="";
    begindepreciationdate :string="";
    enddepreciationdate :string="";
    purchasevalue : number =0;
    depreciationrate : number =0;
    lifetime : number =0;
    carcassprice : number =0;
    suppliercode :string="";
    suppliername :string="";

}