import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GC } from '../common/global-constants';
import { c_user } from '../app.class/c_user';
import { data } from 'jquery';

const AUTH_API = GC.httpPath;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(private http: HttpClient) { }

  login(credentials: { username: any; password: any; crcompany: any ;companyid: any}): Observable<any> {
    return this.http.post(AUTH_API + 'user/signin', {
      username: credentials.username,
      password: credentials.password,
      companyid : credentials.companyid
    }, httpOptions);
  }

  // register(user: { username: any; email: any; password: any; companyid: any;}): Observable<any> {
    register( data: c_user): Observable<any> {
    // alert(user.companyid);  data: c_billingdoc

    return this.http.post(AUTH_API + 'signup', {
      // username: user.username,
      // email: user.email,
      // password: user.password,
      // companyid: user.companyid

      username: data.username,
      email: data.email,
      password: data.password,
      companyid: data.companyid

    }, httpOptions);
  }
}
