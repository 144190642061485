import { Component, OnInit } from '@angular/core';
import { c_partitems } from '../../app.class/c_partitems';
import { c_total } from '../../app.class/c_total';
import { s_partitems } from '../../app.service/s_partItems.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { GC } from '../../common/global-constants';
import { Observable } from 'rxjs';
import { filedata } from 'src/app/app.class/filedata';
import { c_masterRef } from 'src/app/app.class/c_masterref';
import { s_masterRef } from 'src/app/app.service/s_masterRef.service';
import { s_persons } from 'src/app/app.service/s_persons.service';
import { c_persons } from 'src/app/app.class/c_persons';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { CookieService } from 'ngx-cookie-service';


// import * as FileSaver from 'node_modules/pdfmake/build/file-saver';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

 
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';




// import * as pdfMake from 'pdfmake/build/pdfmake';
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';

// (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
var pdfMake = require('node_modules/pdfmake/build/pdfmake.js');
var pdfFonts = require('node_modules/pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-frm-part-items',
  templateUrl: './frm-part-items.component.html',
  styleUrls: ['./frm-part-items.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})



export class FrmPartItemsComponent implements OnInit {
  s_data: c_partitems[]=new Array();//ไว้สำหรับนำมาแสดง
  m_data: c_partitems = new c_partitems();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  m_datas: c_partitems = new c_partitems();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข

  r_data: c_partitems[]=new Array();//ไว้สำหรับนำมาแสดงรายงาน

  
  mr_master :c_masterRef[]=new Array();
  m_mr: c_masterRef = new c_masterRef();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  m_ps: c_persons = new c_persons();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  
  mr_unit: c_masterRef[]=new Array();//ไว้สำหรับนำมาแสดง
  mr_itemGroup: c_masterRef[]=new Array();//ไว้สำหรับนำมาแสดง
  mr_itemType: c_masterRef[]=new Array();//ไว้สำหรับนำมาแสดง
  ps_supplier: c_persons[]=new Array();//ไว้สำหรับนำมาแสดง

  id: number=0 ;
  isLoggedIn: boolean=true;
  eventsFlag : string ="A";
  eventsText : string="";
  role1 : boolean= false;
  BlogId : number=0;

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  fileInfos: Observable<any> | undefined;
  t_fileInfos : filedata[] =new Array();
  file: filedata = new filedata();

  httpPart : string ="";


  itemCode : string="";
  itemName : string="";
  itemDesc : string="";
  itemStatus : string="";
  parttype : number=0;
  nameFormOrigin : string="";
  nameForm : string="";

  lblid : string="Id";
  lblcode : string="รหัส";
  lblname : string="ชื่อ";
  lbldesc : string="รายละเอียด";
  lblsupplier : string ="ซื้อจาก";
  lblpurchasevalue : string ="มูลค่าการซื้อ(บาท)";
  lbldocumentref : string="เอกสาร";
  lblstatus : string ="สถานะ";
  lblgroup : string="หมวด";
  lbltype : string="ประเภท";
  lblunit : string="หน่วย";
  lblcompany : string="บริษัท";
  lblpurchasedate : string="วันที่ซื้อ";
  lblbegindepreciationdate : string="วันที่เริ่มคิดค่าเสื่อม";
  lblenddepreciationdate : string="วันที่หยุดคิดค่าเสื่อม";
  lbldepreciationrate : string ="อัตราค่าเสื่อมราคา(%)";
  lbllifetime : string ="อายุการใช้งาน(ปี)";

  lbldepreciationperday : string ="ค่าเสื่อมต่อวัน";
  lbldepreciationaccum : string ="ค่าเสื่อมสะสมยกมาต้นปี";
  lblassetaccumnet : string ="ทรัพย์สินสุทธิยกมา";
  lbldepreciationaccumbetweenyear : string ="ค่าเสื่อมราคาระหว่างปี";
  lblsumdepreciationaccumnet : string ="รวมค่าเสื่อมสะสมสุทธิ";
  lbldepreciationbalancenet : string ="คงเหลือทรัพย์สินสุทธิ";


  lbldatefix : string ="วันที่คำนวน";
  lblfrompurchasedate: string ="จากวันที่จัดซื้อ";
  lbltopurchasedate: string ="ถึงวันที่จัดซื้อ";
  lblreporttype : string ="ชื่อรายงาน";

  lblqrcode : string ="รหัส QRCODE";
  lblnote : string ="หมายเหตุ";

  now = new Date();


  status = [
    { id: "Y", name: "ใช้งาน" },
    { id: "N", name: "ยกเลิก" }
  ];

  reporttype =[
    { id: "1", name: "รายงาน ข้อมูลทรัพย์สินแบบละเอียด (AS001)" }
  ];

  myDate : string;
  total : c_total[] =new Array();

  total1 :number=0;
  total2 :number=0;
  total3 :number=0;
  total4 :number=0;
  total5 :number=0;
  total6 :number=0;
  total7 :number=0;
  



  constructor(private dataService: s_partitems,private masterrefService: s_masterRef,private personsService: s_persons,
    private router: Router,private route: ActivatedRoute,private tokenStorageService: TokenStorageService,private cookieService: CookieService) {
      this.role1 = !!this.tokenStorageService.getToken();
    }




   ngOnInit(): void {
      this.role1 = !!this.tokenStorageService.getToken();
      this.httpPart=GC.httpPath;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      GC.companyid =Number(this.cookieService.get('companyid'));

      this.parttype = this.route.snapshot.params['id'];
      this.m_data.companyid=GC.companyid;

      this.nameFormOrigin = this.route.snapshot.params['name'];
      if (this.nameFormOrigin==="registration_asset")
      {
        this.nameForm="ทะเบียนทรัพย์สิน";
      }

      this.lblcode=this.lblcode+ this.nameForm;
      this.lblname=this.lblname+ this.nameForm;

      this.getMaster(-1,this.m_mr); 

      this.m_datas.dateFix=GC.getDateEnNow("YYYY-MM-DD");

      this.queryData();
   }
   

   queryData(){
    if (this.m_datas.statusflag.length <= 0){this.m_datas.statusflag="Y";}
    this.m_datas.companyid=GC.companyid;
    this.m_datas.parttype=this.parttype;
     this.getData(-1,this.m_datas);
   }

   private getData(id? : number,mr? : c_partitems){
    this.dataService.getDataList(1,mr).subscribe(data => {
        this.s_data=data;

        this.s_data.forEach( (element : any) =>  {
            
          element.dateFix= this.m_datas.dateFix;

          element = GC.calDepreciation(element);
        });
    });
  }

  queryReport(){


    if (this.m_datas.reporttype <= 0){alert("กรุณาเลือก " + this.lblreporttype);return;}

    if (this.m_datas.statusflag.length <= 0){this.m_datas.statusflag="Y";}
    this.m_datas.companyid=GC.companyid;
    this.m_datas.parttype=this.parttype;
    
    if (this.m_datas.reporttype==1)
    {
      this.getReport(3,this.m_datas);
    }
     
   }

   private getReport(id : number,mr? : c_partitems){
    this.dataService.getDataList(id,mr).subscribe(data => {
        this.r_data=data;

        this.r_data.forEach( (element : any) =>  {
            
          element.dateFix= this.m_datas.dateFix;

          element = GC.calDepreciation(element);

          

        });
      
        this.sumReport();
    });
  }

 sumReport()
 {

  this.total1=0;
  this.total2=0;
  this.total3=0;
  this.total4=0;
  this.total5=0;
  this.total6=0;
  this.total7=0;

  this.r_data.forEach( (element : c_partitems) =>  {

    this.total1= this.total1 + Number(element.purchasevalue);
    if (!(element.depreciationflag))
    {
      this.total2= this.total2 + Number(element.depreciationperday);
      this.total3= this.total3 + Number(element.depreciationaccum);
      this.total4= this.total4 + Number(element.assetaccumnet);
      this.total5= this.total5 + Number(element.depreciationaccumbetweenyear);
      this.total6= this.total6 + Number(element.sumdepreciationaccumnet);
      this.total7= this.total7 + Number(element.depreciationbalancenet);
      
    }

    this.total1= GC.formatNumber(this.total1,2);
    this.total2= GC.formatNumber(this.total2,2);
    this.total3= GC.formatNumber(this.total3,2);
    this.total4= GC.formatNumber(this.total4,2);
    this.total5= GC.formatNumber(this.total5,2);
    this.total6= GC.formatNumber(this.total6,2);
    this.total7= GC.formatNumber(this.total7,2);

    
  });
 }


  addData(){
    this.m_data = new c_partitems();
    this.showModal = true; // Show-Hide Modal Check
    this.eventsFlag="A"; 
    this.eventsText ="เพิ่มข้อมูล " + this.nameForm;

    if (this.parttype==GC.reg_asset)
    {
      this.router.navigate(['/frm-part-items-sub-component',this.nameFormOrigin,this.eventsFlag]);
    }else if (this.parttype==GC.reg_partitems){
      this.router.navigate(['/frm-items-sub-component',this.nameFormOrigin,this.eventsFlag]);
    }
    

    
  }

   updateData(id: number){
     this.id=id;
     this.dataService.getDataById(this.id).subscribe(data => {
       this.m_data = data;
     }, error => console.log(error));
    // this.showModal = true; // Show-Hide Modal Check
    
    this.eventsFlag="E";
    this.eventsText ="แก้ไขข้อมูล " + this.nameForm;

    
    if (this.parttype==GC.reg_asset)
    {
      this.router.navigate(['/frm-part-items-sub-component',id,this.nameFormOrigin,this.eventsFlag]);
    }else if (this.parttype==GC.reg_partitems){
      this.router.navigate(['/frm-items-sub-component',id,this.nameFormOrigin,this.eventsFlag]);
    }
   }
 
   deleteData(id: number,blogId?: number,fileId1? : string,fileId2? : string ){
    let isConfirm : Boolean | any ;
    isConfirm=confirm("คุณแน่ใจหรือไม่ที่จะลบข้อมูล "+ id)
    if(isConfirm===false) {
      return;
    }
      this.dataService.deleteData(id).subscribe( data => {
        console.log(data);
        this.queryData();
    
      })
      this.eventsFlag="D";
    
  }

   clearForm() {
    this.m_datas=new c_partitems();

    this.m_data.partgroupname="";
  }
 
 //for popup form
   showModal: boolean | any;
   registerForm: UntypedFormGroup | any;
   submitted = false;
 
  show()
    { 
      this.showModal = true;
    }

   hide()
   {
     this.showModal = false;
   }

   verifyUser(Us : boolean){
     GC.role1=Us;
     this.role1=GC.role1;
   }

   private getMaster(id? : number,mr? : c_masterRef) {
    // alert("e")
    mr.iscompany=true;//เปิดให้เข้าทุกบริษัท
    mr.companyid=GC.companyid;
    this.masterrefService.getDataList(id,mr).subscribe(data => {
      this.mr_master=new Array();//ไว้สำหรับนำมาแสดง
      this.mr_master=data;
   
      this.getUnit(GC.mr_unit); 
      this.getPartGroup(GC.mr_itemGroup); 
      this.getPartType(GC.mr_itemType); 
      
      
    });
  }


   private getUnit(id? : number,mr? : c_masterRef) {
    this.mr_unit.splice(0,this.mr_unit.length);//clear all
    this.mr_master.forEach( (element : any) =>  {
      
      if (element.masterarea==id){
          this.mr_unit.push(element);
      }
    });
  }

  private getPartGroup(id? : number,mr? : c_masterRef) {
    this.mr_itemGroup.splice(0,this.mr_itemGroup.length);//clear all
    this.mr_master.forEach( (element : any) =>  {
      
      if (element.masterarea==id){
          this.mr_itemGroup.push(element);
      }
    });
  }

  private getPartType(id? : number,mr? : c_masterRef) {

    this.mr_itemType.splice(0,this.mr_itemType.length);//clear all
    this.mr_master.forEach( (element : any) =>  {
      
      if (element.masterarea==id){
          this.mr_itemType.push(element);
      }
    });
  }

async generateReportPDF() { 

  if (this.m_datas.dateFix ===""){alert("กรุณาระบุ " + this.lbldatefix);return;}

  let docDefinition : any;

 
  let rows = [];
  //Header column
  rows.push([ { text: '#',bold: true, alignment: 'center' }, 
              { text: 'รหัสทรัพย์สิน',bold: true, alignment: 'center' }, 
              { text: 'ชื่อทรัพย์สิน', bold: true,alignment: 'center' }, 
              // { text: 'รายละเอียด',bold: true, alignment: 'center' }, 
              { text: 'เอกสาร',bold: true, alignment: 'center' }, 
              { text: 'ชื่อผู้ขาย',bold: true, alignment: 'center' }, 
              { text: 'วันที่ซื้อ',bold: true, alignment: 'center' }, 
              { text: 'QrCode',bold: true, alignment: 'center' }, 
              { text: 'หน่วย',bold: true, alignment: 'center' }, 
              { text: 'กลุ่ม',bold: true, alignment: 'center' },
              { text: 'อายุ(ปี)',bold: true, alignment: 'center' },
              { text: 'วันที่เริ่มคิดค่าเสื่อม',bold: true, alignment: 'center' }, 
              { text: 'วันที่หยุดคิดค่าเสื่อม',bold: true, alignment: 'center' }, 
              { text: 'มูลค่า',bold: true, alignment: 'center' },
              { text: 'ค่าเสื่อมต่อวัน',bold: true, alignment: 'center' }, 
              { text: 'ค่าเสื่อมสะสมยกมาต้นปี',bold: true, alignment: 'center' }, 
              { text: 'ทรัพย์สินสุทธิยกมา',bold: true, alignment: 'center' }, 
              { text: 'ค่าเสื่อมราคาระหว่างปี',bold: true, alignment: 'center' }, 
              { text: 'รวมค่าเสื่อมสะสมสุทธิ',bold: true, alignment: 'center' }, 
              { text: 'คงเหลือทรัพย์สินสุทธิ',bold: true, alignment: 'center' }, 
            ]);
           
//Detail column
  let i: number=0;
  let j: number=0;
  let total : number[] =new Array(100);

  for (i=0; i<100 ;i++)
  {
    total[i]=0;
  }
  
  i=0;
  this.r_data.forEach( (element : c_partitems) =>  {
    i++;
   
    if (!(element.depreciationflag))
    {
      element.dateFix=GC.getDateFormatEnToEn(this.m_datas.dateFix,"YYYY-MM-DD");
      // alert(element.dateFix)
      element=GC.calDepreciation(element);
     
      // alert(element.assetaccumnet)
      rows.push([
      { text: i, alignment: 'right'}, 
      { text: element.partcode, alignment: 'left'}, 
      { text: element.partname, alignment: 'left'}, 
      // { text: element.partdesc, alignment: 'left'}, 
      { text: element.documentref, alignment: 'left'}, 
      { text: element.suppliername, alignment: 'left'}, 
      { text: GC.convertYearEnToYearThai(element.purchasedate,'DD/MM/YYYY'), alignment: 'center'}, 
      { text: element.qrcode, alignment: 'center'}, 
      { text: element.unitname, alignment: 'left'}, 
      { text: element.partgroupname, alignment: 'left'}, 
      { text: GC.formatNumberTostring(element.lifetime,2), alignment: 'right'}, 
      { text: GC.convertYearEnToYearThai(element.begindepreciationdate,'DD/MM/YYYY'), alignment: 'center'}, 
      { text: GC.convertYearEnToYearThai(element.enddepreciationdate,'DD/MM/YYYY'), alignment: 'center'}, 
      { text: GC.formatNumberTostring(element.purchasevalue,2), alignment: 'right'}, 
      { text: GC.formatNumberTostring(element.depreciationperday,2), alignment: 'right'}, 
      { text: GC.formatNumberTostring(element.depreciationaccum,2), alignment: 'right'}, 
      { text: GC.formatNumberTostring(element.assetaccumnet,2), alignment: 'right'}, 
      { text: GC.formatNumberTostring(element.depreciationaccumbetweenyear,2), alignment: 'right'}, 
      { text: GC.formatNumberTostring(element.sumdepreciationaccumnet,2), alignment: 'right'}, 
      { text: GC.formatNumberTostring(element.depreciationbalancenet,2), alignment: 'right'}, 
      ]);
      
      j=9;
      total[j]=total[j]+element.purchasevalue;
      j++;
      total[j]=total[j]+element.depreciationperday;
      j++;
      total[j]=total[j]+element.depreciationaccum;
      j++;
      total[j]=total[j]+element.assetaccumnet;
      j++;
      total[j]=total[j]+element.depreciationaccumbetweenyear;
      j++;
      total[j]=total[j]+element.sumdepreciationaccumnet;
      j++;
      total[j]=total[j]+element.depreciationbalancenet;
      
    }else
    {
      rows.push([
        { text: i, alignment: 'right'}, 
        { text: element.partcode, alignment: 'left'}, 
        { text: element.partname, alignment: 'left'}, 
        // { text: element.partdesc, alignment: 'left'}, 
        { text: element.partdesc, alignment: 'left'}, 
        { text: element.suppliername, alignment: 'left'}, 
        { text: GC.convertYearEnToYearThai(element.purchasedate,'DD/MM/YYYY'), alignment: 'center'}, 
        { text: element.qrcode, alignment: 'center'}, 
        { text: element.unitname, alignment: 'left'}, 
        { text: element.partgroupname, alignment: 'left'}, 
        { text: element.lifetime, alignment: 'right'}, 
        { text: GC.convertYearEnToYearThai(element.begindepreciationdate,'DD/MM/YYYY'), alignment: 'center'}, 
        { text: GC.convertYearEnToYearThai(element.enddepreciationdate,'DD/MM/YYYY'), alignment: 'center'}, 
        { text: element.purchasevalue, alignment: 'right'}, 
        { text: "", alignment: 'right'}, 
        { text: "", alignment: 'right'}, 
        { text: "", alignment: 'right'}, 
        { text: "", alignment: 'right'},
        { text: "", alignment: 'right'},
        { text: "", alignment: 'right'},
        ]);

    }

   // alert(total[9]);
  });

  //set total
  // j=9;
  rows.push([
    { text: "", alignment: 'right'}, 
    { text: "รวม", alignment: 'left'}, 
    { text: "", alignment: 'left'}, 
    // { text: "", alignment: 'left'}, 
    { text: "", alignment: 'left'}, 
    { text: "", alignment: 'left'}, 
    { text: "", alignment: 'center'}, 
    { text: "", alignment: 'center'}, 
    { text: "", alignment: 'left'}, 
    { text: "", alignment: 'left'}, 
    { text: "", alignment: 'right'}, 
    { text: "", alignment: 'center'}, 
    { text: "", alignment: 'center'}, 
    { text: GC.formatNumberTostring(total[9],2), alignment: 'right'}, 
    { text: GC.formatNumberTostring(total[10],2), alignment: 'right'}, 
    { text: GC.formatNumberTostring(total[11],2), alignment: 'right'}, 
    { text: GC.formatNumberTostring(total[12],2), alignment: 'right'}, 
    { text: GC.formatNumberTostring(total[13],2), alignment: 'right'}, 
    { text: GC.formatNumberTostring(total[14],2), alignment: 'right'}, 
    { text: GC.formatNumberTostring(total[15],2), alignment: 'right'}, 
    { text: GC.formatNumberTostring(total[16],2), alignment: 'right'}, 
    ]);

  let groupName : string="";
  let pageMaginTop : number=50;

  if (this.m_data.partgroupname != "")
  {
    groupName='หมวด : ' + this.m_data.partgroupname ;
    
  }

  groupName= groupName + ' วันที่คำนวน : ' + GC.convertYearEnToYearThai(this.m_datas.dateFix,'DD/MM/YYYY');
  pageMaginTop=70;

  pdfMake.fonts = {
    THSarabunNew: {
      normal: 'THSarabunNew.ttf',
      bold: 'THSarabunNew-Bold.ttf',
      italics: 'THSarabunNew-Italic.ttf',
      bolditalics: 'THSarabunNew-BoldItalic.ttf'
      },
    Roboto: {
      normal: 'Roboto-Regular.ttf',
      bold: 'Roboto-Medium.ttf',
      italics: 'Roboto-Italic.ttf',
      bolditalics: 'Roboto-MediumItalic.ttf'
    }
    };

     docDefinition = {
      pageSize: 'A4',
      pageMargins: [ 20,pageMaginTop,20,30 ],
      pageOrientation: 'landscape' ,
      header: {
        margin: 20,
        
        columns: [
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'รายงาน ข้อมูลทรัพย์สิน',
                      bold: true,
                      width: '*',
                      fontSize: 18,
                      alignment: 'center',
                    }
                  ],
                },
                {
                  columns: [
                    {
                      text: groupName,
                      bold: true,
                      width: '*',
                      fontSize: 15,
                      alignment: 'center',
                    }
                  ],
                },
              ],
            }

            
        ],
      },
      footer: function (currentPage : any, pageCount : any) {
        return [{ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'center' }];
      },

      content: [
        {
          
          table: {
            headerRows: 1,
                widths: [20, 50, 60,30,30,45, 30,25,30,20,45,45,30,30,30,30,30,30,30,30],//745 แนวนอน
                body: rows,
          },
        },
  
      ],
      styles: {
        notesTitle: {
          fontSize: 10,
          bold: true,
          margin: [0, 50, 0, 3],
        },
        notesText: {
          fontSize: 10,
        },
      },
      defaultStyle: {
        columnGap: 14,
        font: 'THSarabunNew'
      },
    };
     pdfMake.createPdf(docDefinition).open();
   } 



  downloadExc()
  {
    if (this.m_datas.dateFix ===""){alert("กรุณาระบุ " + this.lbldatefix);return;}
    GC.exportexcel();
  }

   onChange(event : any,name : string) {
    if (name=='partgroupid')
    {
      this.m_data.partgroupname=event.target.options[event.target.options.selectedIndex].text;
    }else if (name=='reporttype')
    {
      this.m_datas.reportname=event.target.options[event.target.options.selectedIndex].text;
    }
    
    
   }

  //  onChange2(event : any) {
  //   this.m_datas.reportname=event.target.options[event.target.options.selectedIndex].text;
  //  }
}
