<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>


<body class="ht" [style.display]="role1 ? 'block' : 'none'"> 
    <br><br><br><br>
    <div class="container-fluid text-center bg_4_1">
      <div class="bg_2_2">

        <div class="container card2">
            <section id="about" class="about">
              
              <ul class="grid">
                <!-- ระบบจัดซื้อ -->
                <!-- <li > 
                  <img (click) = "toSystem(2)"  src="https://mtpapiservice.net:8443/api/v1/files/MO_SV.4.1_Privacy_img02.webp" alt="Jim Hawkins" title="Jim Hawkins" class="round zoom2- card-img-" width="200px" height="200px">
                  <br><br>
                  <h4 class="f-green fs5">ระบบจัดซื้อ</h4>
                  <br>
                </li> -->

                <!-- ระบบขาย -->
                <!-- <li > 
                  <img (click) = "toSystem(3)"  src="https://mtpapiservice.net:8443/api/v1/files/MO_SV.4.1_Privacy_img02.webp" alt="Jim Hawkins" title="Jim Hawkins" class="round zoom2- card-img-" width="200px" height="200px">
                  <br><br>
                  <h4 class="f-green fs5">ระบบขาย</h4>
                  <br>
                </li> -->

                <!-- ระบบทรัพย์สิน -->
                <li > 
                  <img (click) = "toSystem(1)"  src="https://mtpapiservice.net:8443/api/v1/files/MO_SV.4.1_Privacy_img02.webp" alt="Jim Hawkins" title="Jim Hawkins" class="round zoom2- card-img-" width="200px" height="200px">
                  <br><br>
                  <h4 class="f-green fs5">ระบบทรัพย์สิน</h4>
                  <br>
                </li>

                <!-- ระบบบัญชี -->
                <!-- <li > 
                  <img (click) = "toSystem(4)"  src="https://mtpapiservice.net:8443/api/v1/files/MO_SV.4.1_Privacy_img02.webp" alt="Jim Hawkins" title="Jim Hawkins" class="round zoom2- card-img-" width="200px" height="200px">
                  <br><br>
                  <h4 class="f-green fs5">ระบบบัญชี</h4>
                  <br>
                </li> -->
              </ul>
            </section>
          </div>
          <div class="bg_5_3 margin_footer"></div>
      </div>
      </div>    



</body>