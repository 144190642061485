import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { c_masterRef } from '../app.class/c_masterref';
import { map } from 'rxjs/operators';
import { GC } from '../common/global-constants';
import { TokenStorageService } from '../_services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class s_masterRef {

  private baseURL = "";
  private baseVisitURL = GC.httpPath + "goods_count";
  private person : any;
  tP : String ="masterref";

  constructor(private httpClient: HttpClient, private tokenStorage: TokenStorageService) { 
    this.baseURL = GC.httpPath + this.tP;
    GC.username = this.tokenStorage.getUser().username;
  }
  
  getDataList(id? :number,mr? : c_masterRef): Observable<c_masterRef[]>{
    
    this.person= this.httpClient.get(this.baseURL).pipe(map((response: any) => response.json()));
    this.baseURL = GC.httpPath + this.tP +"/allkey?id="+ mr.id + "&keycode=" + mr.keycode + "&keyname=" + mr.keyname + "&masterarea=" + id + "&iscompany=" + mr.iscompany
    + "&companyid=" + mr.companyid;
    //  alert(this.baseURL); 
    return this.httpClient.get<c_masterRef[]>(`${this.baseURL}`);
  }
  
  getDataByMyQuery(ind? :number ,mt? : c_masterRef): Observable<c_masterRef>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));
 
    this.baseURL = GC.httpPath + this.tP +"/myquery?ind=" + ind +"&id="+ mt.id + "&masterarea=" + mt.masterarea ;
        // alert(this.baseURL); 
      return this.httpClient.get<c_masterRef>(`${this.baseURL}`);
  }

  createData(data: c_masterRef): Observable<Object>{
    data.createby=GC.username;
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
    //alert(`${this.baseURL}`);
    return this.httpClient.post(`${this.baseURL}`, data);
  }

  getDataById(id: number): Observable<c_masterRef>{
    this.baseURL = GC.httpPath + this.tP
    // alert(`${this.baseURL}`);
    return this.httpClient.get<c_masterRef>(`${this.baseURL}/${id}`);
  }

  updateData(id: number, data: c_masterRef): Observable<Object>{
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
    // alert(`${this.baseURL}/${id}`);
    return this.httpClient.put(`${this.baseURL}/${id}`, data);
  }

  updateVisits(id: number, data: c_masterRef): Observable<Object>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.put(`${this.baseVisitURL}/${id}`, data);
  }

  deleteData(id: number): Observable<Object>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }
}

