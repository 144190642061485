<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>


<body class="ht">
  <div #back></div>

  
 <div  [style.display]="role1 ? 'block' : 'none'" >
    <div id="bottom"  >
        <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
       </div>
 
   <div class="card2"><br><br>
      <span>
          <h1>{{nameForm2}}</h1> 
      </span>
      <span class="example-spacer"></span>


    <div class="container">
        <span class="bg-light">Id : {{m_data.id}}</span>
        <div class="card2"><br>
            <form  (ngSubmit)="f.form.valid && onSubmitMaster(eventsFlag)" #f="ngForm" novalidate>

                <div class="row leftRight">
                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblcode}}</label> </div>
                    <div class="col-md-3 mb-3"> 

                        <ng-container  *ngIf="m_data.inputmanual;else other4" >
                            <div class="input-group">
                                <input  type="text" id="partcode" name="partcode" [(ngModel)]="m_data.partcode" class="form-control"
                                    required minlength="6" #partcode="ngModel" [ngClass]="{ 'is-invalid': f.submitted && partcode.errors }">  
                                    <div *ngIf="partcode.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="partcode.errors['required']">กรุณาระบุ {{lblcode}}</div>
                            </div>          
                            </div>
                        </ng-container>
                        <ng-template #other4>
                            <div class="input-group">
                                <input  type="text" id="partcode" name="partcode" [(ngModel)]="m_data.partcode" class="form-control" disabled="true"> 
                            </div>
                        </ng-template>
                    
                    </div>

                    <ng-container  *ngIf="eventsFlag =='A';else other5" >
                        <div class="col-md-1 mb-1"> <label><input type="checkbox" (click) = "addManual(m_data.inputmanual)" id="autobegingflag" name="autobegingflag" [(ngModel)]="m_data.inputmanual"> {{lblInputManual}}</label>   </div>
                    </ng-container>
                    <ng-template #other5>
                        <div class="col-md-1 mb-1"> <label><input type="checkbox" (click) = "addManual(m_data.inputmanual)" id="autobegingflag" name="autobegingflag" [(ngModel)]="m_data.inputmanual" disabled="true"> {{lblInputManual}}</label>   </div>
                    </ng-template>


       

                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblname}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <input  type="text" id="partname" name="partname" [(ngModel)]="m_data.partname" class="form-control"
                            required minlength="1" #partname="ngModel" [ngClass]="{ 'is-invalid': f.submitted && partname.errors }">  
                            <div *ngIf="partname.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="partname.errors['required']">กรุณาระบุ {{lblname}}</div>
                            </div>            
                        </div>
                    </div>
                </div>

                <!-- <div class="row leftRight">

                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbldocumentref}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <input  type="text" id="documentref" name="documentref" [(ngModel)]="m_data.documentref" class="form-control"> 
                        </div>
                    </div>

                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblname}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <input  type="text" id="partname" name="partname" [(ngModel)]="m_data.partname" class="form-control"
                            required minlength="1" #partname="ngModel" [ngClass]="{ 'is-invalid': f.submitted && partname.errors }">  
                            <div *ngIf="partname.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="partname.errors['required']">กรุณาระบุ {{lblname}}</div>
                            </div>            
                        </div>
                    </div>
                </div> -->


                <div class="row leftRight">
                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbldesc}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <input  type="text" id="partdesc" name="partdesc" [(ngModel)]="m_data.partdesc" class="form-control">           
                        </div>
                    </div>

                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblunit}}</label> </div>
                    <div class="col-md-1 mb-1"> 
                        <div class="input-group">
                            <select id="unitid" name="unitid" [(ngModel)]="m_data.unitid" class="form-control" 
                            required #unitid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && unitid.errors }">
                                <option *ngFor="let ms of mr_unit" [value]="ms.id">{{ms.keyname}} </option>
                            </select>  
                            <div *ngIf="unitid.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="unitid.errors['required']">กรุณาระบุ {{lblunit}}</div>
                            </div>
                        
                        </div>
                    </div>

                    <div class="col-md-1 mb-1"><label class="verticalLine" for="">{{lbldocumentref}}</label> </div>
                    <div class="col-md-2 mb-2"> 
                        <div class="input-group">
                            <input  type="text" id="documentref" name="documentref" [(ngModel)]="m_data.documentref" class="form-control"> 
                        </div>
                    </div>

                </div>

                <div class="row leftRight">
                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblgroup}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <select id="partgroupid" (ngModelChange)="searchPartType($event)" name="partgroupid" [(ngModel)]="m_data.partgroupid" class="form-control"
                            required #partgroupid="ngModel"[ngClass]="{ 'is-invalid': f.submitted && partgroupid.errors }">
                                <option *ngFor="let ms of mr_itemGroup" [value]="ms.id">{{ms.keycode}} : {{ms.keyname}} </option>
                            </select> 
                            <div *ngIf="partgroupid.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="partgroupid.errors['required']">กรุณาระบุ {{lblgroup}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbltype}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <select id="parttypeid" name="parttypeid" [(ngModel)]="m_data.parttypeid" class="form-control"
                            required minlength="1" #statusflag="ngModel" [ngClass]="{ 'is-invalid': f.submitted && statusflag.errors }">
                                <option *ngFor="let ms of mr_itemType" [value]="ms.id">{{ms.keycode}} : {{ms.keyname}} </option>
                            </select> 

                            <div *ngIf="statusflag.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="statusflag.errors['required']">กรุณาระบุ {{lblstatus}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row leftRight">
                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblsupplier}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <select id="supplierid" name="supplierid" [(ngModel)]="m_data.supplierid" class="form-control">
                                <option *ngFor="let ms of ps_supplier" [value]="ms.id">{{ms.personcode}} : {{ms.personname}} </option>
                            </select> 
                        </div>
                    </div>

                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblpurchasevalue}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <input  type="number" id="purchasevalue" name="purchasevalue" [(ngModel)]="m_data.purchasevalue" class="form-control fontRight"
                            required minlength="1" #purchasevalue="ngModel" [ngClass]="{ 'is-invalid': f.submitted && purchasevalue.errors }">  
                            <div *ngIf="purchasevalue.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="purchasevalue.errors['required']">กรุณาระบุ {{lblpurchasevalue}}</div>
                            </div>          
                        </div>
                    </div>
                </div>

                <div class="row leftRight">
                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblcarcassprice}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <input  type="number" id="carcassprice" name="carcassprice" [(ngModel)]="m_data.carcassprice" class="form-control fontRight"  
                            required minlength="1" #carcassprice="ngModel" [ngClass]="{ 'is-invalid': f.submitted && carcassprice.errors }">  
                            <div *ngIf="carcassprice.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="carcassprice.errors['required']">กรุณาระบุ {{lblcarcassprice}}</div>
                            </div>         
                        </div>
                    </div>
                    
                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblpurchasedate}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <input  type="date" id="purchasedate" name="purchasedate" [(ngModel)]="m_data.purchasedate" class="form-control"
                            required minlength="0" #purchasedate="ngModel" [ngClass]="{ 'is-invalid': f.submitted && purchasedate.errors }">  
                            <div *ngIf="purchasedate.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="purchasedate.errors['required']">กรุณาระบุ {{lblpurchasedate}}</div>
                            </div>            
                        </div>
                    </div>
                </div>

                <div class="row leftRight">
                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblbegindepreciationdate}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <input  type="date" id="begindepreciationdate" name="begindepreciationdate" [(ngModel)]="m_data.begindepreciationdate" class="form-control"
                            required minlength="0" #begindepreciationdate="ngModel" [ngClass]="{ 'is-invalid': f.submitted && begindepreciationdate.errors }">  
                            <div *ngIf="begindepreciationdate.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="begindepreciationdate.errors['required']">กรุณาระบุ {{lblbegindepreciationdate}}</div>
                            </div>           
                        </div>
                    </div>

                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblenddepreciationdate}}</label> </div>
                    <div class="col-md-4 mb-4"> 
                        <div class="input-group">
                            <input  type="date" id="endcalculatedate" name="endcalculatedate" [(ngModel)]="m_data.enddepreciationdate" class="form-control"
                            required minlength="0" #endcalculatedate="ngModel" [ngClass]="{ 'is-invalid': f.submitted && endcalculatedate.errors }">  
                            <div *ngIf="endcalculatedate.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="endcalculatedate.errors['required']">กรุณาระบุ {{lblenddepreciationdate}}</div>
                            </div>             
                        </div>
                    </div>
                </div>

                <div class="row leftRight">
                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbldepreciationrate}}</label> </div>
                    <div class="col-md-2 mb-2"> 
                        <div class="input-group">
                            <select id="depreciationrate" (ngModelChange)="seartRate($event,m_data)"  name="depreciationrate" [(ngModel)]="m_data.depreciationrate" class="form-control fontRight"
                            required #depreciationrate="ngModel"[ngClass]="{ 'is-invalid': f.submitted && depreciationrate.errors }">
                                <option *ngFor="let ms of deptrate" [value]="ms.id">{{ms.name}} </option>
                            </select> 
                            <div *ngIf="depreciationrate.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="depreciationrate.errors['required']">กรุณาระบุ {{lbldepreciationrate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 mb-2"> 
                        <div class="input-group">
                            <input type="checkbox"  id="depreciationflag" name="depreciationflag"  [(ngModel)]="m_data.depreciationflag" (click)="clearFlag()" (ngModelChange)="seartRate($event,m_data)">{{lbldepreciationflag}}
                        </div>
                    </div>

                    <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lbllifetime}}</label> </div>
                    <div class="col-md-1 mb-1"> 
                        <div class="input-group">
                            <input  type="number" id="lifetime" name="lifetime" [(ngModel)]="m_data.lifetime" class="form-control fontRight"
                            required minlength="1" #lifetime="ngModel" [ngClass]="{ 'is-invalid': f.submitted && lifetime.errors }" disabled="true">  
                            <div *ngIf="lifetime.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="lifetime.errors['required']">กรุณาระบุ {{lbllifetime}}</div>
                            </div>  

                        </div>
                    </div>

                    <div class="col-md-1 mb-1"><label class="verticalLine" for="">{{lblcreateamount}}</label> </div>
                    <div class="col-md-1 mb-1"> 
                        <div class="input-group">
                           

                            <ng-container  *ngIf="eventsFlag =='A';else other6" >
                                <input  type="number" id="createamount" name="createamount" [(ngModel)]="m_data.createamount" class="form-control fontRight">
                             </ng-container>
                            <ng-template #other6>
                                <input  type="number" id="createamount" name="createamount" [(ngModel)]="m_data.createamount" class="form-control fontRight" disabled="true"> 
                            </ng-template>

                        </div>
                    </div>
                    <div class="col-md-1 mb-1"><label  for="">{{m_data.unitname}}</label> </div>
                </div>




                <div class="row leftRight">
                    <div class="col-md-3 mb-3 fontCenter"></div>

                    <div class="col-md-6 mb-6 fontCenter">
                        <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit" (click)="generateReportPDF()">พิมพ์</button>&nbsp;
                        <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit" (click)="eventBotton(1)">บันทึก</button> &nbsp;
                        <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit" (click)="eventBotton(2)">บันทึก&ออก</button> &nbsp;
                        <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="close()">ออก</button>
                        <br><br>
                    </div>

                    <div class="col-md-3 mb-3 fontCenter"></div>
                </div>

               


                
          </form>
        </div>

        <div class="card2"><br>

            <div class="row leftRight">
                <div class="col-md-3 mb-3"><label class="verticalLine" for="">{{lbldatefix}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="date" id="dateFix" name="dateFix" (ngModelChange)="seartRate2($event,m_data)" [(ngModel)]="m_data.dateFix" class="form-control"
                        required minlength="0" #dateFix="ngModel" [ngClass]="{ 'is-invalid': f.submitted && dateFix.errors }">  
                        <div *ngIf="dateFix.errors && f.submitted" class="invalid-feedback">
                            <div *ngIf="dateFix.errors['required']">กรุณาระบุ {{lbldatefix}}</div>
                        </div>           
                    </div>
                </div>

                <div class="col-md-3 mb-3"><label class="verticalLine" for="">{{lbldepreciationaccumlastyeardate}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="date" id="depreciationaccumlastyeardate" name="depreciationaccumlastyeardate" [(ngModel)]="m_data.depreciationaccumlastyeardate" class="form-control" disabled="true">     
                    </div>
                </div>
            </div>



            <div class="row leftRight">
                <div class="col-md-3 mb-3"><label class="verticalLine" for="">{{lbldepreciationaperday}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="depreciationpermonth" name="depreciationpermonth" [(ngModel)]="m_data.depreciationperday" class="form-control fontRight" disabled="true"> 
                    </div>
                </div>
                
                <div class="col-md-3 mb-3"><label class="verticalLine" for="">{{lbldepreciationaccumlastyear}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="depreciationaccum" name="depreciationaccum" [(ngModel)]="m_data.depreciationaccum" class="form-control fontRight" disabled="true"> 
                    </div>
                </div>
            </div>

            <div class="row leftRight">
                <div class="col-md-3 mb-3"><label class="verticalLine" for="">{{lblassetaccumnet}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="assetaccumnet" name="assetaccumnet" [(ngModel)]="m_data.assetaccumnet" class="form-control fontRight" disabled="true"> 
                    </div>
                </div>

                <div class="col-md-3 mb-3"><label class="verticalLine" for="">{{lbldepreciationaccumbetweenyear}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="depreciationaccumbetweenyear" name="depreciationaccumbetweenyear" [(ngModel)]="m_data.depreciationaccumbetweenyear" class="form-control fontRight" disabled="true"> 
                    </div>
                </div>
            </div>


            <div class="row leftRight">
                <div class="col-md-3 mb-3"><label class="verticalLine" for="">{{lblsumdepreciationaccumnet}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="sumdepreciationaccumnet" name="sumdepreciationaccumnet" [(ngModel)]="m_data.sumdepreciationaccumnet" class="form-control fontRight" disabled="true"> 
                    </div>
                </div>

                <div class="col-md-3 mb-3"><label class="verticalLine" for="">{{lbldepreciationbalancenet}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="depreciationbalancenet" name="depreciationbalancenet" [(ngModel)]="m_data.depreciationbalancenet" class="form-control fontRight" disabled="true"> 
                    </div>
                </div>
                
            </div>

               
        </div>
        
    </div>

   

    <br>
        &nbsp; <button (click) = "addData()"  class = "btn btn-success text-center">เพิ่มข้อมูล</button>&nbsp;
        <button (click) = "generatePDFGroup(s_data)" class = "btn btn-success text-center">QR Code</button>


        <!-- <input type="file" (change)="onFileChange($event)" multiple="false" />
        <table class="sjs-table">
        <tbody>
          <tr *ngFor="let row of data">
                <td *ngFor="let val of row">
                    {{val}}
                </td>
            </tr>
        </tbody>
        </table> -->
        <!-- <button (click)="export()">Export!</button> -->


    <div class="container-fluid ">
      <div class="card2">

      </div>
      <div class="card2" style="overflow-x:auto;">

        <table id="dtHorizontalExample" class="table table-hover table-bordered table-sm" cellspacing="0" width="100%" >
          <thead>
              <tr class="text-center">
                  <th scope="col" style="text-align: center;"> แก้ไข</th>
                  <!-- <th scope="col" style="text-align: center;"> Id</th> -->
                  <th scope="col" style="text-align: center;"> {{lblqrcode}}</th>
                  <th scope="col" style="text-align: center;"> {{lblnote}}</th>
                  <th scope="col" style="text-align: center;"> {{lbluseflag}}</th>
                  <th scope="col" style="text-align: center;"> ลบ </th>
              </tr>
          </thead>
          <tbody>
              <tr  *ngFor = "let item of s_data" >
                  <td scope="row" class="col-1 fontCenter"> 
                     <button (click) = "updateData(item.id)" class = "btn btn-info text-center buttonRow"> แก้ไข</button>&nbsp;
                     <button (click) = "generatePDF(item.qrcode,item.partcode,item.partname,item.purchasedate,item.note)" class = "btn btn-info text-center buttonRow"> พิมพ์</button> 
                    </td>
                  <!-- <td scope="row" class="col-1 fontRight"> {{item.id}}</td> -->
                  <td scope="row" class="col-1 fontCenter"> {{item.qrcode}} </td>
                  <td scope="row" class="col-2 fontLeft"> {{item.note}} </td>
                  <td scope="row" class="col-1 fontCenter"> {{item.useflag}} </td>
                  <td scope="row" class="col-1 fontCenter"><button (click) = "deleteData(item.id)" class = "btn btn-danger text-center buttonRow"> ลบ</button></td>
              </tr>
             
          </tbody>
      </table>
      
    </div>
    
    <br>
    </div>
   </div>
</div>

<!-- Creates the bootstrap modal where the image will appear -->
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <form  (ngSubmit)="f.form.valid && onSubmitItems(eventsFlag)" #f="ngForm" novalidate>
    <div class="modal-dialog " id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable " role="document">
       <div class="modal-content">
          
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
            <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
          </div>

          
            <div class="modal-body">
                 <span class="bg-light">Id : {{m_datas.id}}</span>
                <div class="card2"><br>
                   <div class="row leftRight">
                        <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lblqrcode}}</label> </div>
                         <div class="col-md-7 mb-7"> 
                            <div class="input-group">
                                <input  type="text" id="qrcode" name="qrcode" [(ngModel)]="m_datas.qrcode" class="form-control" disabled
                                required minlength="6" #qrcode="ngModel" [ngClass]="{ 'is-invalid': f.submitted && qrcode.errors }">  
                                <div *ngIf="qrcode.errors && f.submitted" class="invalid-feedback">
                                    <div *ngIf="qrcode.errors['required']">กรุณาระบุ {{lblqrcode}}</div>
                                </div>       
                            </div>
                         </div>
                    </div>

                    <div class="row leftRight">
                        <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lbluseflag}}</label> </div>
                        <div class="col-md-7 mb-7"> 
                            <div class="input-group">
                                
                                <select id="useflag" name="useflag" [(ngModel)]="m_datas.useflag" class="form-control" required #useflag="ngModel"[ngClass]="{ 'is-invalid': f.submitted && useflag.errors }">
                                    <option *ngFor="let ms of status" [value]="ms.id">{{ms.name}} </option>
                                </select> 
                                <div *ngIf="useflag.errors && f.submitted" class="invalid-feedback">
                                    <div *ngIf="useflag.errors['required']">กรุณาระบุ {{lbluseflag}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row leftRight">
                        <div class="col-md-5 mb-5"><label class="verticalLine" for="">{{lblnote}}</label> </div>
                        <div class="col-md-7 mb-7"> 
                            <div class="input-group">
                                <input  type="text" id="note" name="note" [(ngModel)]="m_datas.note" class="form-control">
                            </div>
                        </div>
                    </div>
                      
                    <div class ="container text-center">
                        <qrcode [qrdata]="m_datas.genqrcode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                        <span>{{lblDetail}}</span>
                    </div>
                  

        

                   <br>
                </div>
                
            </div>

          <div class="modal-footer">
           
            <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit" (click)="eventBotton(2)">บันทึก</button> &nbsp;
            <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">ออก</button>
          </div>
       
        </div>
      </div>
    </div>
   </form>

   
  </div>
    <!-- end  Creates the bootstrap modal where the image will appear -->

    

</body>