import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GC } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {
  private baseURL = GC.httpPath + "/files/api";
 // private baseURL = "http://localhost:8081/api/v1";
  constructor(private httpClient: HttpClient) { }

  
  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', `${this.baseURL}`, formData, {
      reportProgress: true,
      responseType: 'json'
    });


    return this.httpClient.request(req);
  }
  

  getFiles(): Observable<any> {
    return this.httpClient.get(`${this.baseURL}`);
  }

  getFilesById(id : string | any): Observable<any> {
    return this.httpClient.get(`${this.baseURL}/${id}`);
  }

  // deleteFileById(id :string |any)
  // {

  // }
  deleteFileById(id: string |any): Observable<Object>{
    return this.httpClient.delete(`${id}`);
  }
}