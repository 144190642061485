import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { c_billingdoc_items } from '../app.class/c_billingdoc_items';
import { map } from 'rxjs/operators';
import { GC } from '../common/global-constants';
import { TokenStorageService } from '../_services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class s_billingdoc_items {

  private baseURL = "";
  private baseVisitURL = GC.httpPath + "goods_count";
  private person : any;
  tP : String ="billingitems";

  constructor(private httpClient: HttpClient, private tokenStorage: TokenStorageService) { 
    this.baseURL = GC.httpPath + this.tP;
    GC.username = this.tokenStorage.getUser().username;
  }
  
  getDataList(ind? :number ,mt? : c_billingdoc_items): Observable<c_billingdoc_items[]>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));
 
    this.baseURL = GC.httpPath + this.tP +"/allkey?ind=" + ind +"&id="+ mt.id + "&billingid=" + mt.billingid + "&deptid=" + mt.deptid 
    + "&personid=" + mt.personid  + "&locationid=" + mt.locationid + "&partsubid=" + mt.partsubid + "&qrcode=" + mt.qrcode;
          // alert(this.baseURL); 
    return this.httpClient.get<c_billingdoc_items[]>(`${this.baseURL}`);
  }

  getDataListByQrcode(ind? :number ,mt? : c_billingdoc_items): Observable<c_billingdoc_items[]>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));
 
    this.baseURL = GC.httpPath + this.tP +"/fixbyid?ind=" + ind + "&billingid=" + mt.billingid + "&qrcode=" + mt.qrcode;
            // alert(this.baseURL); 
    return this.httpClient.get<c_billingdoc_items[]>(`${this.baseURL}`);
  }

  createData(data: c_billingdoc_items): Observable<Object>{
    data.createby=GC.username;
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
    // alert(this.baseURL); 
    return this.httpClient.post(`${this.baseURL}`, data);
  }

  getDataById(id: number): Observable<c_billingdoc_items>{
    this.baseURL = GC.httpPath + this.tP
    // alert(`${this.baseURL}/${id}`);
    return this.httpClient.get<c_billingdoc_items>(`${this.baseURL}/${id}`);
  }

  getDataByMyQuery(ind? :number ,mt? : c_billingdoc_items): Observable<c_billingdoc_items>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));
 
    this.baseURL = GC.httpPath + this.tP +"/myquery?ind=" + ind +"&id="+ mt.id + "&billingid=" + mt.billingid + 
    "&partsubid=" + mt.partsubid + "&documenttypeset=" + mt.documenttypeset ;
          // alert(this.baseURL); 
      return this.httpClient.get<c_billingdoc_items>(`${this.baseURL}`);
  }

  updateData(id: number, data: c_billingdoc_items): Observable<Object>{
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
      //alert(this.baseURL + "/" + id);
    return this.httpClient.put(`${this.baseURL}/${id}`, data);
  }

  updateVisits(id: number, data: c_billingdoc_items): Observable<Object>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.put(`${this.baseVisitURL}/${id}`, data);
  }

  deleteData(id: number, id2: number=0): Observable<Object>{
    this.baseURL = GC.httpPath + this.tP
    //  alert( this.baseURL);
    return this.httpClient.delete(`${this.baseURL}/${id}/${id2}`);
  }
}

