export class c_configdoc{
    id : number=0;
    lastno: string="";
    prefixcode: string="";
    configdoctype : number =0;
    digitamount : number =0;
    runningno : number =0;
    companyid : number =0;
    yeartype: number =0;
    monthtype: number =0;
    code1: string="";
    code2: string="";
    code3: string="";
    autobegingflag: string="";

    createby : string ="";
    createdate :string="";
    modifyby: string="";
    modifydate :string="";

    companycode : string ="";
}