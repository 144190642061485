<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>


<body class="ht">
<div class="container-fluid ">
  <div class="container-fluid shadow min-vh-50 py-1">


    <div class="row">
        <div class="col-md-5 mx-auto">
            <div class="small fw-light">ค้นหาด้วยรหัสทรัพย์สิน 6 หลัก</div>
            <div class="input-group">
                <input maxlength="6" class="form-control border-end-0 border rounded-pill text-center" type="search"  id="searchvalue" name="searchvalue" [(ngModel)]="searchvalue">
                
                <button (click) = "search()"  class = "border  btn btn-info text-center buttonRow"> ค้นหา</button>
                &nbsp;
                <button  (click)="generateReportPDF()" class="border  btn btn-info text-center buttonRow">พิมพ์</button> 
            </div>
        </div>
    </div>
    <br>

    <div class="row">
      <div *ngFor = "let item of s_partsub" >
        <div class="col-md-5 mx-auto">
          <P class="verticalLine text-center">ข้อมูลทรัพย์สิน</P>
          <P>รหัสทะเบียนทรัพย์สิน : {{item.partcode}}</P>
          <P>ชื่อภาษาอังกฤษ : {{item.partnameeng}}</P>
          <P>ชื่อทะเบียนทรัพย์สิน : {{item.partname}}</P>
          <P>รายละเอียด : {{item.partdesc}}</P>
          <P>รหัส QR Code : {{item.qrcode}}</P>
          <P>หน่วย : {{item.unitname}}</P>
          <P>หมวด : {{item.partgroupname}}</P>
          <P>ประเภท : {{item.parttypename}}</P>
          <P>ซื้อจาก : {{item.suppliername}}</P>
          <P>มูลค่าการซื้อ : {{item.purchasevalue}}</P>
          <P>อัตราค่าเสื่อมราคา : {{item.depreciationrate}} %</P>
          <P>อายุการใช้งาน : {{item.lifetime}} (ปี)</P>
          <P>ราคาซาก : {{item.carcassprice}} (บาท)</P>
          <P>วันที่ซื้อ : {{item.purchasedate}}</P>
          <P>วันที่เริ่มคิดค่าเสื่อม : {{item.begindepreciationdate}}</P>
          <P>วันที่หยุดคิดค่าเสื่อม : {{item.enddepreciationdate}}</P>
          <P>สถานะ : {{item.useflag}}</P>
        </div>
    </div>
  </div>
</div>


    <div class="card2 shadow" style="overflow-x:auto;" id="htmlData">
      <div class="container" >
        <P class="verticalLine text-center">รายการเคลื่อนไหว</P>
      </div>
      
      <table id="dtHorizontalExample" class="table table-hover table-bordered table-sm" cellspacing="0" width="100%" >
        <thead>

            <tr class="text-center">
                <th scope="col" style="text-align: center;"> {{lblDocEvents}}</th>
                <th scope="col" style="text-align: center;"> {{lbldocdate}}</th>
                <th scope="col" style="text-align: center;"> {{lbldocno}}</th>
                <th scope="col" style="text-align: center;"> {{lblcustomername}}</th>
                <th scope="col" style="text-align: center;"> {{lblempname}}</th>
                <th scope="col" style="text-align: center;"> {{lblpartdept}}</th>
                <th scope="col" style="text-align: center;"> {{lblpartlocation}}</th>
                <th scope="col" style="text-align: center;"> {{lbltxprice}}</th>
                <th scope="col" style="text-align: center;"> {{lbltxamount}}</th>
            </tr>

        </thead>
        <tbody>
            <tr  *ngFor = "let item of s_data" >
                <td scope="row" class="col-1 fontLeft"> {{item.docevents}}</td>
                <td scope="row" class="col-1 fontLeft"> {{item.documentdatelast}}</td>
                <td scope="row" class="col-1 fontLeft"> {{item.documentno}}</td>
                <td scope="row" class="col-1 fontLeft"> {{item.customercode}}  {{item.customername}}</td>
                <td scope="row" class="col-1 fontLeft"> {{item.personcode}}  {{item.personname}} </td>
                <td scope="row" class="col-1 fontLeft"> {{item.deptname}} </td>
                <td scope="row" class="col-1 fontLeft"> {{item.locationname}} </td>
                <td scope="row" class="col-1 fontRight"> {{item.txprice}} </td>
                <td scope="row" class="col-1 fontRight"> {{item.txamount}} </td>
            </tr>
        </tbody>
    </table>
    
  </div>
  
  <br>
  </div>
</body>