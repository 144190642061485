<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>


<body class="ht" [style.display]="role1 ? 'block' : 'none'">
  <div #back></div>
  <div id="bottom"  >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
   </div>
 
   <div class="card2"><br><br>
      <span>
          <h1>{{nameForm}}</h1> 
      </span>
      <span class="example-spacer"></span>

    <div class="container">
        <div class="card2"><br>
            <div class="row leftRight">
                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblDoctype}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="lastno" name="lastno" [(ngModel)]="m_datas.lastno" class="form-control">           
                    </div>
                </div>

                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblPrefix}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="prefixcode" name="prefixcode" [(ngModel)]="m_datas.prefixcode" class="form-control">           
                    </div>
                </div>
               
                <div class="col-md-2 mb-2 fontCenter">
                    <button (click) = "queryData()" class = "btn btn-success text-center">ค้นหา</button>&nbsp;
                    <button (click) = "clearForm()" class = "btn btn-success text-center">เคลียร์</button>
                </div>
            </div>
        </div>
        
    </div>
    <br>
        <button (click) = "addData()"  class = "btn btn-success text-center">เพิ่มข้อมูล</button>
    <div class="container-fluid ">
      <div class="card2">

      </div>
      <div class="card2">
          <div class="row">
            <div class="col-sm-12" style="overflow-x:auto;">
              <table id="dtHorizontalExample" class="table table-hover table-bordered table-sm" cellspacing="0" width="100%" >
                <thead>
                    <tr class="text-center">
                        <th scope="col" style="text-align: center;"> แก้ไข</th>
                        <th scope="col" style="text-align: center;"> {{lblLastDoc}}</th>
                        <th scope="col" style="text-align: center;"> {{lblDoctype}}</th>
                        <th scope="col" style="text-align: center;"> {{lblPrefix}}</th>
                        <th scope="col" style="text-align: center;"> {{lblCode1}}</th>
                        <th scope="col" style="text-align: center;"> {{lblYearType}}</th>
                        <th scope="col" style="text-align: center;"> {{lblCode2}}</th>
                        <th scope="col" style="text-align: center;"> {{lblMonthType}}</th>
                        <th scope="col" style="text-align: center;"> {{lblCode3}}</th>
                        <th scope="col" style="text-align: center;"> {{lblDigit}}</th>
                        <th scope="col" style="text-align: center;"> {{lblRunningNo}}</th>
                        <th scope="col" style="text-align: center;"> {{lblcompany}}</th>
                        <th scope="col" style="text-align: center;"> ลบ </th>
                    </tr>
                </thead>
                <tbody>
                    <tr  *ngFor = "let item of s_data" >
                        <td scope="row" class="col-1 fontCenter">  <button (click) = "updateData(item.id)" class = "btn btn-info text-center buttonRow"> แก้ไข</button></td>
                        <td scope="row" class="col-2 fontLeft"> {{item.lastno}} </td>
                        <td scope="row" class="col-1 fontLeft"> {{item.configdoctype}}</td>
                        <td scope="row" class="col-2 fontLeft"> {{item.prefixcode}} </td>
                        <td scope="row" class="col-2 fontLeft"> {{item.code1}} </td>
                        <td scope="row" class="col-2 fontLeft"> {{item.yeartype}} </td>
                        <td scope="row" class="col-2 fontLeft"> {{item.code2}} </td>
                        <td scope="row" class="col-2 fontLeft"> {{item.monthtype}} </td>
                        <td scope="row" class="col-2 fontLeft"> {{item.code3}} </td>
                        <td scope="row" class="col-2 fontLeft"> {{item.digitamount}} </td>
                        <td scope="row" class="col-2 fontLeft"> {{item.runningno}} </td>
                        <td scope="row" class="col-1 fontLeft"> {{item.companycode}} </td>
                        <td scope="row" class="col-1 fontCenter"><button (click) = "deleteData(item.id)" class = "btn btn-danger text-center buttonRow"> ลบ</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
      
    </div>
    
    <br>
    </div>
   </div>

<!-- Creates the bootstrap modal where the image will appear -->
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <!-- <form (ngSubmit)="onSubmit(eventsFlag)"> -->
  <form  (ngSubmit)="f.form.valid && onSubmit(eventsFlag)" #f="ngForm" novalidate>
    <div class="modal-dialog " id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable " role="document">
       <div class="modal-content">
          
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
            <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
          </div>

         
            <div class="modal-body">
              <span class="bg-light">Id : {{m_data.id}}</span>
                <div class="card2"><br>

                  <div class="row leftRight">
                    <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblLastDoc}}</label> </div>
                    <div class="col-md-8 mb-8"> 
                      <div class="input-group">
                        <input  type="text" id="last_no" name="last_no" [(ngModel)]="m_data.lastno" class="form-control" disabled="true">           
                    </div>
                    </div>
                  </div>

                  <div class="row leftRight">
                    <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblDoctype}}</label> </div>
                    <div class="col-md-8 mb-8"> 
                        <div class="input-group">  
                            <select id="config_doc_type" name="config_doc_type" [(ngModel)]="m_data.configdoctype" class="form-control" required #config_doc_type="ngModel"[ngClass]="{ 'is-invalid': f.submitted && config_doc_type.errors }">
                                <option *ngFor="let ms of doctype" [value]="ms.id">{{ms.name}} </option>
                            </select> 
                            <div *ngIf="config_doc_type.errors && f.submitted" class="invalid-feedback">
                                <div *ngIf="config_doc_type.errors['required']">กรุณาระบุ {{lblDoctype}}</div>
                            </div>
                        </div>
                    </div>
                  </div>

                  <div class="row leftRight">
                       <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblPrefix}}</label> </div>
                       <div class="col-md-8 mb-8"> 
                          <div class="input-group">
                              <input  type="text" id="prefix_code" name="prefix_code" [(ngModel)]="m_data.prefixcode" class="form-control">           
                          </div>
                       </div>
                  </div>

                  
                  <div class="row leftRight">
                    <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblCode1}}</label> </div>
                    <div class="col-md-8 mb-8"> 
                       <div class="input-group">
                           <input  type="text" id="code1" name="code1" [(ngModel)]="m_data.code1" class="form-control">           
                       </div>
                    </div>
                 </div>

                 <div class="row leftRight">
                  <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblYearType}}</label> </div>
                  <div class="col-md-8 mb-8"> 
                      <div class="input-group">  
                          <select id="yeartype" name="yeartype" [(ngModel)]="m_data.yeartype" class="form-control" required #yeartype="ngModel"[ngClass]="{ 'is-invalid': f.submitted && yeartype.errors }">
                              <option *ngFor="let ms of yeartypes" [value]="ms.id">{{ms.name}} </option>
                          </select> 
                          <div *ngIf="yeartype.errors && f.submitted" class="invalid-feedback">
                              <div *ngIf="yeartype.errors['required']">กรุณาระบุ {{lblYearType}}</div>
                          </div>
                      </div>
                  </div>
                </div>

                <div class="row leftRight">
                  <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblCode2}}</label> </div>
                  <div class="col-md-8 mb-8"> 
                     <div class="input-group">
                         <input  type="text" id="code2" name="code2" [(ngModel)]="m_data.code2" class="form-control">           
                     </div>
                  </div>
               </div>

               <div class="row leftRight">
                <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblMonthType}}</label> </div>
                <div class="col-md-8 mb-8"> 
                    <div class="input-group">  
                        <select id="monthtype" name="monthtype" [(ngModel)]="m_data.monthtype" class="form-control" required #monthtype="ngModel"[ngClass]="{ 'is-invalid': f.submitted && monthtype.errors }">
                            <option *ngFor="let ms of monthtypes" [value]="ms.id">{{ms.name}} </option>
                        </select> 
                        <div *ngIf="monthtype.errors && f.submitted" class="invalid-feedback">
                            <div *ngIf="monthtype.errors['required']">กรุณาระบุ {{lblMonthType}}</div>
                        </div>
                    </div>
                </div>
              </div>

              <div class="row leftRight">
                <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblCode3}}</label> </div>
                <div class="col-md-8 mb-8"> 
                   <div class="input-group">
                       <input  type="text" id="code3" name="code3" [(ngModel)]="m_data.code3" class="form-control">           
                   </div>
                </div>
             </div>

                  <div class="row leftRight">
                    <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblDigit}}</label> </div>
                    <div class="col-md-8 mb-8"> 
                      <div class="input-group">
                        <input  type="text" id="digit_amount" name="digit_amount" [(ngModel)]="m_data.digitamount" class="form-control">           
                    </div>
                    </div>
                  </div>

                  <div class="row leftRight">
                    <div class="col-md-4 mb-4"><label class="verticalLine" for="">{{lblRunningNo}}</label> </div>
                    <div class="col-md-8 mb-8"> 
                      <div class="input-group">
                        <input  type="text" id="running_no" name="running_no" [(ngModel)]="m_data.runningno" class="form-control">           
                    </div>
                    </div>
                  </div>

                  <div class="row leftRight">
                    <div class="col-md-4 mb-4"> </div>
                    <div class="col-md-8 mb-8"> 
                      <div class="input-group">      
                        <label><input type="checkbox" id="autobegingflag" name="autobegingflag" [(ngModel)]="m_data.autobegingflag"> {{lblAutoBegin}}</label>   
                      </div>
                    </div>
                  </div>

                    <br>
                </div>
            </div>

          <div class="modal-footer">
            <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
            <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
          </div>
       
        </div>
      </div>
    </div>
   </form>
  </div>
    <!-- end  Creates the bootstrap modal where the image will appear -->

</body>