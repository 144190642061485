import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';
import { c_partitems } from '../app.class/c_partitems';
import { map } from 'rxjs/operators';
import { GC } from '../common/global-constants';
import { TokenStorageService } from '../_services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class s_partitems {

  private baseURL = "";
  private baseVisitURL = GC.httpPath + "goods_count";
  private person : any;
  tP : String ="partitems";

  constructor(private httpClient: HttpClient, private tokenStorage: TokenStorageService) { 
    this.baseURL = GC.httpPath + this.tP;
    GC.username = this.tokenStorage.getUser().username;
  }
  
  getDataList(ind? :number ,mt? : c_partitems): Observable<c_partitems[]>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));
 
    this.baseURL = GC.httpPath + this.tP +"/allkey?ind=" + ind +"&id="+ mt.id + "&partcode=" + mt.partcode + "&partname=" + mt.partname + "&parttype=" + mt.parttype 
    + "&statusflag=" + mt.statusflag + "&companyid=" + mt.companyid + "&partgroupid=" + mt.partgroupid + "&parttypeid=" + mt.parttypeid 
    + "&frompurchasedate=" + mt.frompurchasedate + "&topurchasedate=" + mt.topurchasedate
    + "&unitid=" + mt.unitid + "&useflagset=" + mt.useflagset + "&ignoreid=" + mt.ignoreid ;

    // console.log(this.baseURL);ddddd
          //  alert(this.baseURL);

        // var authorization = 'Bearer '+sessionStorage.getItem("access_token");
        // const headers = new HttpHeaders({ 'Content-Type': 'application/json',"Authorization": authorization, responseType : 'blob'});
     
        // alert(this.baseURL);
        // return this.httpClient.get<c_partitems[]>(`${this.baseURL}`, { headers : headers,responseType : 'blob' as 'json'});

    return this.httpClient.get<c_partitems[]>(`${this.baseURL}`);
  }

  createData(data: c_partitems): Observable<Object>{
    data.createby=GC.username;
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
   
    return this.httpClient.post(`${this.baseURL}`, data);
  }

  getDataById(id: number): Observable<c_partitems>{
    this.baseURL = GC.httpPath + this.tP
    // alert(`${this.baseURL}/${id}`);
    return this.httpClient.get<c_partitems>(`${this.baseURL}/${id}`);
  }

  

  updateData(id: number, data: c_partitems): Observable<Object>{
    data.modifyby=GC.username;
    this.baseURL = GC.httpPath + this.tP
    //  alert(this.baseURL + "/" + id);
    return this.httpClient.put(`${this.baseURL}/${id}`, data);
  }

  updateVisits(id: number, data: c_partitems): Observable<Object>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.put(`${this.baseVisitURL}/${id}`, data);
  }

  deleteData(id: number): Observable<Object>{
    this.baseURL = GC.httpPath + this.tP
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }

  // exportToPdf()
  // {
  //   this.baseURL = GC.httpPath + this.tP +"/report"
     
  //   let filePaths : string= GC.httpPath;
  //   // alert(this.baseURL);
  //   return this.httpClient.get<any>(`${this.baseURL}`);
  // }
  exportToPdf()
  {
    this.baseURL = GC.httpPath + this.tP +"/report"
    var authorization = 'Bearer '+sessionStorage.getItem("access_token");
    const headers = new HttpHeaders({ 'Content-Type': 'application/json',"Authorization": authorization, responseType : 'blob'});
     
    // alert(this.baseURL);
    return this.httpClient.get<Blob>(`${this.baseURL}`, { headers : headers,responseType : 'blob' as 'json'});
  }

//   getPDF(invoiceId : number): Observable<Blob>
//      {
//          this.url = this.main_url + '/invoices/generatepdf/'+invoiceId;
//          var authorization = 'Bearer '+sessionStorage.getItem("access_token");

//          const headers = new HttpHeaders({ 'Content-Type': 'application/json',"Authorization": authorization, responseType : 'blob'});

//          return this.httpClient.get<Blob>(this.url, { headers : headers,responseType : 
//          'blob' as 'json'});
//      }
}

